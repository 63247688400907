import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Pais } from 'src/app/model/pais';
import { Usuario } from 'src/app/model/usuario';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StorageService } from 'src/app/services/storage.service';
import { ValidatorService } from 'src/app/validator/validator.service';

@Component({
  selector: 'app-cambio-password',
  templateUrl: './cambio-password.component.html',
  styleUrls: ['./cambio-password.component.scss'],
})
export class CambioPasswordComponent implements OnInit, AfterViewInit {
  form!: FormGroup;
  paises!: Pais[];
  error: string | null = null;
  loginInvalid: boolean = false;
  autenticate!: boolean;

  @ViewChild('btnCambiar') btnCambiar!: MatButton;

  @ViewChild('fpais') fpaisSelect!: MatSelect;

  constructor(
    private fb: FormBuilder,
    private back: BackendService,
    private router: Router,
    private msg: NotificationService,
    private storage: StorageService,
    private el: ElementRef,
    private validatorService: ValidatorService,
  ) {}

  ngAfterViewInit(): void {
    this.setFocus('fpais');
  }

  ngOnInit() {
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      fpais: [1, [Validators.required]],
      oldpassword: ['', [Validators.required, Validators.minLength(8)]],
      newpassword: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!-_@#\$%\^&\*])(?=.{8,})/), Validators.minLength(8)]],
      renewpassword: ['', [Validators.required]],
    }, {
      validators: [this.validatorService.camposIguales('newpassword', 'renewpassword')]
    });

    this.back.getPaises().subscribe(
      (paises) => {
        this.paises = paises;
        if (paises && paises.length > 0) {
          let id: number = paises[0].id;
          let valPais = this.storage.getParam('pais', id);
          if (!this.autenticate) {
            this.form.controls.fpais.setValue(valPais);
          } else {
            this.setFormForAutenticate();
          }
        }
      },
      (error) => {
        this.error = 'Error al cargar paises: ' + error.statusText;
        this.loginInvalid = true;
      }
    );

    this.storage.autenticado().subscribe((val) => {
      this.autenticate = val;
      if (val) {
        this.setFormForAutenticate();
      }
    });
  }

  campoValido(campo: string) {
    return this.form.controls[campo].errors && this.form.controls[campo].touched;
  }

  setFormForAutenticate() {
    let user = this.storage.getParam<Usuario>('usuario');
    if (user) {
      let pais = user.pais.id;
      this.form.controls.fpais.setValue(pais);
      this.form.controls.username.setValue(user.id);
    }
  }

  onSubmit() {
    this.error = null;
    if (this.form.value.oldpassword == this.form.value.newpassword) {
      this.error = 'El password anterior no puede ser igual al nuevo';
      this.setFocus('oldpassword');
      return;
    }
    
    this.back
      .changePassword(
        this.form.value.oldpassword,
        this.form.value.newpassword,
      )
      .subscribe(
        (msgOk) => {
          if (msgOk && msgOk == 'OK') {
            this.router.navigate(['/']);
            this.msg.showSuccess(
              'Cambio de password exitoso',
              'Cambio de password'
            );
          } else {
            this.error = 'Error al cambiar el password ' + msgOk;
          }
        },
        (error) => {
          this.error =
            'Error no controlado (Change): ' + error.statusText;
        }
      );
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }

  onSelectionChangePais(event: any) {
    if (this.form.controls.fpais.valid) {
      this.setFocus('username');
    }
  }

  usernameKeyEnter(event: any) {
    if (this.form.controls.username.valid) {
      this.setFocus('oldpassword');
    }
  }

  oldPasswordKeyEnter(event: any) {
    if (this.form.controls.oldpassword.valid) {
      this.setFocus('newpassword');
    }
  }

  newPasswordKeyEnter(event: any) {
    if (this.form.controls.newpassword.valid) {
      this.setFocus('renewpassword');
    }
  }

  renewPasswordKeyEnter(event: any) {
    if (this.form.controls.renewpassword.valid) {
      this.btnCambiar.focus();
    }
  }
}
