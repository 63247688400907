<form [formGroup]="form" autocomplete="off">
    <mat-form-field appearance="fill" style="width: 100%;">
      <mat-label>VIN</mat-label>
      <input matInput 
      formControlName="vin" 
      maxlength="256" 
      placeholder="Ingrese un VIN..."
      (keydown.enter)="nextDanio($event)"
      (keydown.tab)="nextDanio($event)">
      <button mat-icon-button matSuffix (click)="onClickSearch()">
          <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%;">
      <mat-label>Operacion</mat-label>
      <input matInput 
      formControlName="operacion" 
      maxlength="256" 
      placeholder="Ingrese una operacion..."
      (keydown.enter)="changeFocusAceptar($event)"
      (keydown.tab)="changeFocusAceptar($event)"
      >
    </mat-form-field>
    <mat-list role="list" style="overflow-y: scroll; height: 290px;">
      <div mat-subheader>Lineas de Operación del Proveedor</div>
      <mat-list-item *ngFor="let operaciones of lineasOperacion, let i = index" role="listitem"
      >{{operaciones.id}} - {{operaciones.descripcion}}
    </mat-list-item>
    </mat-list>
  </form>
  