<form [formGroup]="form" autocomplete="off">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Transacción</mat-label>
    <mat-select
      formControlName="transaccion"
      #transaccion
      (selectionChange)="onSelectionChangeRemito($event)"
    >
      <mat-option
        *ngFor="let transaccion of transacciones"
        [value]="transaccion"
      >
        {{ transaccion.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Código Inspector</mat-label>
    <mat-select formControlName="impector">
      <mat-option *ngFor="let impector of impectores" [value]="impector">
        {{ impector.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Número de Remito</mat-label>
    <input
      matInput
      formControlName="remito"
      maxlength="256"
      placeholder="Ingrese un remito..."
      (keydown.enter)="remitoKeyEnter($event)"
      (keydown.tab)="remitoKeyEnter($event)"
    />
  </mat-form-field>
</form>
