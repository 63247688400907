<div class="example-container">
  <mat-toolbar color="primary" class="md-medium-tall example-toolbar">
    <button *ngIf="usuario != null" mat-icon-button (click)="snav.toggle()">
      <img src="assets/icons/menu.svg" />
    </button>
    <h1 class="example-app-name">{{ title }}</h1>
    <span class="example-spacer"></span>

    <button mat-button [matMenuTriggerFor]="menu">
      {{ autenticado ? "Hola " + nombre : "Inicie sesión" }}
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item routerLink="login" *ngIf="!autenticado">
        <img
          src="assets/icons/login.svg"
          style="margin-right: 16px; vertical-align: middle"
        />
        <span>Iniciar sesión</span>
      </button>
      <!-- <button mat-menu-item routerLink="cambio_password" *ngIf="autenticado">
        <img
          src="assets/icons/lock.svg"
          style="margin-right: 16px; vertical-align: middle"
        /> 
        <span>Cambiar password</span>
      </button> -->
      <button mat-menu-item *ngIf="autenticado" (click)="logout()">
        <img
          src="assets/icons/logout.svg"
          style="margin-right: 16px; vertical-align: middle"
        />
        <span>Cerrar sesión</span>
      </button>
      <div mat-menu-item>
        <img
          *ngIf="sonido"
          src="assets/icons/volume_up.svg"
          style="margin-right: 16px; vertical-align: middle"
        />
        <img
          *ngIf="!sonido"
          src="assets/icons/volume_off.svg"
          style="margin-right: 16px; vertical-align: middle"
        />

        <mat-slide-toggle
          primary
          [(ngModel)]="sonido"
          (click)="onToggleSoundStatus($event)"
        >
          Sonidos
        </mat-slide-toggle>
      </div>
      <button mat-menu-item routerLink="qr" *ngIf="!ambienteProd">
        <img src="assets/icons/qr_code_scanner.svg" style="margin-right: 16px; vertical-align: middle;">
        <span>QR</span>
      </button>
    </mat-menu>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav #snav [mode]="'over'" *ngIf="usuario != null">
      <app-menu [usuario]="usuario" (onMenu)="onMenu($event)"></app-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
