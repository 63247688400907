import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventParamDanios } from 'src/app/forms/danios/danios.component';
import {
  SeguridadComponent,
  SeguridadEvent,
} from 'src/app/forms/seguridad/seguridad.component';
import { EmitEventTransaccion } from 'src/app/forms/transaccion/transaccion.component';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CargaDaniosService } from 'src/app/services/carga-danios/carga-danios.service';
import { MatDialog } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { CamaraComponent } from 'src/app/camara/camara.component';
import * as moment from 'moment';

@Component({
  selector: 'app-mobile-carga-danios',
  templateUrl: './mobile-carga-danios.component.html',
  styleUrls: ['./mobile-carga-danios.component.scss']
})
export class MobileCargaDaniosComponent implements OnInit {
  @ViewChild('aceptar') btnAceptar!: MatButton;
  @ViewChild(SeguridadComponent) seguridadComponent;
  @ViewChild(CamaraComponent) camaraComponent;

  subtitulo: string = 'Seguridad';
  paso: number = 1;
  valid = false;
  
  // Seguridad
  cliente: IdsDescr | null = null;
  cuenta: IdDescr | null = null;
  subcuenta: IdDescr | null = null;
  puestoDeRecepcion: IdDescr | null = null;
  esProcesoUnificado: boolean | any = null;
  
  // CheckPoint
  vin: string | null = null;
  checkpoint: IdDescr | null = null;
  imputacion: IdDescr | null = null;
  responsable: IdDescr | null = null;
  
  // Daño
  tipoDeDanio: IdsDescr | null = null;
  parteDanio: IdsDescr | null = null;
  gravedadDeDanio: IdsDescr | null = null;
  cuadrante: IdDescr | null = null;
  unidadMedida: IdDescr | null = null;
  valorUnidadDeMedida: number = 0;
  observacion: string = '';
  
  // Transaccion
  transaccion: IdsDescr | null = null;
  inspectorAveria: IdDescr | null = null;
  remito: string | null = null;

  // Camara
  fechaDanio: string | null = null;
  captures: string[] = [];
  imagePath: string[] = [];
  funcion: Funcion | null = null;
  sistema: string = '59';

  constructor(
    private router: Router,
    private msg: NotificationService,
    private route: ActivatedRoute,
    private back: BackendService,
    private cargaDanios: CargaDaniosService,
    public dialog: MatDialog
  ) {
    let nav = this.router.getCurrentNavigation();
    this.funcion = <Funcion>nav?.extras?.state?.funcion;
    if (!this.funcion) {
      this.router.navigate(['']);
    }
    this.sistema = this.funcion.sistema.id;
  }

  ngOnInit(): void {}

  onEmitFocus() {
    this.btnAceptar.focus();
  }

  onValidTransaccion(valid: EmitEventTransaccion) {
    this.valid = valid.valid;
    this.inspectorAveria = valid.impector;
    this.transaccion = valid.transaccion;
  }

  onValidDanios(valid: EventParamDanios) {
    this.cuadrante = valid.cuadrante;
    this.tipoDeDanio = valid.danio;
    this.gravedadDeDanio = valid.gravedad;
    this.observacion = valid.observacion;
    this.parteDanio = valid.parte;
    this.unidadMedida = valid.unidadMedida;
    this.valid = valid.valid;
    this.valorUnidadDeMedida = valid.valor;
  }

  onValidOtros(valid: [boolean, Date]) {
    this.valid = true;
    // this.fechaDanio = valid[1];

    this.fechaDanio = moment(valid[1]).format('DD/MM/YYYY HH:mm:ss');
  }  

  onValidCheckPoint(valid: [boolean, string, IdDescr, IdDescr, IdDescr]) {
    this.valid = valid[0];
    this.vin = valid[1];
    this.checkpoint = valid[2];
    this.imputacion = valid[3];
    this.responsable = valid[4];
  }

  onValidSeguridad($event: SeguridadEvent) {
    this.cliente = $event.cliente;
    this.cuenta = $event.cuenta;
    this.subcuenta = $event.subcuenta;
    this.puestoDeRecepcion = $event.puestoDeRecepcion;

    //this.valid = $event.valid;
    this.valid = true;
  }

  videoOff(){
    this.camaraComponent.stopVideoOnly();
  }

  onClick() {
    if(this.paso < 5 && !this.valid) return;
    if (this.paso < 5) {
      this.setPaso(this.paso + 1);
    } else {
      this.avanzarConLaConfirmacion();
    }
  }

  private avanzarConLaConfirmacion() {
    if (!this.cliente) {
      this.msg.showError('cliente NULL', 'Error');
      return;
    }
    if (!this.cuenta) {
      this.msg.showError('cuenta NULL', 'Error');
      return;
    }
    if (!this.vin) {
      this.msg.showError('vin NULL', 'Error');
      return;
    }
    if (!this.tipoDeDanio) {
      this.msg.showError('tipoDeDanio NULL', 'Error');
      return;
    }
    if (!this.parteDanio) {
      this.msg.showError('parteDanio NULL', 'Error');
      return;
    }
    if (!this.gravedadDeDanio) {
      this.msg.showError('gravedadDeDanio NULL', 'Error');
      return;
    }
    if (!this.unidadMedida) {
      this.msg.showError('unidadMedida NULL', 'Error');
      return;
    }
    if (!this.checkpoint) {
      this.msg.showError('checkpoint NULL', 'Error');
      return;
    }
    if (!this.fechaDanio) {
      this.fechaDanio = moment().format('DD/MM/YYYY HH:mm:ss');
    }
    if (!this.funcion) {
      this.msg.showError('funcion NULL', 'Error');
      return;
    }
    this.back
      .validaDanios(
        this.cliente,
        this.cuenta,
        this.vin,
        this.tipoDeDanio,
        this.parteDanio,
        this.gravedadDeDanio,
        this.cuadrante,
        this.unidadMedida,
        this.valorUnidadDeMedida,
        this.observacion,
        this.checkpoint,
        this.imputacion,
        this.responsable,
        this.transaccion,
        this.inspectorAveria,
        this.fechaDanio,
        this.funcion
      )
      .subscribe(
        (data) => {
          if (data && data.ok) {
            this.msg
              .openDialog('Daño validado ✅', data.message)
              .subscribe((ret) => {
                if (ret && ret == 'SI') {
                  this.insertaDanio();
                }
              });
            return;
          } else {
            if (data) {
              this.msg.showError(data.err.message, data.err.error);
              return;
            }
          }
          this.msg.showError('Error desconocido');
        },
        (error: HttpErrorResponse) => {
          this.msg.showError(error.error.err.message, error.error.err.error);
        }
      );
  }

  private insertaDanio() {
    if (
      this.vin &&
      this.cliente &&
      this.cuenta &&
      this.parteDanio &&
      this.gravedadDeDanio &&
      this.unidadMedida &&
      this.checkpoint &&
      this.imputacion &&
      this.responsable &&
      this.tipoDeDanio &&
      this.transaccion
    ) {
      this.back
        .altaDanios(
          this.vin,
          this.cliente,
          this.cuenta,
          this.tipoDeDanio,
          this.parteDanio,
          this.gravedadDeDanio,
          this.cuadrante,
          this.unidadMedida,
          this.valorUnidadDeMedida,
          this.observacion,
          this.checkpoint,
          this.imputacion,
          this.responsable,
          this.transaccion,
          this.inspectorAveria,
          this.fechaDanio || moment().format('DD/MM/YYYY HH:mm:ss'),
          this.sistema,
          this.funcion?.id || 'InvalidFuncion'
        )
        .subscribe(
          (data) => {
            if (data && data.ok) {
              this.insertImage();
            } else {
              if (data && data.err) {
                this.msg.showError(data.err.message, data.err.error);
              } else {
                this.msg.showError('Error inesperado', 'Error');
              }
            }
          },
          (error: HttpErrorResponse) => {
            this.msg.showError(error.statusText, 'Error inesperado');
          }
        );
    } else {
      this.msg.showError('Cliente/Cuenta/parteDaño null', 'Error inesperado');
    }
  }
  public insertImage() {
    let payload = {
      vin: this.vin,
      cliente: this.cliente,
      cuenta: this.cuenta,
      funcion: this.funcion,
      sistema: this.sistema,
      tipoDeDanio: this.tipoDeDanio,
      partesDanio: this.parteDanio,
      gravedadDeDanio: this.gravedadDeDanio,
      partesDanios: this.parteDanio,
      cuadrante: this.cuadrante,
      unidadMedida: this.unidadMedida,
      valorUnidadDeMedido: this.valorUnidadDeMedida,
      observacion: this.observacion,
      checkpoint: this.checkpoint,
      imputacion: this.imputacion,
      responsable: this.responsable,
      transaccion: this.transaccion,
      inspectorAveria: this.inspectorAveria,
      fechaDanio: this.fechaDanio,
      image1: this.imagePath[0],
      image2: this.imagePath[1],
      image3: this.imagePath[2],
    };

    this.cargaDanios.altaImagenDanios(payload).subscribe(
      (data) => {
        if (data && data.ok) {
          this.imagePath = [];
          this.videoOff();
          this.msg.showSuccess('Daño insertado con exito');
          this.setPaso(this.paso - 3);
        } else {
          if (data && data.err) {
            this.msg.showError(data.err.message, data.err.error);
          } else {
            this.msg.showError('Error inesperado', 'Error');
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.msg.showError(error.statusText, 'Error inesperado');
      }
    );
  }

  public onCancel = () => {
    this.setPaso(this.paso - 1);
    if (this.paso == 0) {
      this.router.navigate(['/']);
    }
    if(this.paso == 4){
      this.videoOff();
    }
    this.imagePath = [];
  };

  public setPaso(paso: number) {
    this.valid = false;
    this.paso = paso;
    switch (this.paso) {
      case 1:
        this.subtitulo = 'Seguridad';
        break;

      case 2:
        this.subtitulo = `Check Point - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`;
        break;

      case 3:
        this.subtitulo = `Daños - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`;
        break;

      case 4:
        this.subtitulo = `Transaccion - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`;
        break;

      case 5:
        this.subtitulo = `Otros e imagenes - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`;
        break;

      default:
        //this.subtitulo = 'Error'; o 0, que salio
        break;
    }
  }
}
