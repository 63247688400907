<mat-card>
  <mat-card-header>
    <mat-card-title>Ingreso Produccion</mat-card-title>
    <mat-card-subtitle>{{ subtitulo }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content style="height: fit-content">
    <app-seguridad [funcion]="funcion" [aceptar]="aceptar" [conRecepcion]="false" *ngIf="paso == 1"
      (valid)="onValidSeguridad($event)"></app-seguridad>

    <app-control-de-danios-ecom #formControDanios *ngIf="!esProcesoUnificado && paso == 2"
      (valid)="onValidControlDaniosEcom($event)" [funcion]="funcion" [cliente]="cliente" [cuenta]="cuenta"
      (emitFocus)="onEmitFocus()"></app-control-de-danios-ecom>
    
    <app-ingreso-vin-unificado *ngIf="esProcesoUnificado && paso == 2" [funcion]="funcion"
      (valid)="onValidIngresoVinUnificado($event)" (EnableButton)="onEnableButton($event)" [cuenta]="cuenta"
      [cliente]="cliente" [subcuenta]="subcuenta" [esProcesoUnificado]="esProcesoUnificado" [okey]="okey">
    </app-ingreso-vin-unificado>
    
    <app-generar-op-pendiente *ngIf=" paso == 4" [funcion]="funcion"
    (valid)="onValidOperacion($event)" [cuenta]="cuenta"
      [cliente]="cliente" [subcuenta]="subcuenta" [esProcesoUnificado]="esProcesoUnificado" [aceptar]="aceptar">
    </app-generar-op-pendiente>
    <Label *ngIf="paso == 3" class="label" textWrap="true">
      {{ descripcionVin }}
    </Label>
    <app-danios [cliente]="cliente" [finalizar]="finalizar" (valid)="onValidControlDanios($event)"
      (okEnter)="onEmitFocus()" *ngIf="paso == 3"></app-danios>
  </mat-card-content>
  <mat-card-actions>
    <div style="text-align: center" [ngClass]="{
        hide:
          (esProcesoUnificado && paso == 2) || (esProcesoUnificado && paso == 3)
      }">
      <button mat-raised-button (click)="onCancel()">Cancelar</button>
      <button type="submit" mat-raised-button (click)="onClick()" [disabled]="!valid" #aceptar color="primary">
        Aceptar
      </button>
    </div>
    <div style="text-align: center; width: 100%" [ngClass]="{
        hide:
          (!esProcesoUnificado && paso != 2) ||
          (esProcesoUnificado && paso != 2) ||
          (!esProcesoUnificado && paso == 2)
      }">
      <div class="buttons">
        <button mat-raised-button type="submit" class="button button1" (click)="cargarDanios(1)" [disabled]="!acces1"
          (keydown)="focus($event)" (keydown.enter)="pressClick($event, 1)" (keydown.tab)="pressClick($event, 1)" #okey>
          1-OK
        </button>
        <button mat-raised-button class="button button2" (click)="setPaso(3, 2)" [disabled]="!acces2" #conDanio
          (keydown)="focus($event)" (keydown.enter)="pressClick($event, 2)" (keydown.tab)="pressClick($event, 2)">
          2-OBS
        </button>
        <button mat-raised-button class="button button3" (click)="setPaso(3, 3)" [disabled]="!acces3" #rechazo
          (keydown)="focus($event)" (keydown.enter)="pressClick($event, 3)" (keydown.tab)="pressClick($event, 3)">
          3-RECH
        </button>
        <button mat-raised-button class="button button3" (click)="setPaso(4, 4)" [disabled]="!acces4" #bloqueo
          (keydown)="focus($event)" (keydown.enter)="pressClick($event, 4)" (keydown.tab)="pressClick($event, 4)">
          4-BLOQ
        </button>
      </div>
      <button mat-raised-button color="primary" (click)="onCancel()" style="width: 40%; margin-top: 10px">
        Volver
      </button>
    </div>
    <div style="text-align: center; width: 100%" [ngClass]="{
        hide:
          (!esProcesoUnificado && paso != 3) ||
          (esProcesoUnificado && paso != 3) ||
          (!esProcesoUnificado && paso == 3)
      }">
      <button mat-raised-button (click)="onCancel()">Cancelar</button>
      <button type="submit" mat-raised-button color="primary" (click)="nuevoDanio()" [disabled]="!valid">
        Nuevo Daño
      </button>
      <section style="margin-top: 10px">
        <button mat-raised-button color="primary" style="width: 70%" (click)="cargarDanios(2)" [disabled]="!valid"
          #finalizar>
          Finalizar
        </button>
      </section>
    </div>
  </mat-card-actions>
</mat-card>
<!-- [disabled]="!valid"  -->