import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parse } from 'path';
import { throwError, Observable } from 'rxjs';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { Usuario } from 'src/app/model/usuario';
import { environment } from 'src/environments/environment';

type response = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
};

type responseDaniosEcom = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
  marca: IdsDescr;
};

@Injectable({
  providedIn: 'root',
})
export class BackendControlDaniosEcomService {
  public usrServer: String = environment.urlBackend;
  public urlSeguridad: String = this.usrServer + 'seguridad/';
  public urlToken: String = this.usrServer + 'token/';
  public urlMobile: String = this.usrServer + 'mobile/';

  constructor(private httpClient: HttpClient) {}

  // TODO: Listo para pruebas de integración
  //consulta el vin de danios ecom en el componente control-de-danios-ecom
  public consultaVinDaniosEcom(
    vin: string,
    funcion: Funcion | null,
    cliente: string,
    cuenta: number
  ): Observable<response> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'consultaVinDaniosEcom';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;
    const sistema = funcion?.sistema.id || 'ErrorFuncionSistema';
    const body = {
      pais: parsedUser.pais.id,
      vin,
      cliente,
      cuenta,
      token,
      sistema,
      funcion: funcion?.id,
    };

    return this.httpClient.post<response>(url, body, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  // TODO: Listo para pruebas de integración
  // consulta el modelo de danios en el componente control-de-danios-ecom
  public consultaModeloDaniosEcom(
    vin: string,
    funcion: Funcion | null,
    cliente: string,
    cuenta: number,
    modelo: string
  ): Observable<responseDaniosEcom> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'consultaModeloDaniosEcom';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;
    const body = {
      pais: parsedUser.pais.id,
      vin,
      cliente,
      cuenta,
      funcion: funcion?.id,
      modelo,
    };

    return this.httpClient.post<responseDaniosEcom>(url, body, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  // TODO: Listo para pruebas de integración
  //consulta los danios en control-de-danios-ecom
  public consultaDestinoDaniosEcom(
    funcion: Funcion | null,
    cliente: string,
    cuenta: number,
    destino: string
  ): Observable<response> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'consultaModeloDaniosEcom';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;
    const sistema = funcion?.sistema.id || 'ErrorFuncionSistema';

    const body = {
      pais: parsedUser.pais.id,
      cliente,
      cuenta,
      token,
      sistema,
      funcion: funcion?.id,
      destino,
    };

    return this.httpClient.post<response>(url, body, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  // TODO: Listo para pruebas de integración
  //da de alta un danio en el componente mobile-danios
  public altaDaniosEcom(
    cliente: IdsDescr,
    cuenta: IdDescr,
    subcuenta: IdDescr,
    tipo: string,
    funcion: Funcion,
    vin: string,
    modelo: IdsDescr,
    marca: IdsDescr,
    destino: IdsDescr
  ): Observable<response> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'altaDaniosEcom';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;
    const body = {
      pais: parsedUser.pais,
      cliente,
      cuenta,
      subcuenta,
      vin,
      tipo,
      modelo,
      marca,
      destino,
    };

    return this.httpClient.post<response>(url, body, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  // TODO: Listo para pruebas de integración
  // da de alta un historico de danios en el componente mobile-danios
  public altaHistoricoDaniosEcom(
    funcion: Funcion | null,
    cliente: IdsDescr,
    cuenta: IdDescr,
    vin: string,
    tipoDeDanio: IdsDescr,
    partesDanios: IdsDescr,
    gravedadDeDanio: IdsDescr,
    cuadrante: IdDescr | null,
    unidadMedida: IdDescr,
    valorUnidadDeMedida: number,
    observacion: string,
    tipo: string | null
  ): Observable<response> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'altaHistoricoDaniosEcom';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });
    const parsedUser = JSON.parse(user) as Usuario;
    const body = {
      pais: parsedUser.pais,
      cliente,
      cuenta,
      vin,
      tipoDeDanio,
      partesDanios,
      gravedadDeDanio,
      cuadrante,
      unidadMedida,
      valorUnidadDeMedida,
      observacion,
      funcion,
      tipo,
    };

    return this.httpClient.post<response>(url, body, {
      headers: { authorization: `Bearer ${token}` },
    });
  }
}
