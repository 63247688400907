<h2 mat-dialog-title>Resumo do Despacho</h2>
<mat-dialog-content>
  <b>Nota fiscal:</b>
  <ul>
    <li>
      {{ notaFiscal }}
    </li>
  </ul>

  <b>Concessionária:</b>
  <ul>
    <li>
      {{ concessionaria }}
    </li>
  </ul>

  <b>Transportador:</b>
  <ul>
    <li>
      {{ transportador }}
    </li>
  </ul>

  <b>Endereço:</b>
  <ul>
    <li>
      {{ endereco }}
    </li>
  </ul>

  <div align="right">
    <button mat-button mat-dialog-close cdkFocusInitial>Ok</button>
  </div>
</mat-dialog-content>