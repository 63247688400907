import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MenuComponent } from './menu/menu.component';
import { LoginComponent } from './sesion/login/login.component';
import { MobileCargaDaniosComponent } from './funciones/mobile-carga-danios/mobile-carga-danios.component';
import { CambioPasswordComponent } from './sesion/cambio-password/cambio-password.component';
import { MobileTransferenciaComponent } from './funciones/mobile-transferencia/mobile-transferencia.component';
import { DialogoComponent } from './genericos/dialogo/dialogo.component';
import { SeguridadComponent } from './forms/seguridad/seguridad.component';
import { CheckpointComponent } from './forms/checkpoint/checkpoint.component';
import { DaniosComponent } from './forms/danios/danios.component';
import { TransaccionComponent } from './forms/transaccion/transaccion.component';
import { OtrosComponent } from './forms/otros/otros.component';
import { MobileCargaDanioReducidaComponent } from './funciones/mobile-carga-danio-reducida/mobile-carga-danio-reducida.component';
import { DaniosReducidaComponent } from './forms/danios-reducida/danios-reducida.component';
import { SpinnerOverlayComponent } from './genericos/spinner-overlay/spinner-overlay.component';
import { SpinnerInterceptor } from './services/spinner-interceptor';
import { MobileControlDespachoComponent } from './funciones/mobile-control-despacho/mobile-control-despacho.component';
import { MobileRecepcionComponent } from './funciones/mobile-recepcion/mobile-recepcion.component';
import { FormRecepcionComponent } from './forms/form-recepcion/form-recepcion.component';
import { MobileDaniosComponent } from './funciones/mobile-danios/mobile-danios.component';
import { ControlDeDaniosEcomComponent } from './forms/control-de-danios-ecom/control-de-danios-ecom.component';
import { MobileControlGuardiaComponent } from './funciones/mobile-control-guardia/mobile-control-guardia.component';
import { ControlDeGuardiaComponent } from './forms/control-de-guardia/control-de-guardia.component';
import { MobileRecomendacionVinComponent } from './funciones/mobile-recomendacion-vin/mobile-recomendacion-vin.component';
import { RecomandacionVinComponent } from './forms/recomandacion-vin/recomandacion-vin.component';
import { getDutchPaginatorIntl } from './traslator';
import { RegOpAutoComponent } from './forms/reg-op-auto/reg-op-auto.component';
import { ScannComponent } from './scann/scann/scann.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ScannviewComponent } from './scann/scannview/scannview.component';
import { CamaraComponent } from './camara/camara.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MobileControlIngresoEcomUnificadoComponent } from './funciones/mobile-control-ingreso-ecom-unificado/mobile-control-ingreso-ecom-unificado.component';
import { SeguridadEcomUnificado } from './forms/seguridad-ecom-unificado/seguridad-ecom-unificado.component';
import { RegOpOtComponent } from './forms/reg-op-ot/reg-op-ot/reg-op-ot.component';
import { MobileRegistrarOpOtComponent } from './funciones/mobile-registrar-op-ot/mobile-registrar-op-ot/mobile-registrar-op-ot.component';
import { QuaggaJSComponent } from './scann/quagga-js/quagga-js.component';
import { MobileRegistrarOperacionAutomaticaComponent } from './funciones/mobile-registrar-operacion-automatica/mobile-registrar-operacion-automatica.component';
import { MobileRegistrarOperacionForzadaComponent } from './funciones/mobile-registrar-operacion-forzada/mobile-registrar-operacion-forzada.component';
import { ReingresoDevolucionComponent } from './forms/reingreso-devolucion/reingreso-devolucion.component';
import { RegOpForzadaComponent } from './forms/registrar-operaciones-forzadas/reg-op-forzadas.component';
import { MobileReingresoDevolucionComponent } from './funciones/mobile-reingreso-devolucion/mobile-reingreso-devolucion.component';
import { IngresoVinUnificadoComponent } from './forms/ingreso-vin-unificado/ingreso-vin-unificado.component';
import { IngresoEcomUnificadoComponent } from './forms/ingreso-ecom-unificado/ingreso-ecom-unificado.component';
import { GenerarOpPendienteComponent } from './forms/generar-op-pendiente/generar-op-pendiente.component';
import { ConfirmDialogComponent } from './forms/confirm-dialog/confirm-dialog.component';
import { MobileDespachoMercadoNacionalComponent } from './funciones/mobile-despacho-mercado-nacional/mobile-despacho-mercado-nacional.component';
import { DespachoMercadoNacionalComponent } from './forms/despacho-mercado-nacional/despacho-mercado-nacional.component';
import { ResumenDialogComponent } from './genericos/resumen-dialog/resumen-dialog.component';
import { MobileEntradaEcomComponent } from './funciones/mobile-ingreso-ecom/mobile-entrada-ecom.component';
import { EntradaEcomComponent } from './forms/ingreso-ecom/entrada-ecom.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    LoginComponent,
    MobileCargaDaniosComponent,
    CambioPasswordComponent,
    MobileTransferenciaComponent,
    DialogoComponent,
    SeguridadComponent,
    CheckpointComponent,
    DaniosComponent,
    TransaccionComponent,
    OtrosComponent,
    MobileCargaDanioReducidaComponent,
    DaniosReducidaComponent,
    SpinnerOverlayComponent,
    MobileControlDespachoComponent,
    MobileRecepcionComponent,
    FormRecepcionComponent,
    MobileDaniosComponent,
    ControlDeDaniosEcomComponent,
    MobileControlGuardiaComponent,
    ControlDeGuardiaComponent,
    MobileRecomendacionVinComponent,
    RecomandacionVinComponent,
    MobileRegistrarOperacionAutomaticaComponent,
    RegOpAutoComponent,
    ScannComponent,
    ScannviewComponent,
    CamaraComponent,
    MobileControlIngresoEcomUnificadoComponent,
    SeguridadEcomUnificado,
    RegOpOtComponent,
    MobileRegistrarOpOtComponent,
    MobileRegistrarOperacionForzadaComponent,
    RegOpForzadaComponent,
    MobileReingresoDevolucionComponent,
    ReingresoDevolucionComponent,
    QuaggaJSComponent,
    IngresoVinUnificadoComponent,
    IngresoEcomUnificadoComponent,
    GenerarOpPendienteComponent,
    ConfirmDialogComponent,
    MobileDespachoMercadoNacionalComponent,
    DespachoMercadoNacionalComponent,
    ResumenDialogComponent,
    MobileEntradaEcomComponent,
    EntradaEcomComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatMenuModule,
    MatExpansionModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatCardModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    FormsModule,
    ZXingScannerModule,
    MatButtonToggleModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'en-GB'
    },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    {provide: ControlDeDaniosEcomComponent}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
