<div *ngIf="mostrar">
  <div  class="principal">



    <div id="interactive" class="viewport">
      <!-- QuaggaJS will populate this element -->
    </div>
    
    <div class="recuadroGris">
      <div class="line">
        <br>
      </div>
    </div>

    <div class="float">
      <button mat-fab color="primary" class="margenes" aria-label="Siguiente camara"
        (click)="onNextClick()"
      >
      <img src="assets/icons/cameraswitch.svg" >
      </button>
      <button mat-fab color="primary" class="margenes" aria-label="Cancelar"
        (click)="onCancelarClick()"
      >
      <img src="assets/icons/close.svg" >
      </button>
    </div>

  </div>
</div>