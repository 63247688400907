import { HttpClient } from '@angular/common/http';
import { Injectable, ViewEncapsulation } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { Funcion } from 'src/app/model/funcion';
import { Usuario } from 'src/app/model/usuario';
import { environment } from 'src/environments/environment';
import { BackendService } from '../backend.service';

type response = {
  ok:boolean;
  err:{
    error:string;
    message:string;
  },
};
type responseLO = {
  ok:boolean;
  lineasOperacion: any;
  err:{
    error:string;
    message:string;
  }
};
type responseRO = {
        ok:boolean;
        resultadoOperacion: boolean;
        err:{
          error:string;
          message:string;
        }
      };
@Injectable({
  providedIn: 'root'
})
export class ResgistracionOpOtService {
  usrServer: String = environment.urlBackend;
  urlSeguridad: String = this.usrServer+"seguridad/";
  urlToken: String = this.usrServer+"token/";
  urlMobile:String = `${this.usrServer}mobile/`;

  constructor(
    private httpClient: HttpClient
  ) { 

  }
//carga el orden de trabajo mediante el componente reg-op-ot
 public findOt(payload:any): Observable<response>{
    let tempUser = localStorage.getItem('usuario');
    let url = this.urlMobile+'validaOrdenDeTrabajo';

    if(tempUser){
      let usuario = <Usuario>JSON.parse( tempUser );
      let body = {
        pais: usuario.pais.id,
        cuenta: payload.cuenta.id,
        cliente: payload.cliente.id,
        ot: payload.ot,

      };       
      const httpOptions = BackendService.getJsonOptions();
      
      return this.httpClient.post<response>(url,body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }//findVin(vin:string,funcion:Funcion)

//obtiene el vin en el componente reg-op-ot
 public findVin(payload:any): Observable<response>{
    let tempUser = localStorage.getItem('usuario');
    let url = this.urlMobile+'validaVinOT';

    if(tempUser){
      let usuario = <Usuario>JSON.parse( tempUser );
      let body = {
        pais: usuario.pais.id,
        vin: payload.vin,
        ordenDeTrabajo: payload.ordenDeTrabajo,
        estado:{
          id:parseInt(payload.estado.id)
        }

      };
       const httpOptions = BackendService.getJsonOptions();
      return this.httpClient.post<response>(url,body, httpOptions);
    }
    return throwError({statusText:'Usuario no logueado'});
  }
//carga la operacion desde el componente reg-op-ot
   public findOperacion(payload: any): Observable<response>{
    let tempUser = localStorage.getItem('usuario');
    
    if(tempUser){
      let usuario = <Usuario>JSON.parse( tempUser );
      let body = {
        pais: usuario.pais.id,
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        subcuenta: payload.subcuenta.id,
        operacion: parseInt(payload.operacion),
        ordenDeTrabajo: payload.ordenDeTrabajo.id,
        vin: payload.vin,
      };
       const httpOptions = BackendService.getJsonOptions();

      return this.httpClient.post<response>(`${this.urlMobile}validaOperacionOT`, body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }
//ingresa proveedor en el componente reg op ot
 public findProveedor(payload: any): Observable<responseLO>{
    let tempUser = localStorage.getItem('usuario');
    
    if(tempUser){
      let usuario = <Usuario>JSON.parse( tempUser );
      let body = {
        pais: usuario.pais.id,
        proveedor: parseInt(payload.proveedor),
        operacion: parseInt(payload.operacion),
      };
      const httpOptions = BackendService.getJsonOptions();

      return this.httpClient.post<responseLO>(`${this.urlMobile}validaProveedor`, body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }
//carga resultado en el componenete reg op ot
  public findResultado(payload: any): Observable<responseRO>{
    let tempUser = localStorage.getItem('usuario');

    if(tempUser){
      let usuario = <Usuario>JSON.parse( tempUser );
      let body = {
        pais: usuario.pais.id,
        operacion: parseInt(payload.operacion),
        resultado: payload.resultado,
        linea: parseInt(payload.lineasProveedor.id)
      };
       const httpOptions = BackendService.getJsonOptions();
      
      return this.httpClient.post<responseRO>(`${this.urlMobile}validaResultado`, body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }
//registra una operacion en el componente mobile-registrar-op-ot
 public registrarOperacionPorOt(payload:any, funcion:Funcion|null): Observable<response>{
    let tempUser = localStorage.getItem('usuario');
    
    if(tempUser){
      let usuario = <Usuario>JSON.parse( tempUser );
      let body = {
        pais: usuario.pais,
        cliente: payload.cliente,
        cuenta: payload.cuenta,
        subcuenta: payload.subcuenta,
        proveedor:{id : payload.proveedor},
        ordenDeTrabajo: payload.ot,
        vin:payload.vin,
        lineas: [payload.lineasProveedor],
        operacion: parseInt(payload.operacion),
        funcion: funcion?.id
     };      
     const httpOptions = BackendService.getJsonOptions();

      return this.httpClient.post<response>(`${this.urlMobile}registrarOperacionPorOT`, body, httpOptions);
    }
    return throwError({statusText:'Usuario no logueado'});
  }
}