import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { QuaggaJSComponent } from 'src/app/scann/quagga-js/quagga-js.component';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';

@Component({
  selector: 'app-mobile-control-despacho',
  templateUrl: './mobile-control-despacho.component.html',
  styleUrls: ['./mobile-control-despacho.component.scss'],
})
export class MobileControlDespachoComponent implements OnInit {
  @ViewChild('controlQuagga') controlQuagga!: QuaggaJSComponent;

  funcion: Funcion | null = null;
  sistema: string = '59';
  paso: number = 1;
  valid = false;
  otherValid = false;
  // Seguridad
  cliente: IdsDescr | null = null;
  cuenta: IdDescr | null = null;
  subcuenta: number | null = null;

  form = new FormGroup({
    vin: new FormControl(null, Validators.required),
    otherVin: new FormControl(null, Validators.required),
    ubicacion: new FormControl(null, Validators.required),
  });
  ubicacionActual: string = 'Cargando...';
  modeloDescripcion: string = 'Cargando...';
  clienteId: string = 'Cargando...';
  subcuentaId: string = 'Cargando...';
  cuentaId: string = 'Cargando...';

  constructor(
    private router: Router,
    private msg: NotificationService,
    private back: BackendService,
    private el: ElementRef,
    private sound: SoundService
  ) {
    let nav = this.router.getCurrentNavigation();
    this.funcion = <Funcion>nav?.extras?.state?.funcion;
    if (!this.funcion) {
      this.router.navigate(['']);
    }
    this.sistema = this.funcion.sistema.id;
  }

  ngOnInit(): void {
    this.form.controls.otherVin.disable();
    this.form.controls.ubicacion.disable();

    this.form.statusChanges.subscribe((valid) => {
      let ret = <string>valid == 'VALID';
      this.valid = ret && this.otherValid;
    });

    this.form.controls.vin.valueChanges.subscribe((val) => {
      this.form.controls.otherVin.setValue(null);
      this.form.controls.ubicacion.setValue(null);
      this.form.controls.otherVin.disable();
      this.form.controls.ubicacion.disable();
      this.valid = false;
      this.otherValid = false;
    });
    this.setFocus('vin');
  }

  onScannStart() {
    this.controlQuagga.Iniciar();
  }

  onScannDetect($event) {
    this.form.controls.vin.setValue($event);
    this.onClickSearch();
  }

  public onCancel = () => {
    this.router.navigate(['/']);
  };

  onClick() {
    if (!this.valid) return;
    let payload = {
      vin: this.form.value.vin,
      ubicacion: this.form.value.ubicacion,
    };
    this.back.validaDespacho(payload).subscribe(
      (response) => {
        console.log('response: ', response);
      },
      (error) => {
        console.log('error: ', error);
      }
    );
  }
  onClickSearch2() {
    let otherVin = this.form.controls.otherVin.value;
    let vin = this.form.controls.vin.value;
    this.back
      .validaVinRecibido(
        otherVin,
        vin,
        this.sistema,
        this.funcion?.id || 'ErrorFuncion'
      )
      .subscribe(
        (data) => {
          if (data && data.ok) {
            this.modeloDescripcion = data.modeloDescripcion;
            this.ubicacionActual = data.ubicacionActual;
            this.clienteId = data.cliente;
            this.cuentaId = data.cuenta;
            this.subcuentaId = data.subcuenta;
            this.msg.showSuccess('VIN OK', 'VIN Recibido');
            this.sound.playBeepOk();
            this.setFocus('ubicacion');
          } else {
            this.msg.showError(data.err.message, data.err.error);
            this.setFocus('vin');
            this.sound.playBeepError();
          }
        },
        (error: HttpErrorResponse) => {
          this.msg.catchError(error);
          this.sound.playBeepError();
        }
      );
  }

  onClickSearch() {
    this.otherValid = false;
    this.form.controls.otherVin.disable();
    this.form.controls.ubicacion.disable();

    this.back
      .validaVinRemito(
        this.form.controls.vin.value,
        this.sistema,
        this.funcion?.id || 'ErrorFuncion'
      )
      .subscribe(
        (data) => {
          if (data && data.ok) {
            this.msg.showSuccess('VIN Exitoso', 'Control OK');
            this.sound.playBeepOk();
            this.setFocus('otherVin');
            this.otherValid = true;
            this.form.controls.otherVin.enable();
            this.form.controls.ubicacion.enable();
          } else {
            this.msg.showError(data.err.message, data.err.error);
            this.setFocus('vin');
            this.sound.playBeepError();
          }
        },
        (error: HttpErrorResponse) => {
          this.msg.catchError(error);
          this.sound.playBeepError();
        }
      );
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }

  nextFocus(event: any) {
    event.preventDefault();
    this.onClickSearch();
  }

  focusOtherVin(event: any) {
    event.preventDefault();
    this.onClickSearch2();
  }
}
