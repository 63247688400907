import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { IRecomendacionVin } from 'src/app/model/irecomandacionvin';
import { IVin } from 'src/app/model/ivin';
import { BackendRecomandacionVinService } from 'src/app/services/backendServices/recomendacionVin.service';
import { NotificationService } from 'src/app/services/notification.service';

export interface EventOnValidRecomandacionVin {
  valid:boolean;
  vin:IVin;
  calle:string;
  columna:string;
  grupo:IdDescr;
}

@Component({
  selector: 'app-recomandacion-vin',
  templateUrl: './recomandacion-vin.component.html',
  styleUrls: ['./recomandacion-vin.component.scss']
})
export class RecomandacionVinComponent implements OnInit {

  @Output() valid = new EventEmitter<EventOnValidRecomandacionVin>();
  @Output() changeVin = new EventEmitter<string>();
  @Output() changeObserv = new EventEmitter<string>();
  
  @Input() funcion:Funcion|null = null;
  @Input() cliente:IdsDescr|null = null;
  @Input() cuenta:IdDescr|null = null;
  @Input() subcuenta:IdDescr|null = null;

  @Input() paso:number|null = null;
  
  grupos: IdDescr[] = [];
  vin:IVin|null = null;
  ubicacion:{
    calle:string,
    columna:string
  }|null = null;
  form = new FormGroup({
    grupo: new FormControl( null, Validators.required),
    confirmVin: new FormControl(),
    observaciones: new FormControl()
  });
  metodo: any;
  recomendacionVin: IRecomendacionVin|null = null;
  valido = false;

  // Tabla
  isLoadingResults = true;
  isRateLimitReached = true;
  displayedColumns: string[] = [ 'number', 'title'];
  resultsLength = 0;
  operaciones:any;
  ubicacionString: string = '';

  constructor(
    private back:BackendRecomandacionVinService,
    private msg:NotificationService,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.back.findOperaciones().subscribe(
      data => {
        if(data && data.ok){
          this.grupos = data.grupoOperacion;
          return;
        }
        this.msg.contolError(data);
      },
      (error:HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );

    this.form.controls.grupo.valueChanges.subscribe(
      val => {
        this.isLoadingResults = true;
        this.isRateLimitReached = true;
        if(val){
          let grupo:IdDescr = this.form.controls.grupo.value;
          this.back.consultaRecomendacionVin(
            this.cliente?.id||"ErrorCliente", this.cuenta?.id||-1, this.subcuenta?.id||-1,grupo
          ).subscribe(
            data => {
              if(data && data.ok){                                
                this.vin = data.vin;
                this.ubicacion = data.ubicacion;
                this.ubicacionString = data.ubicacion.calle + '-' + data.ubicacion.columna;
                this.operaciones = data.operacionesPendientes;
                if(data.operacionesPendientes && data.operacionesPendientes.length>0){
                  this.isLoadingResults = false;
                  this.isRateLimitReached = false;                  
                }
                this.valido = true;
                let ret:EventOnValidRecomandacionVin = {
                  calle: this.ubicacion.calle,
                  columna: this.ubicacion.columna,
                  valid: true,
                  vin: this.vin,
                  grupo
                }
                this.valid.emit(ret);
                return;
              }
              this.msg.contolError(data);
            },
            (error:HttpErrorResponse) => {
              this.msg.catchError(error);
            }
          );
        }
      }
    );// this.form.controls.grupo.valueChanges.subscribe(

    this.form.controls.confirmVin.valueChanges.subscribe(
      val => {
        this.changeVin.emit(val);
      }
    );

    this.form.controls.observaciones.valueChanges.subscribe(
      val => {
        this.changeObserv.emit(val);
      }
    );
  }

  setFocus(name:string){
    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="'+name+'"]');
    if(invalidControl){
      setTimeout (() => {
        invalidControl.focus();
      }, 0);      
    }
  }

  focusVin(){
    if(this.paso === 3){
      this.setFocus('confirmVin');
    }
  }
}
