import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr } from 'src/app/model/iddescr';
import { NotificationService } from 'src/app/services/notification.service';
import { ResgistracionOperacionAutomaticaService } from 'src/app/services/registracion-operacion-automatica/registracion-operacion-automatica.service';
import { ResgistracionOpOtService } from 'src/app/services/registracion-operacion-ot/registracion-op-ot.service';
import { SoundService } from 'src/app/services/sound.service';

export interface EventOnValidRegOpOt {
  valid: boolean;
  ot: any;
  vin: any;
  operacion: any;
  proveedor: any;
  resultado: any;
  lineasProveedor: any;
}

@Component({
  selector: 'app-reg-op-ot',
  templateUrl: './reg-op-ot.component.html',
  styleUrls: ['./reg-op-ot.component.scss'],
})
export class RegOpOtComponent implements OnInit {
  @Input('aceptar') btnAceptar!: MatButton;
  @Input() funcion: Funcion | null = null;
  @Input() cliente: any;
  @Input() cuenta: any;
  @Input() subcuenta: any;
  @Output() valid = new EventEmitter<EventOnValidRegOpOt>();

  ordenDeTrabajo: any = null;
  estado: any = null;
  lineasOperaciones: IdDescr[] = [];

  form = new FormGroup({
    ot: new FormControl(null, Validators.required),
    vin: new FormControl(null, Validators.required),
    operacion: new FormControl(null, Validators.required),
    proveedor: new FormControl(null, Validators.required),
    resultado: new FormControl(null, Validators.required),
    lineasProveedor: new FormControl(null, Validators.required),
  });

  constructor(
    private back: ResgistracionOpOtService,
    private msg: NotificationService,
    private el: ElementRef,
    private sound: SoundService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form.statusChanges.subscribe((val) => {
      let ret = <string>val == 'VALID';
      this.valid.emit({
        valid: ret,
        ot: this.form.controls.ot.value,
        vin: this.form.controls.vin.value,
        operacion: this.form.controls.operacion.value,
        proveedor: this.form.controls.proveedor.value,
        resultado: this.form.controls.resultado.value,
        lineasProveedor: this.form.controls.lineasProveedor.value,
      });
    });
    this.setFocus('ot');
  }

  ClearForm() {
    this.form.reset();
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }

  buscarOt() {
    let payload = {
      ot: this.form.controls.ot.value,
      cuenta: this.cuenta,
      cliente: this.cliente,
    };
    if (!this.funcion) return;
    this.back.findOt(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.msg.showSuccess('Orden de Trabajo cargado con exito');
          this.sound.playBeepOk();
          this.setFocus('vin');
          this.form.controls.operacion.enable();
          this.ordenDeTrabajo = data.ordenDeTrabajo;
          this.estado = data.ordenDeTrabajo.estado;
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  focusNext(event: any, id: any) {
    if (id == 1) {
      event.preventDefault();
      this.buscarOt();
    }
    if (id == 2) {
      event.preventDefault();
      this.buscarVin();
    }
    if (id == 3) {
      event.preventDefault();
      this.buscarOperacion();
    }
    if (id == 4) {
      event.preventDefault();
      this.buscarProveedor();
    }
    if (id == 5) {
      this.setFocus('resultado');
    }
    if (id == 6) {
      event.preventDefault();
      this.buscarResultado();
    }
  }

  buscarVin() {
    let payload = {
      vin: this.form.controls.vin.value.toUpperCase(), 
      ordenDeTrabajo: this.ordenDeTrabajo,
      estado: this.estado,
    };
    this.back.findVin(payload).subscribe(
      (data) => {
        if (data && data.ok) {
          this.msg.showSuccess('Ok');
          this.setFocus('operacion');
          this.sound.playBeepOk();
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  buscarOperacion() {
    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      subcuenta: this.subcuenta,
      operacion: this.form.controls.operacion.value,
      ordenDeTrabajo: this.ordenDeTrabajo,
      vin: this.form.controls.vin.value.toUpperCase(),
    };
    this.back.findOperacion(payload).subscribe(
      (data) => {
        if (data && data.ok) {
          this.msg.showSuccess('Operacion cargada con exito');
          this.sound.playBeepOk();
          this.setFocus('proveedor');
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  buscarProveedor() {
    let payload = {
      proveedor: this.form.controls.proveedor.value,
      operacion: this.form.controls.operacion.value,
    };

    this.back.findProveedor(payload).subscribe(
      (data) => {
        if (data && data.ok) {
          this.msg.showSuccess('Proveedor cargado con exito');
          this.sound.playBeepOk();
          this.lineasOperaciones = data.lineasOperacion;
          this.setFocus('lineasProveedor');
          if (this.lineasOperaciones.length == 1) {
            this.form.controls.lineasProveedor.setValue(
              this.lineasOperaciones[0]
            );
            this.setFocus('resultado');
          }
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  buscarResultado() {
    this.form.controls.lineasProveedor.value.valor = this.form.controls.resultado.value;

    let payload = {
      operacion: this.form.controls.operacion.value,
      resultado: this.form.controls.resultado.value,
      lineasProveedor: this.form.controls.lineasProveedor.value,
    };
    this.back.findResultado(payload).subscribe(
      (data) => {
        if (data && data.ok) {
          this.msg.showSuccess('Resultado cargado con exito');
          this.sound.playBeepOk();
          this.btnAceptar.focus();
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }
}
