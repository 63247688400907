<div class="video-container" *ngIf="!error">
  <form [formGroup]="form" class="form-width" autocomplete="off">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Fecha del Daño</mat-label>
      <input
        matInput
        formControlName="fecha"
        [matDatepicker]="picker"
        placeholder="Ingrese fecha..."
        #fecha
        (keydown.enter)="nextImporte($event)"
        (keydown.tab)="nextImporte($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Importe</mat-label>
      <input
        matInput
        type="number"
        formControlName="importe"
        placeholder="Ingrese importe..."
        #importe
        (keydown.enter)="nextImporte($event)"
        (keydown.tab)="nextImporte($event)"
      />
    </mat-form-field>
    <div class="label-container">
      <mat-label>Imagenes del daño</mat-label>
      <mat-label *ngIf="cameraOn">CAMARA:ACTIVADA</mat-label>
    </div>
    <video #video id="video" class="center"></video>

    <div class="imagenes-buttons">
      <div class="container" *ngIf="!imgBoolean[0]">
        <button mat-button class="img-button" (click)="openVideo(0)">
          <img class="img-icons" src="assets/icons/photo_camera.svg" />
        </button>
      </div>

      <div class="container" *ngIf="imgBoolean[0]">
        <img [src]="imagePath[0]" class="img-button" />
        <button class="btn" (click)="borrarImage(0)">X</button>
      </div>

      <div class="container" *ngIf="!imgBoolean[1]">
        <button mat-button class="img-button" (click)="openVideo(1)">
          <img class="img-icons" src="assets/icons/photo_camera.svg" />
        </button>
      </div>

      <div class="container" *ngIf="imgBoolean[1]">
        <img [src]="imagePath[1]" class="img-button" />
        <button class="btn" (click)="borrarImage(1)">X</button>
      </div>

      <div class="container" *ngIf="!imgBoolean[2]">
        <button mat-button class="img-button" (click)="openVideo(2)">
          <img class="img-icons" src="assets/icons/photo_camera.svg" />
        </button>
      </div>

      <div class="container" *ngIf="imgBoolean[2]">
        <img [src]="imagePath[2]" class="img-button" />
        <button class="btn" (click)="borrarImage(2)">X</button>
      </div>
    </div>

    <div class="imagenes-buttons">
      <div class="container">
        <button
          class="fileButton"
          mat-raised-button
          color="primary"
          (click)="onClickFileInputButton($event, 0)"
        >
          <img class="img-icons" src="assets/icons/clip.svg" />
        </button>
        <input
          hidden
          #imageInput0
          type="file"
          accept="image/*"
          (change)="agregarImg($event, 0)"
        />
      </div>

      <div class="container">
        <button
          class="fileButton"
          mat-raised-button
          color="primary"
          (click)="onClickFileInputButton($event, 1)"
        >
          <img class="img-icons" src="assets/icons/clip.svg" />
        </button>
        <input
          hidden
          #imageInput1
          type="file"
          accept="image/*"
          (change)="agregarImg($event, 1)"
        />
      </div>

      <div class="container">
        <button
          class="fileButton"
          mat-raised-button
          color="primary"
          (click)="onClickFileInputButton($event, 2)"
        >
          <img class="img-icons" src="assets/icons/clip.svg" />
        </button>
        <input
          hidden
          #imageInput2
          type="file"
          accept="image/*"
          (change)="agregarImg($event, 2)"
        />
      </div>
    </div>

    <canvas
      #canvas
      id="canvas"
      [width]="WIDTH"
      [height]="HEIGHT"
      class="canvas-css"
    ></canvas>
  </form>
</div>

<div *ngIf="error">
  <div>
    <form [formGroup]="form" class="form-width">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Fecha del Daño</mat-label>
        <input
          matInput
          formControlName="fecha"
          [matDatepicker]="picker"
          placeholder="Ingrese fecha..."
          #fecha
          (keydown.enter)="nextImporte($event)"
          (keydown.tab)="nextImporte($event)"

        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Importe</mat-label>
        <input
          matInput
          type="number"
          formControlName="importe"
          placeholder="Ingrese importe..."
        />
      </mat-form-field>
    </form>
  </div>
  <div class="label-container">
    <mat-label>Imagenes del daño</mat-label>
    <mat-label>CAMARA:INACTIVA</mat-label>
  </div>
</div>
