<mat-card>
  <mat-card-header>
      <mat-card-title>{{titulo}}</mat-card-title>
      <mat-card-subtitle>{{subtitulo}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content style="height: fit-content;">
      <app-seguridad 
          [funcion]="funcion"
          [aceptar]="aceptar"
          *ngIf='paso == 1' 
          (valid)='onValidSeguridad($event)'            
      ></app-seguridad>
      <app-recomandacion-vin
        [funcion]="funcion"
        [cliente]="cliente"
        [cuenta]="cuenta"
        [subcuenta]="subcuenta"
        [paso]="paso"
        *ngIf='paso == 2 || paso == 3'
        (valid)='onValidRecomandacionVin($event)'
        (changeVin)='onChangeVin($event)'
        (changeObserv)='onChangeObserv($event)'
      ></app-recomandacion-vin>
      
  </mat-card-content>
  <mat-card-actions>
    <div style="text-align: center;" [ngClass]="{'hide' : show}">
        <button mat-raised-button (click)="onCancel()">Cancelar</button>
        <button type="submit" mat-raised-button (click)="onClick()" 
            #aceptar
            [disabled]="!valid"          
            color="primary"
            >Aceptar</button>
    </div>
    <div *ngIf='paso == 3' style="text-align: center;">
        <button mat-raised-button (click)="onLiberar()">Libarar</button>
        <button mat-raised-button (click)="onListaNegra()">Lista Negra</button>
        <button mat-raised-button (click)="onProcesar()">Procesar</button>
    </div>
  </mat-card-actions>
</mat-card>
