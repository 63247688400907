<mat-card>
    <mat-card-header>
        <mat-card-title>
            Transferencias
        </mat-card-title>
    </mat-card-header>
    <mat-card-content style="height: fit-content;">
        <form [formGroup]="form" autocomplete="off">
            <div layout="column">
                <div>
                    <mat-form-field appearance="fill" class="example-full-width">
                        <mat-label>Ingrese VIN</mat-label>
                        <input #elvin matInput placeholder="ABCD123456" formControlName="vin" 
                            (keydown.enter)="onVinKeyEnter($event)"
                            (keydown.tab)="onVinKeyEnter($event)"
                        />
                        <div matSuffix style="display:flex">
                            <mat-icon (click)="onClickSearch()">search</mat-icon>
                            <mat-icon (click)="onScannStart()" matSuffix>photo_camera</mat-icon>
                        </div>                        
                        <!-- <div #buscar mat-icon-button matSuffix (click)="onClickSearch()" [attr.aria-label]="'Buscar VIN'">
                            <img src="assets/icons/search.svg">
                        </div> -->
                        <mat-hint>Numero/Letras de 17 o 8 caracteres</mat-hint>                        
                        <mat-error *ngIf="form.controls['vin'].errors?.required" class="error-message">
                            VIN Requerido
                        </mat-error> 
                        <mat-error *ngIf="form.controls['vin'].errors?.pattern" class="error-message">
                            VIN Invalido
                        </mat-error>                        
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Marca:</mat-label>
                        <input matInput placeholder="XXXX" disabled [value]="marca" />            
                    </mat-form-field> 
                    <mat-form-field appearance="outline">
                        <mat-label>Modelo:</mat-label>
                        <input matInput placeholder="XXXX" disabled [value]="modelo" />            
                    </mat-form-field>   
                </div>                
                <div>
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Ubicacion actual:</mat-label>
                        <input matInput placeholder="XXXX" disabled [value]="ubicacion">            
                    </mat-form-field>    
                </div>
                <div>
                    <mat-form-field appearance="fill"   class="example-full-width">
                        <mat-label>Nueva Ubicacion:</mat-label>
                        <input matInput placeholder="XXXX" formControlName="newvin"
                            (keydown.enter)="onNewVinKeyEnter($event)"
                            (keydown.tab)="onNewVinKeyEnter($event)"
                        />  
                        <mat-error *ngIf="form.controls['newvin'].errors?.required" class="error-message">
                            Ubicacion requerida.
                        </mat-error>                              
                    </mat-form-field>    
                </div>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <div style="text-align: center;">
            <button mat-raised-button (click)="onCancel()">Cancelar</button>
            <button type="submit" mat-raised-button (click)="onClick()" 
                [disabled]="!(form.valid && inputNewZone)" color="primary">Aceptar</button>    
        </div>
    </mat-card-actions>
</mat-card>
<app-quagga-js #controlQuagga
    (scan)='onScannDetect($event)'
></app-quagga-js>