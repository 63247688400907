import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';

export type validCargaDanioType = {
  valid: boolean;
  vin: string;
  tipoDanio: IdsDescr;
  checkPoint: IdDescr;
  unidadDanio: IdDescr;
  valor: number;
  parte: IdsDescr;
  cuadrante: IdDescr;
  gravedad: IdsDescr;
  observacion: string;
  transaccion: IdsDescr | null;
  responsable: IdDescr | null;
  imputacion: IdDescr | null;
};

@Component({
  selector: 'app-danios-reducida',
  templateUrl: './danios-reducida.component.html',
  styleUrls: ['./danios-reducida.component.scss'],
})
export class DaniosReducidaComponent implements OnInit {
  @Output() valid = new EventEmitter<validCargaDanioType>();
  @Input() cliente: IdsDescr | null = null;
  @Input() cuenta: IdDescr | null = null;
  @Input() funcion: Funcion | null = null;
  @Input('aceptar') btnAceptar!: MatButton;

  checkPoints: IdDescr[] = [];
  danios: IdsDescr[] = [];
  unidadDanios: IdDescr[] = [];
  partes: IdsDescr[] = [];
  cuadrantes: IdDescr[] = [];
  gravedades: IdsDescr[] = [];
  // default Checkpoints
  transaccion: IdsDescr | null = null;
  responsable: IdDescr | null = null;
  imputacion: IdDescr | null = null;

  form: FormGroup = new FormGroup({
    vin: new FormControl(
      null,
      Validators.compose([
        Validators.pattern('[0-9A-Z]{8}(?:[0-9a-zA-Z]{9})?'),
        Validators.required,
      ])
    ),
    checkPoint: new FormControl(null, Validators.required),
    danio: new FormControl(null, Validators.required),
    unidadDanio: new FormControl(null, Validators.required),
    valor: new FormControl(null),
    parte: new FormControl(null, Validators.required),
    cuadrante: new FormControl(null),
    gravedad: new FormControl(null, Validators.required),
    observacion: new FormControl(null),
  });

  constructor(
    private back: BackendService,
    private msg: NotificationService,
    private sound: SoundService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.cliente) {
      this.back.consultaDanios(59, this.cliente.id).subscribe(
        (data) => {
          this.gravedades = data.gravedadDanio;
          this.partes = data.parteDanio;
          this.danios = data.tipoDanio;
          this.sound.playBeepOk();
        },
        (error: HttpErrorResponse) => {
          this.msg.showError(error.statusText, 'Error inesperado');
          this.sound.playBeepError();
        }
      );
    } else {
      this.msg.showError('No hay un cliente', 'Error');
    }

    this.form.controls.danio.valueChanges.subscribe((val) => {
      if (!this.cliente) return;
      this.back.consultaTipoDanios(this.cliente.id, val.id).subscribe(
        (data) => {
          if (data && data.ok) {
            this.unidadDanios = data.unidadMedidaPlaya;

            if (this.unidadDanios.length == 0) {
              this.msg.showInfo('No hay unidad de medidas');
              this.setFocus('parte');
              return;
            }

            this.setFocus('unidadDanio');

            if (this.unidadDanios.length == 1) {
              this.form.controls.unidadDanio.setValue(this.unidadDanios[0]);
              this.setFocus('valor');
            }
          } else {
            if (data && data.err) {
              this.msg.showError(data.err.message, data.err.error);
              this.sound.playBeepError();
            } else {
              this.msg.showError('Error inesperado');
              this.sound.playBeepError();
            }
          }
        },
        (error: HttpErrorResponse) => {
          this.msg.showError(error.statusText, 'Error inesperado');
        }
      );
    });

    this.form.controls.parte.valueChanges.subscribe((val) => {
      if (!this.cliente) return;
      this.back.consultaCuadrante(59, this.cliente.id, val.id).subscribe(
        (data) => {
          if (data && data.ok) {
            this.cuadrantes = data.cuadrante;

            if (this.cuadrantes.length == 0) {
              this.msg.showInfo('No hay cuadrantes');
              this.setFocus('gravedad');
              return;
            }

            this.setFocus('cuadrante');

            if (this.cuadrantes.length == 1) {
              this.form.controls.cuadrante.setValue(this.cuadrantes[0]);
              this.setFocus('gravedad');
            }
          } else {
            this.msg.showError('Error inesperado');
            this.sound.playBeepError();
          }
        },
        (error: HttpErrorResponse) => {
          this.msg.showError(error.statusText, 'Error inesperado');
          this.sound.playBeepError();
        }
      );
    });

    this.form.controls.checkPoint.valueChanges.subscribe((val) => {
      let vin = this.form.controls.vin.value;
      let checkPoint: IdDescr = this.form.controls.checkPoint.value;
      this.back
        .consultaCheckpointDefault(
          this.funcion,
          this.cliente?.id || 'ErrorClienteId',
          this.cuenta?.id || -1,
          vin,
          checkPoint.id
        )
        .subscribe(
          (data) => {
            if (data && data.ok) {
              this.transaccion = data.transaccion;
              this.responsable = data.responsable;
              this.imputacion = data.imputacion;
            } else {
              if (data && data.err) {
                this.msg.showError(data.err.message, data.err.error);
                this.sound.playBeepError();
              } else {
                this.msg.showError('Error inesperado');
                this.sound.playBeepError();
              }
            }
          },
          (error: HttpErrorResponse) => {
            this.msg.showError(error.statusText, 'Error inesperado');
            this.sound.playBeepError();
          }
        );
    });

    this.form.statusChanges.subscribe((valid) => {
      let ret = <string>valid == 'VALID';
      this.valid.emit({
        valid: ret,
        vin: this.form.controls.vin.value,
        tipoDanio: this.form.controls.danio.value,
        checkPoint: this.form.controls.checkPoint.value,
        cuadrante: this.form.controls.cuadrante.value,
        gravedad: this.form.controls.gravedad.value,
        observacion: this.form.controls.observacion.value,
        parte: this.form.controls.parte.value,
        unidadDanio: this.form.controls.unidadDanio.value,
        valor: this.form.controls.valor.value,
        transaccion: this.transaccion,
        responsable: this.responsable,
        imputacion: this.imputacion,
      });
    });
    this.setFocus('vin');
  }

  onClickSearch() {
    if (this.cliente) {
      if (this.cuenta) {
        this.back
          .consultaCheckpoint(
            this.form.controls.vin.value,
            this.cliente.id,
            this.cuenta.id,
            '59'
          )
          .subscribe(
            (data) => {
              if (data.ok) {
                this.checkPoints = data.checkpoint;
                this.setFocus('checkPoint');

                if (this.checkPoints.length == 1) {
                  this.form.controls.checkPoint.setValue(this.checkPoints[0]);
                  this.setFocus('danio');
                }
              }
            else {
              this.msg.showError(data.err.message, data.err.error);
              this.setFocus('vin');
              this.sound.playBeepError();
            }
          },
          (error:HttpErrorResponse) => {
            this.msg.catchError(error);
            this.sound.playBeepError();
          }
          );
      } 
    } 
    
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }

  nextDanio(event: any) {
    event.preventDefault();
    this.onClickSearch();
  }

  valorKeyEnter(event: any) {
    event.preventDefault();
    if (this.form.controls.valor.valid) {
      this.setFocus('parte');
    }
  }

  observacionKeyEnter(event: any) {
    event.preventDefault();
    if (this.form.controls.observacion.valid) {
      this.btnAceptar.focus();
    }
  }

  onSelectionChangeDanio(event: any) {
    if (this.form.controls.checkPoint.valid) {
      this.setFocus('danio');
    }
  }

  onSelectionChangeMedidas(event: any) {
    if (this.form.controls.danio.valid) {
      this.setFocus('unidadDanio');
    }
  }

  onSelectionChangeValor(event: any) {
    if (this.form.controls.unidadDanio.valid) {
      if (this.form.controls.unidadDanio.value.descripcion == 'No Informado') {
        this.setFocus('parte');
      } else {
        this.setFocus('valor');
      }
    }
  }

  onSelectionChangeCuadrante(event: any) {
    event.preventDefault();
    if (this.form.controls.parte.valid) {
      this.setFocus('cuadrante');
    }
  }

  onSelectionChangeGravedad(event: any) {
    if (this.form.controls.cuadrante.valid) {
      this.setFocus('gravedad');
    }
  }

  onSelectionChangeObservacion(event: any) {
    if (this.form.controls.gravedad.valid) {
      this.setFocus('observacion');
    }
  }
}
