import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DespachoMercadoNacionalComponent, EventOnValidDespMercNac } from 'src/app/forms/despacho-mercado-nacional/despacho-mercado-nacional.component';
import { SeguridadEvent } from 'src/app/forms/seguridad/seguridad.component';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';
import { StorageService } from 'src/app/services/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/forms/confirm-dialog/confirm-dialog.component';
import { BackendDespMercNacional } from 'src/app/services/backendServices/despachoMercNacional.service';

@Component({
  selector: 'app-mobile-despacho-mercado-nacional',
  templateUrl: './mobile-despacho-mercado-nacional.component.html',
  styleUrls: ['./mobile-despacho-mercado-nacional.component.scss']
})
export class MobileDespachoMercadoNacionalComponent implements OnInit {
  @ViewChild(DespachoMercadoNacionalComponent) 
  formControlDespMercNac!: DespachoMercadoNacionalComponent;

  funcion: Funcion | null = null;
  sistema: string = '59';
  paso: number = 1;
  subtitulo: string = 'Seguridad';
  // Seguridad
  cliente: IdsDescr | null = null;
  cuenta: IdDescr | null = null;
  subcuenta: IdDescr | null = null;
  //Valores Componente hijo
  valid = false;
  vin: any;
  operacion: any;
  proveedor: any;
  vinDetail: any;

  constructor(
    private storage: StorageService,
    private router: Router,
    private back: BackendDespMercNacional,
    private msg: NotificationService,
    private sound: SoundService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.funcion = this.storage.getFuncion(
      'mobile_despacho_mercado_nacional'
    );
    
    if (this.funcion) {
      this.sistema = this.funcion.sistema.id;
    }
  }

  onValidDespMecNac($event: EventOnValidDespMercNac) {
    //this.valid = $event.valid;
    this.valid = true;
    this.vin = $event.vin;
    this.vinDetail = $event.vinDetail;  
  }

  onClick() {
    if (this.paso < 2) {
      this.setPaso(this.paso + 1);
    } else {
      if(this.vinDetail?.vin){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: { message: 'Quer mesmo realizar o despacho?' }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.despachar();
            return;
          }
        })
      }else if (this.vinDetail?.vin.fechaBloqueoPreparacion != null || this.vinDetail?.vin.tieneOperacionPendientesBloquea || this.vinDetail == null){
        this.msg.showError('Não é possível realizar Despacho.');
        this.setPaso(2);
        this.sound.playBeepError();
      }
    }
  }

  despachar() {

    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      subCuenta: this.subcuenta,
      lineaHojaRuta: this.vinDetail,
    };

    this.back.despachar(payload).subscribe(
      (data) => {
        if (data && data.ok) {
          this.msg.showSuccess('Despacho Realizado com sucesso!');
          this.formControlDespMercNac.ClearForm();
          this.setPaso(2);
          this.sound.playBeepOk();
        } else {
          if (data && data.err) {
            this.msg.showError(data.err.message, data.err.error);
            this.formControlDespMercNac.ClearForm();
            this.setPaso(2);
            this.sound.playBeepError();
          } else {
            this.msg.showError('Error inesperado', 'Error');
            this.formControlDespMercNac.ClearForm();
            this.setPaso(2);
            this.sound.playBeepError();
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.msg.showError(error.statusText, 'Error inesperado');
        this.sound.playBeepError();
      }
    );
  }

  setPaso(nuevoPaso: number) {
    this.valid = false;
    this.paso = nuevoPaso;
    switch (this.paso) {
      case 1:
        this.subtitulo = 'Seguridad';
        break;
      case 2:
        this.subtitulo = `Despacho Mercado local - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`;
        break;

      default:
        break;
    }
  }

  onCancel = () => {
    this.setPaso(this.paso - 1);
    if (this.paso == 0) this.router.navigate(['/']);
  };

  onValidSeguridad($event: SeguridadEvent) {
    if ($event.valid) {
      this.cliente = $event.cliente;
      this.cuenta = $event.cuenta;
      this.subcuenta = $event.subcuenta;
    }
    //this.valid = $event.valid;

    //inicio
    this.valid = true;
    this.cliente = $event.cliente;
    this.cuenta = $event.cuenta;
    this.subcuenta = $event.subcuenta;
    //fin (esto no deberia ir cuando se valide de manera correcta)
  }
}

