<form [formGroup]="form">
    <mat-form-field appearance="fill" class="example-full-width" autocomplete="off">
        <mat-label>Cliente</mat-label>
        <mat-select formControlName="cliente"
        #cliente
        (selectionChange)="onSelectionChangeCliente($event)"
        (closed)="closeMethod(cliente, 1)"
        >
            <mat-option *ngFor="let cliente of clientes" [value]="cliente">
                {{cliente.descripcion}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>Cuenta</mat-label>
        <mat-select formControlName="cuenta"
        (selectionChange)="onSelectionChangeCuenta($event)"
         #cuenta
         (closed)="closeMethod(cuenta ,2)"
         >
            <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta">
                {{cuenta.descripcion}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="conSubcuenta" appearance="fill" class="example-full-width">
        <mat-label>SubCuenta</mat-label>
        <mat-select formControlName="subcuenta" 
        #subcuenta
        (closed)="closeMethod(subcuenta, 3)"
        (selectionChange)="onSelectionChangeSubcuenta($event)"
        >
            <mat-option *ngFor="let subcuenta of subcuentas" [value]="subcuenta">
                {{subcuenta.descripcion}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="esProcesoUnificado" appearance="fill" class="example-full-width">
        <mat-label>Puesto de Recepcion</mat-label>
        <mat-select formControlName="puestoDeRecepcion" 
        #puestoDeRecepcion
        (closed)="closeMethod(puestoDeRecepcion, 4)"
        (selectionChange)="onSelectionChangePuestoDeRecepcion($event)"
        >
         <mat-option *ngFor="let recepcion of puestoDeRecepciones" [value]="recepcion">
            {{recepcion}}
        </mat-option> 
        </mat-select>
    </mat-form-field>
</form>