<mat-card>
  <mat-card-title>Cambiar password</mat-card-title>
  <p *ngIf="error" class="error">
    {{ error }}
  </p>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
      <p>
        <mat-form-field>
          <input
            type="password"
            matInput
            placeholder="Clave anterior"
            formControlName="oldpassword"
            (keydown.enter)="oldPasswordKeyEnter($event)"
            (keydown.tab)="oldPasswordKeyEnter($event)"
          />
        </mat-form-field>
        <mat-error *ngIf="campoValido('oldpassword')">
          La contraseña ingresada no es valida.
        </mat-error>
      </p>
      <p>
        <mat-form-field>
          <input
            type="password"
            matInput
            placeholder="Nueva clave"
            formControlName="newpassword"
            (keydown.enter)="newPasswordKeyEnter($event)"
            (keydown.tab)="newPasswordKeyEnter($event)"
          />
        </mat-form-field>
        <mat-error *ngIf="campoValido('newpassword')">
          La contraseña ingresada no es valida.
        </mat-error>
      </p>
      <p>
        <mat-form-field>
          <input
            type="password"
            matInput
            placeholder="Verificar clave"
            formControlName="renewpassword"
            (keydown.enter)="renewPasswordKeyEnter($event)"
            (keydown.tab)="renewPasswordKeyEnter($event)"
          />
        </mat-form-field>
        <mat-error *ngIf="campoValido('renewpassword')">
          La contraseña ingresada no es valida.
        </mat-error>
      </p>
      <div class="button">
        <button
          type="submit"
          #btnCambiar="matButton"
          mat-raised-button
          color="primary"
          mat-button
          [disabled]="!form.valid"
        >
          Cambiar
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
