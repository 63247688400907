import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Funcion } from 'src/app/model/funcion';
import { ControlGuardiaServices } from 'src/app/services/control-guardia/control-guardia.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';

export type ControlGuardiaEventEmitterType ={
  valid:boolean;
  vin:string;
};

@Component({
  selector: 'app-control-de-guardia',
  templateUrl: './control-de-guardia.component.html',
  styleUrls: ['./control-de-guardia.component.scss']
})
export class ControlDeGuardiaComponent implements OnInit {

  @Input() funcion:Funcion|null = null;
  @Input('aceptar') btnAceptar!: MatButton
  @Input() cliente: any;
  @Input() cuenta: any;
  @Input() subcuenta: any;
  @Output() valid = new EventEmitter<ControlGuardiaEventEmitterType>();
  

  form = new FormGroup({
    vin: new FormControl(null, Validators.compose([
      Validators.pattern('[0-9A-Z]{8}(?:[0-9a-zA-Z]{9})?'),
      Validators.required
    ])),
  });
  
  constructor(
    private back: ControlGuardiaServices,
    private msg: NotificationService,
    private el: ElementRef,
    private sound : SoundService
  ) { }

  ngOnInit(): void {
    this.form.statusChanges.subscribe(
      valid => {
        let ret = ((<string>valid) == 'VALID');        
        this.valid.emit({
          valid:ret,
          vin:this.form.controls.vin.value
        });
      }
    );
    this.setFocus('vin');
  }

  avanzarConLaConfirmacion() {
    let vin = this.form.controls.vin.value;
    this.back.validaControlGuardia(
      this.funcion,this.cliente?.id||"ErrorClienteId",this.cuenta?.id||0, vin||"ErrorVIN"
    ).subscribe( data => {
      if(data && data.ok){
        this.msg.showSuccess("Control exitoso","Control Guardia");
        this.sound.playBeepOk();
        this.onEmitFocus();
        return;
      }
      if(data && !data.ok && data.err){
        this.msg.showError(data.err.message,data.err.error);
      }else{
        this.msg.showError("Error inesperado","Error");
      }
      this.sound.playBeepError();
    }, (error:HttpErrorResponse) => {
      this.msg.showError(error.message,"Error");
      this.sound.playBeepError();
    });
  }


  onEmitFocus(){
    this.btnAceptar.focus();
  }
  setFocus(name:string){
    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="'+name+'"]');
    if(invalidControl){
      setTimeout (() => {
        invalidControl.focus();
      }, 0);      
    }
  }
  public ClearForm(){
    this.form.reset();
    this.setFocus('vin');
  }

  onClickSearch(){
    this.avanzarConLaConfirmacion();
  }
    nextDanio(event:any){
    event.preventDefault();
    this.onClickSearch(); 
  }
}
