<mat-card>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
      <h2>Iniciar sesión</h2>
      <mat-error *ngIf="error">
        {{ error }}
      </mat-error>
      <mat-form-field appearance="fill" [hidden]="!paises">
        <mat-label>Seleccione un país</mat-label>
        <mat-select
          formControlName="fpais"
          #fpais
          (selectionChange)="onSelectionChangePais($event)"
          (closed)="onSelectionChangePais($event)"
        >
          <mat-option *ngFor="let pais of paises" [value]="pais.id">
            {{ pais.descripcion }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-template [hidden]="paises">Cargando paises...</ng-template>
      <mat-form-field class="full-width-input">
        <input
          matInput
          placeholder="Usuario"
          formControlName="username"
          (keydown.enter)="usernameKeyEnter($event)"
          (keydown.tab)="usernameKeyEnter($event)"

        />
        <mat-error> Ingrese un usuario válido </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input
          matInput
          type="password"
          placeholder="Password"
          formControlName="password"
          required
          (keydown.enter)="passwordKeyEnter($event)"
          (keydown.tab)="passwordKeyEnter($event)"
        />
        <mat-error> Ingrese un password válido </mat-error>
      </mat-form-field>
      <button
        formControlName="aceptar"
        mat-raised-button
        color="primary"
        [disabled]="!form.valid"
      >
        Iniciar sesión
      </button>
    </form>
  </mat-card-content>
</mat-card>
