import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';

export type EventParamDanios = {
  valid: boolean;
  danio: IdsDescr;
  unidadMedida: IdDescr;
  valor: number;
  parte: IdsDescr | null;
  cuadrante: IdDescr;
  gravedad: IdsDescr | null;
  observacion: string;
};

@Component({
  selector: 'app-danios',
  templateUrl: './danios.component.html',
  styleUrls: ['./danios.component.scss'],
})
export class DaniosComponent implements OnInit {
  @Output() valid = new EventEmitter<EventParamDanios>();
  @Output() okEnter = new EventEmitter();
  @Input() cliente: IdsDescr | null = null;
  @Input('finalizar') btnFinalizar!: MatButton;
  @Input('aceptar') btnAceptar!: MatButton;

  danios: IdsDescr[] = [];
  partes: IdsDescr[] = [];
  gravedades: IdsDescr[] = [];
  unidades: IdDescr[] = [];
  cuadrantes: IdDescr[] = [];

  daniosFiltro!: Observable<IdsDescr[]>;
  partesFiltro!: Observable<IdsDescr[]>;
  gravedadesFiltro!: Observable<IdsDescr[]>;
  form = new FormGroup({
    danio: new FormControl(null, [Validators.required, this.danioValidator()]),
    unidadMedida: new FormControl(null, Validators.required),
    valor: new FormControl(null),
    parte: new FormControl(null, [Validators.required, this.parteValidator()]),
    cuadrante: new FormControl(null),
    gravedad: new FormControl(null, [
      Validators.required,
      this.gravedadValidator(),
    ]),
    observacion: new FormControl(null),
  });
  salta: boolean = false;

  constructor(
    private back: BackendService,
    private msg: NotificationService,
    private el: ElementRef
  ) {}

  danioValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = this.getDanio(control.value);
      return forbidden == null
        ? { forbiddenName: { value: control.value } }
        : null;
    };
  }

  parteValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = this.getParte(control.value);
      return forbidden == null
        ? { forbiddenName: { value: control.value } }
        : null;
    };
  }

  gravedadValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = this.getGravedad(control.value);
      return forbidden == null
        ? { forbiddenName: { value: control.value } }
        : null;
    };
  }

  private _filter(value: string): IdsDescr[] {
    const filterValue = value?.toLowerCase();
    return this.danios.filter(
      (option) => !value || option.id?.toLowerCase().includes(filterValue)
    );
  }

  private _filterPartes(value: string): IdsDescr[] {
    if (!value || value.length == 0) {
      return this.partes;
    }
    return this.partes.filter((option) => option.id == value);
  }

  private _filterGravedades(value: string): IdsDescr[] {
    const filterValue = value?.toLowerCase();
    if (!value) {
      return this.gravedades;
    }
    return this.gravedades.filter(
      (option) => option.id.toLowerCase() == filterValue
    );
  }

  private getDanio(id_descr: string): IdsDescr | null {
    let danios = this.danios.filter(
      (option) => id_descr === option.id + ' - ' + option.descripcion
    );

    if (danios && danios.length > 0) {
      return danios[0];
    }
    return null;
  }

  onCuadranteChange(cuadrante: any) {
    if (cuadrante) {
      this.setFocus('gravedad');
    }
  }

  onParteSelected(parteEvent: MatAutocompleteSelectedEvent) {
    if (
      parteEvent &&
      parteEvent.option &&
      parteEvent.option.value &&
      this.form.controls.parte.valid
    ) {
      this.salta = true;
      //this.setFocus('observacion');
    }
  }

  onSelectionChangeUnidadMedida(event: any) {
    if (this.form.controls.unidadMedida.valid) {
      if (this.form.controls.unidadMedida.value == 'No Informado') {
        this.setFocus('parte');
      } else {
        this.setFocus('valor');
      }
    }
  }

  onObservacionKeyEnter(event: any) {
    event.preventDefault();
    this.setFocus('finalizar');
    this.btnFinalizar.focus();
  }

  ngOnInit(): void {
   // console.log('danios')

    this.back.consultaDanios(59, this.cliente?.id || 'ErrorCliente').subscribe(
      (data) => {
        if (data && data.ok) {
          this.danios = data.tipoDanio;
          this.partes = data.parteDanio;
          this.gravedades = data.gravedadDanio;

          this.daniosFiltro = this.form.controls.danio.valueChanges.pipe(
            startWith(null),
            map((value) => this._filter(value))
          );
          this.partesFiltro = this.form.controls.parte.valueChanges.pipe(
            startWith(null),
            map((value) => this._filterPartes(value))
          );
          this.gravedadesFiltro = this.form.controls.gravedad.valueChanges.pipe(
            startWith(null),
            map((value) => this._filterGravedades(value))
          );
        } else {
          this.msg.contolError(<any>data);
        }
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );

    this.form.controls.danio.valueChanges.subscribe((val: string) => {
      let danio = this.getDanio(val);
      if (danio) {
        this.back
          .consultaUnidadMedida(
            59,
            this.cliente?.id || 'ErrorCliente',
            danio.id
          )
          .subscribe(
            (data) => {
              if (data && data.ok) {
                if(data.unidadMedidaPlaya.length == 0){
                  this.unidades = [];
                  if(this.unidades.length == 0){
                    this.unidades.push({id:1, descripcion: "No Informado"})
                  }
                }else{
                  this.unidades = data.unidadMedidaPlaya;
                  this.setFocus('unidadMedida');
                }
                if (this.unidades.length == 1) {
                  this.setFocus('parte');
                }
                this.form.controls.unidadMedida.setValue(this.unidades[0])
              } else {
                this.msg.showError(
                  'Error al consultar Tipos de Dañios',
                  'Error'
                );
              }
            },
            (error) => {
              this.msg.showError('Error inesperado', 'Error');
            }
          );
      }
    });

    this.form.controls.parte.valueChanges.subscribe((val: string) => {
      let parte = this.getParte(val);

      if (parte) {
        this.back
          .consultaCuadrante(
            59,
            this.cliente?.id?.toString() || 'ErrorCliente',
            parte.id
          )
          .subscribe(
            (data) => {
              if (data && data.ok) {
                this.cuadrantes = data.cuadrante;
                this.setFocus('cuadrante');

                if (!this.cuadrantes || this.cuadrantes.length == 0) {
                  this.setFocus('gravedad');
                }
                if (this.cuadrantes.length == 1) {
                  this.form.controls.cuadrante.setValue(this.unidades[0]);
                  this.setFocus('gravedad');
                }
              } else {
                this.msg.showError(
                  'Error al consultar Tipos de Dañios',
                  'Error'
                );
              }
            },
            (error) => {
              this.msg.showError('Error inesperado', 'Error');
            }
          );
      }
    });

    this.form.statusChanges.subscribe((valid) => {
      let danio = this.getDanio(this.form.controls.danio.value);
      let ret = <string>valid == 'VALID';
      let parte = this.getParte(this.form.controls.parte.value);
      let gravedad = this.getGravedad(this.form.controls.gravedad.value);
      if (!danio) {
        danio = new IdsDescr();
      }
      this.valid.emit({
        cuadrante: this.form.controls.cuadrante.value,
        danio,
        gravedad: gravedad,
        observacion: this.form.controls.observacion.value,
        parte: parte,
        unidadMedida: this.form.controls.unidadMedida.value,
        valid: ret,
        valor: this.form.controls.valor.value,
      });
    });

    this.setFocus('danio');
  }

  public ClearForm() {
    this.form.reset();
    this.setFocus('danio');
  }

  getGravedad(value: string): IdsDescr | null {
    let ret: IdsDescr | null = null;
    let find = this.gravedades.find(
      (p) => p.id + ' - ' + p.descripcion == value
    );
    if (find) {
      ret = find;
    }
    return ret;
  }

  private getParte(val: string): IdsDescr | null {
    let ret: IdsDescr | null = null;
    let find = this.partes.find((p) => p.id + ' - ' + p.descripcion == val);
    if (find) {
      ret = find;
    }
    return ret;
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }

  nextDanio(event: any) {
    event.preventDefault();
    this.BuscarDanios();
  }

  BuscarDanios() {
    if (
      this.form.controls.danio.value == null ||
      this.form.controls.danio.value == ''
    )
      return;

    let danios = this._filter(this.form.controls.danio.value);
    if (!danios || danios.length == 0) {
      let aux = this.getDanio(this.form.controls.danio.value);
      if (aux) {
        danios = [aux];
      }
    }
    if (danios && danios.length > 0) {
      let d = danios[0];
      this.form.controls.danio.setValue(d.id + ' - ' + d.descripcion);
      this.setFocus('unidadMedida');
    }
  }

  nextValor(event: any) {
    event.preventDefault();

    if (this.form.controls.valor.valid) {
      this.setFocus('parte');
    } else {
      this.setFocus('parte');
    }
  }

  nextParte(event: any) {
    event.preventDefault();
    this.BuscarParte();
  }

  BuscarParte() {
    if (
      this.form.controls.parte.value == null ||
      this.form.controls.parte.value == ''
    )
      return;

    let partes = this._filterPartes(this.form.controls.parte.value);

    if (!partes || partes.length == 0) {
      let aux = this.getParte(this.form.controls.parte.value);
      if (aux) {
        partes = [aux];
      }
    }

    if (partes && partes.length > 0) {
      let p = partes[0];
      this.form.controls.parte.setValue(p.id + ' - ' + p.descripcion);
    }
  }

  nextGravedad(event: any) {
    event.preventDefault();
    this.salta = true;
    this.BuscarGravedad();
  }

  BuscarGravedad() {
    if (
      this.form.controls.gravedad.value == null ||
      this.form.controls.gravedad.value == ''
    )
      return;

    let gravedades = this._filterGravedades(this.form.controls.gravedad.value);

    if (!gravedades || gravedades.length == 0) {
      let aux = this.getGravedad(this.form.controls.gravedad.value);
      if (aux) {
        gravedades = [aux];
      }
    }

    if (gravedades && gravedades.length > 0) {
      let g = gravedades[0];
      this.form.controls.gravedad.setValue(g.id + ' - ' + g.descripcion);
      this.setFocus('observacion');
    }
  }
}
