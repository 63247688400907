<form [formGroup]="form" autocomplete="off">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Ingrese N° de OT</mat-label>
    <input
      oninput="this.value = this.value.toUpperCase()"
      matInput
      formControlName="ot"
      maxlength="256"
      placeholder="Ingrese un numero de OT..."
      (keydown.enter)="focusNext($event, 1)"
      (keydown.tab)="focusNext($event, 1)"
    />
    <button
      mat-icon-button
      matSuffix
      (click)="buscarOt()"
      [attr.aria-label]="'Buscar VIN'"
    >
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Ingrese N° de VIN</mat-label>
    <input
      matInput
      formControlName="vin"
      maxlength="256"
      placeholder="Ingrese un numero de Vin"
      (keydown.enter)="focusNext($event, 2)"
      (keydown.tab)="focusNext($event, 2)"
    />
    <button
      mat-icon-button
      matSuffix
      (click)="buscarVin()"
      [attr.aria-label]="'Buscar OT'"
    >
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Operacion</mat-label>
    <input
      matInput
      formControlName="operacion"
      maxlength="256"
      placeholder="Ingrese operacion..."
      (keydown.enter)="focusNext($event, 3)"
      (keydown.tab)="focusNext($event, 3)"
    />
    <button
      mat-icon-button
      matSuffix
      (click)="buscarOperacion()"
      [attr.aria-label]="'Buscar Operacion'"
    >
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Proveedor</mat-label>
    <input
      matInput
      formControlName="proveedor"
      maxlength="256"
      placeholder="Ingrese proveedor..."
      (keydown.enter)="focusNext($event, 4)"
      (keydown.tab)="focusNext($event, 4)"
    />
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Lineas de Operacion del Proveedor</mat-label>
    <mat-select
      formControlName="lineasProveedor"
      (SelectionChange)="focusNext($event, 5)"
      (closed)="focusNext($event, 5)"
    >
      <mat-option
        *ngFor="let operacion of lineasOperaciones"
        [value]="operacion"
      >
        {{ operacion.id }} - {{ operacion.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Ingrese Resultado</mat-label>
    <input
      matInput
      formControlName="resultado"
      maxlength="256"
      placeholder="Ingrese resultado..."
      (keydown.enter)="focusNext($event, 6)"
      (keydown.tab)="focusNext($event, 6)"
    />
  </mat-form-field>
</form>
