import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';

@Component({
  selector: 'app-checkpoint',
  templateUrl: './checkpoint.component.html',
  styleUrls: ['./checkpoint.component.scss'],
})
export class CheckpointComponent implements OnInit {
  @Input() cliente: IdsDescr | null = null;
  @Input() cuenta: IdDescr | null = null;
  @Input() funcion: Funcion | null = null;

  @Input('aceptar') btnAceptar!: MatButton;

  @Output() valid = new EventEmitter<
    [boolean, string, IdDescr, IdDescr, IdDescr]
  >();

  checkPoints: IdDescr[] = [];
  imputaciones: IdDescr[] = [];
  responsables: IdDescr[] = [];

  form = new FormGroup({
    vin: new FormControl(
      null,
      Validators.compose([
        Validators.pattern('[0-9A-Z]{8}(?:[0-9a-zA-Z]{9})?'),
        Validators.required,
      ])
    ),
    checkPoint: new FormControl(null, Validators.required),
    imputacion: new FormControl(null, Validators.required),
    responsable: new FormControl(null, Validators.required),
  });

  constructor(
    private back: BackendService,
    private msg: NotificationService,
    private sound: SoundService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.form.controls.checkPoint.valueChanges.subscribe((val: IdDescr) => {
      if (val) {
        let x = val as IdDescr;
        this.cargarImputaciones(x.id.toString());
      } else {
        this.imputaciones = [];
      }
    });

    this.form.controls.imputacion.valueChanges.subscribe((val: IdDescr) => {
      if (val) {
        this.cargarResponsables(val.id);
      } else {
        this.responsables = [];
      }
    });

    this.form.controls.responsable.valueChanges.subscribe((val: IdDescr) => {
      if (val) {
        let vin = this.form.controls.vin.value;
        let checkPoint = this.form.controls.checkPoint.value;
        let imputacion = this.form.controls.imputacion.value;
        this.valid.emit([true, vin, checkPoint, imputacion, val]);
        this.setFocus('aceptar');
      } else {
        this.valid.emit([
          false,
          '',
          new IdDescr(),
          new IdDescr(),
          new IdDescr(),
        ]);
      }
    });
    this.setFocus('vin');
  }

  cargarResponsables(idImputacion: number) {
    if (!this.cliente || !this.cuenta || !this.funcion) {
      this.msg.showError('Cliente/Cuenta/Funcion NULL', 'Error inesperado');
    } else {
      let chk: IdDescr = this.form.controls.checkPoint.value;
      this.back
        .consultaResponsable(
          this.cliente.id,
          this.cuenta.id,
          this.funcion.id,
          this.funcion.sistema.id,
          chk.id,
          idImputacion
        )
        .subscribe(
          (data) => {
            if (data) {
              if (data.ok) {
                this.responsables = data.data;
                this.setFocus('responsable');
                if (this.responsables.length == 1) {
                  this.form.controls.responsable.setValue(this.responsables[0]);
                  this.setFocus('aceptar');
                }
              } else {
                this.msg.showError('Data FALSE', 'Error Inesperado');
              }
            } else {
              this.msg.showError('Data NULL', 'Error Inesperado');
            }
          },
          (error: HttpErrorResponse) => {
            this.msg.showError(error.statusText, 'Error Inesperado');
          }
        );
    }
  }

  onClickSearch() {
    this.checkPoints = [];
    if (this.cuenta && this.cliente) {
      if (this.funcion?.sistema.id) {
        this.back
          .consultaCheckpoint(
            this.form.controls.vin.value,
            this.cliente.id,
            this.cuenta.id,
            this.funcion?.sistema.id
          )
          .subscribe(
            (ret) => {
              if (ret.ok) {
                this.form.controls.vin.setValue(ret.vin);
                this.checkPoints = ret.checkpoint;
                this.sound.playBeepOk();

                this.setFocus('checkPoint');

                if (this.checkPoints.length == 1) {
                  this.form.controls.checkPoint.setValue(this.checkPoints[0]);
                  this.setFocus('imputacion');
                }
              } else {
                this.msg.showError(ret.err.message, ret.err.error);
                this.setFocus('vin');
                this.sound.playBeepError();
              }
            },
            (error:HttpErrorResponse) => {
              this.msg.catchError(error);
              this.sound.playBeepError();
            }
          );
      } 
    } 
  }

  private cargarImputaciones(val: string) {
    if (!this.cliente || !this.cuenta || !this.funcion) {
      this.msg.showError('Cliente/Cuenta/Funcion NULL', 'Error inesperado');
    } else {
      this.back
        .consultaImputaciones(
          this.cliente.id,
          this.cuenta.id,
          this.funcion.id,
          this.funcion.sistema.id,
          val
        )
        .subscribe(
          (data) => {
            if (data) {
              if (data.ok) {
                this.imputaciones = data.data;
                this.setFocus('imputacion');

                if (this.imputaciones.length == 1) {
                  this.form.controls.imputacion.setValue(this.imputaciones[0]);
                  this.setFocus('responsable');
                }
              } else {
                this.msg.showError('Data FALSE', 'Error Inesperado');
              }
            } else {
              this.msg.showError('Data NULL', 'Error Inesperado');
            }
          },
          (error) => {
            this.msg.showError(error, 'Error Inesperado');
          }
        );
    }
  }
  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }

  nextDanio(event: any) {
    event.preventDefault();
    this.onClickSearch();
  }

  onSelectionChangeCheckPoint(event: any) {
    if (this.form.controls.checkPoint.valid) {
      this.setFocus('imputacion');
    }
  }
  onSelectionChangeImputacion(event: any) {
    if (this.form.controls.imputacion.valid) {
      this.setFocus('responsable');
    }
  }

  onSelectionChangeResponsable(event: any) {
    if (this.form.controls.responsable.valid) {
      this.btnAceptar.focus();
    }
  }
}
