<mat-card>
    <mat-card-header>
      <mat-card-title>Despacho Mercado Nacional</mat-card-title>
      <mat-card-subtitle>{{ subtitulo }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content style="height: fit-content">
      <!-- Selector de cliente, cuenta, subcuenta -->
      <app-seguridad
        [funcion]="funcion"
        [aceptar]="aceptar"
        *ngIf="paso == 1"
        (valid)="onValidSeguridad($event)"
      ></app-seguridad>
  
      <!-- Selector de despacho por vin -->
      <app-despacho-mercado-nacional
        [funcion]="funcion"
        (valid)="onValidDespMecNac($event)"
        *ngIf="paso == 2"
        [cliente]="cliente"
        [cuenta]="cuenta"
        [subcuenta]="subcuenta"
        [aceptar]="aceptar"
      ></app-despacho-mercado-nacional>
    </mat-card-content>
  
    <!-- Actions -->
    <mat-card-actions>
      <div style="text-align: center">
        <button mat-raised-button (click)="onCancel()">Cancelar</button>
        <button
          type="submit"
          mat-raised-button
          (click)="onClick()"
          #aceptar
          color="primary"
        >
          Aceptar
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
  <!-- [disabled]="!valid"  -->
  