<form [formGroup]="form" autocomplete="off">
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>VIN</mat-label>
        <input matInput 
        formControlName="vin" 
        maxlength="256" 
        placeholder="Ingrese un VIN..."
        (keydown.enter)="nextDanio($event)"
        (keydown.tab)="nextDanio($event)">
        <button mat-icon-button matSuffix (click)="onClickSearch()" [attr.aria-label]="'Buscar VIN'">
            <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
        </button>
    </mat-form-field>
</form>