import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select/select';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Pais } from 'src/app/model/pais';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  form!: FormGroup;
  error: any;
  paises!: Pais[];
  dataPais: any;
  @ViewChild('fpais') btnPais!: MatSelect;

  constructor(
    private fb: FormBuilder,
    private back: BackendService,
    private router: Router,
    private storage: StorageService,
    private msg: NotificationService,
    private sound: SoundService,
    private el: ElementRef
  ) {}

  ngAfterViewInit(): void {
    this.setFocus('fpais');
  }

  ngOnInit(): void {
    
    this.storage.logOut(false);

    this.form = this.fb.group({
      username: ['', Validators.required],
      fpais: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.back.getPaises().subscribe(
      (paises) => {
        this.paises = paises;
        if (paises && paises.length > 0) {
          let id: number = paises[0].id;
          let valPais = this.storage.getParam('pais', id);
          this.form.controls.fpais.setValue(valPais);
        }
      },
      (error) => {
        this.error = 'Error al cargar paises: ' + error.statusText;
        this.sound.playBeepError();
      }
    );
  }

  async onSubmit() {
    this.error = null;

    try {
      this.back.login(
          this.form.value.username,
          this.form.value.password,
          this.form.value.fpais
        )
        .subscribe(
          async (data) => {
            if (!data || !data.ok) {
              if (data && data.err) {
                this.msg.showError(data.err.message, data.err.error);
                this.error = data.err.message;
              } else {
                this.msg.showError('Error en login', 'Error');
                this.error = 'Error en login';
              }
              await this.sound.playBeepError();
              return;
            }else if (!data.data) {
              this.error = 'Usuario o password incorrectos';
            } else {
              await this.sound.playBeepOk();
              
              this.storage.setParam('pais', <number>this.form.value.fpais);
              this.router.navigate(['/']);
              this.msg.showSuccess('Login OK');
              //this.storage.saveUsuario(data.data);
              /* console.log('guarda user', data.data);
              this.storage.setParam('pais', <number>this.form.value.fpais);
              console.log('setea pais', this.form.value.fpais);
              this.router.navigate(['/']);
              console.log('navega a home');
              this.msg.showSuccess('Login OK');
              console.log('login ok'); */
            }
          },
          async (error) => {
            this.error = error.statusText;
            await this.sound.playBeepError();
          }
        );
    } catch (err) {
      this.error = err;
      await this.sound.playBeepError();
    }
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }

  onSelectionChangePais(event: any) {
    if (this.form.controls.fpais.valid) {
      this.setFocus('username');
    }
  }

  usernameKeyEnter(event: any) {
    if (this.form.controls.username.valid) {
      this.setFocus('password');
    }
  }

  passwordKeyEnter(event: any) {
    if (this.form.controls.password.valid) {
      this.setFocus('aceptar');
    }
  }
}
