import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { observable } from 'rxjs';
import { validCargaDanioType } from 'src/app/forms/danios-reducida/danios-reducida.component';
import { SeguridadEvent } from 'src/app/forms/seguridad/seguridad.component';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-mobile-carga-danio-reducida',
  templateUrl: './mobile-carga-danio-reducida.component.html',
  styleUrls: ['./mobile-carga-danio-reducida.component.scss'],
})
export class MobileCargaDanioReducidaComponent implements OnInit {
  @ViewChild('btnaceptar') btnaceptar!: MatButton;

  subtitulo: string = 'Seguridad';
  paso: number = 1;
  valid = false;

  cliente: IdsDescr | null = null;
  cuenta: IdDescr | null = null;
  subcuenta: IdDescr | null = null;

  vin: string = 'ErrorVIN';
  tipoDanio: IdsDescr | null = null;
  parteDanio: IdsDescr | null = null;
  gravedadDanio: IdsDescr | null = null;
  cuadrante: IdDescr | null = null;
  unidadMedida: IdDescr | null = null;
  valorUnidadMedida: number = 0;
  observacion: string = '';
  checkpoint: IdDescr | null = null;
  imputacion: IdDescr | null = null;
  responsable: IdDescr | null = null;
  transaccion: IdsDescr | null = null;
  inspector: IdDescr | null = null;
  fecha: Date = new Date();

  funcion: Funcion | null = null;

  constructor(
    private router: Router,
    private msg: NotificationService,
    private back: BackendService
  ) {
    let nav = this.router.getCurrentNavigation();
    this.funcion = <Funcion>nav?.extras?.state?.funcion;
    if (!this.funcion) {
      this.router.navigate(['']);
    }
  }

  ngOnInit(): void {}

  onValidDanio($event: validCargaDanioType) {
    this.valid = $event.valid;
    this.vin = $event.vin;
    this.checkpoint = $event.checkPoint;
    this.cuadrante = $event.cuadrante;
    this.gravedadDanio = $event.gravedad;
    this.observacion = $event.observacion;
    this.parteDanio = $event.parte;
    this.tipoDanio = $event.tipoDanio;
    this.unidadMedida = $event.unidadDanio;
    this.valorUnidadMedida = $event.valor;
    this.transaccion = $event.transaccion;
    this.responsable = $event.responsable;
    this.imputacion = $event.imputacion;
  }

  onValidSeguridad($event: SeguridadEvent) {
    this.cliente = $event.cliente;
    this.cuenta = $event.cuenta;
    this.subcuenta = $event.subcuenta;
    //this.valid = $event.valid;
    this.valid = true;
  }

  onClick() {
    //if(!this.valid) return;
    if (this.paso < 2) {
      this.setPaso(this.paso + 1);
      return;
    }
    // el paso es 2 y le dio click a Aceptar
    if (!this.cliente) {
      this.msg.showError('Error en cliente NULL', 'Error inesperado');
      return;
    }
    if (!this.cuenta) {
      this.msg.showError('Error en cuenta NULL', 'Error inesperado');
      return;
    }
    if (!this.tipoDanio) {
      this.msg.showError('Error en tipoDanio NULL', 'Error inesperado');
      return;
    }
    if (!this.parteDanio) {
      this.msg.showError('Error en parteDanio NULL', 'Error inesperado');
      return;
    }
    if (!this.gravedadDanio) {
      this.msg.showError('Error en gravedadDanio NULL', 'Error inesperado');
      return;
    }
    if (!this.unidadMedida) {
      this.msg.showError('Error en unidadMedida NULL', 'Error inesperado');
      return;
    }
    if (!this.checkpoint) {
      this.msg.showError('Error en checkpoint NULL', 'Error inesperado');
      return;
    }
    if (!this.funcion) {
      this.msg.showError('Error en funcion NULL', 'Error inesperado');
      return;
    }

    this.back
      .validaDanios(
        this.cliente,
        this.cuenta,
        this.vin,
        this.tipoDanio,
        this.parteDanio,
        this.gravedadDanio,
        this.cuadrante,
        this.unidadMedida,
        this.valorUnidadMedida,
        this.observacion,
        this.checkpoint,
        this.imputacion,
        this.responsable,
        this.transaccion,
        this.inspector,
        this.fecha,
        this.funcion
      )
      .subscribe(
        (data) => {
          if (data && data.ok) {
            this.msg.openDialog('Carga Daño', data.message).subscribe((ret) => {
              if (ret && ret == 'SI') {
                this.avanzarCargaDanio();
              }
            });
          } else {
            if (data && !data.ok) {
              this.msg.showError(data.err.message, data.err.error);
            } else {
              this.msg.showError('Error inesperado');
            }
          }
        },
        (error: HttpErrorResponse) => {
          this.msg.showError(error.statusText, 'Error inesperado');
        }
      );
  }

  public onCancel = () => {
    this.setPaso(this.paso - 1);
    if (this.paso == 0) this.router.navigate(['/']);
  };

  private setPaso(paso: number) {
    this.valid = false;
    this.paso = paso;
    switch (this.paso) {
      case 1:
        this.subtitulo = 'Seguridad';
        break;
      case 2:
        this.subtitulo = `Carga daño reducida- ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id})`;
        break;
      default:
        //this.subtitulo = 'Error'; o 0, que salio
        break;
    }
  }

  private avanzarCargaDanio() {
    if (
      !this.cliente ||
      !this.cuenta ||
      !this.tipoDanio ||
      !this.parteDanio ||
      !this.gravedadDanio ||
      !this.unidadMedida ||
      !this.checkpoint ||
      !this.funcion
    ) {
      this.msg.showError('Datos corruptos', 'Error');
      return;
    }
    this.back
      .altaDanios(
        this.vin,
        this.cliente,
        this.cuenta,
        this.tipoDanio,
        this.parteDanio,
        this.gravedadDanio,
        this.cuadrante,
        this.unidadMedida,
        this.valorUnidadMedida,
        this.observacion,
        this.checkpoint,
        this.imputacion,
        this.responsable,
        this.transaccion,
        this.inspector,
        this.fecha,
        this.funcion.sistema.id,
        this.funcion.id
      )
      .subscribe(
        (data) => {
          if (data && data.ok) {
            this.msg.showInfo('Alta exitosa');
            this.setPaso(1);
            return;
          } else {
            if (data && data.err) {
              this.msg.showError(data.err.message, data.err.error);
              return;
            }
          }
          this.msg.showError('Error inesperado', 'Error');
        },
        (error: HttpErrorResponse) => {
          this.msg.showError(error.statusText, 'Error inesperado');
        }
      );
  }
}
