<div style="width: 100%; height: 100%; margin: 0em;
            left: 0em; top: 0em; background: white;
            position: fixed;
            z-index: 10;
            ">
  <div class="float">
    <button mat-fab color="primary" class="margenes" aria-label="Siguiente camara"
      (click)="onNextClick()"
    >
    <img src="assets/icons/cameraswitch.svg" >
    </button>
    <button mat-fab color="primary" class="margenes" aria-label="Cancelar"
      (click)="onCancelarClick()"
    >
    <img src="assets/icons/close.svg" >
    </button>
  </div>
  <zxing-scanner 
    [torch]="torchEnabled" 
    [(device)]="currentDevice" 
    (scanSuccess)="onCodeResult($event)"
    [formats]="formatsEnabled" 
    [tryHarder]="tryHarder" 
    (permissionResponse)="onHasPermission($event)"
    (camerasFound)="onCamerasFound($event)" 
    (torchCompatible)="onTorchCompatible($event)">
  </zxing-scanner>
</div>