import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MobileCargaDanioReducidaComponent } from './funciones/mobile-carga-danio-reducida/mobile-carga-danio-reducida.component';
import { MobileCargaDaniosComponent } from './funciones/mobile-carga-danios/mobile-carga-danios.component';
import { MobileControlDespachoComponent } from './funciones/mobile-control-despacho/mobile-control-despacho.component';
import { MobileRecepcionComponent } from './funciones/mobile-recepcion/mobile-recepcion.component';
import { MobileDaniosComponent } from './funciones/mobile-danios/mobile-danios.component';
import { MobileControlGuardiaComponent } from './funciones/mobile-control-guardia/mobile-control-guardia.component';
import { MobileTransferenciaComponent } from './funciones/mobile-transferencia/mobile-transferencia.component';
import { HomeComponent } from './home/home.component';
import { CambioPasswordComponent } from './sesion/cambio-password/cambio-password.component';
import { LoginComponent } from './sesion/login/login.component';
import { MobileRecomendacionVinComponent } from './funciones/mobile-recomendacion-vin/mobile-recomendacion-vin.component';
import { MobileRegistrarOperacionAutomaticaComponent } from './funciones/mobile-registrar-operacion-automatica/mobile-registrar-operacion-automatica.component';
import { ScannviewComponent } from './scann/scannview/scannview.component';
import { MobileControlIngresoEcomUnificadoComponent } from './funciones/mobile-control-ingreso-ecom-unificado/mobile-control-ingreso-ecom-unificado.component';
import { MobileRegistrarOpOtComponent } from './funciones/mobile-registrar-op-ot/mobile-registrar-op-ot/mobile-registrar-op-ot.component';
import { MobileRegistrarOperacionForzadaComponent } from './funciones/mobile-registrar-operacion-forzada/mobile-registrar-operacion-forzada.component';
import { MobileReingresoDevolucionComponent } from './funciones/mobile-reingreso-devolucion/mobile-reingreso-devolucion.component';
import { AutherizationGuard } from './utils/autherization.guard';
import { MobileDespachoMercadoNacionalComponent } from './funciones/mobile-despacho-mercado-nacional/mobile-despacho-mercado-nacional.component';
import { MobileEntradaEcomComponent } from './funciones/mobile-ingreso-ecom/mobile-entrada-ecom.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'cambio_password',
    component: CambioPasswordComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_transferencia',
    component: MobileTransferenciaComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_carga_danios',
    component: MobileCargaDaniosComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_control_despacho',
    component: MobileControlDespachoComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_recepcion',
    component: MobileRecepcionComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_control_guardia',
    component: MobileControlGuardiaComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_carga_danio_reducida',
    component: MobileCargaDanioReducidaComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_danios',
    component: MobileDaniosComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_recomendacion_vin',
    component: MobileRecomendacionVinComponent,
    canActivate: [AutherizationGuard],
  },
  //{ path: 'mobile_recomendacion_vin', component: MobileControlIngresoEcomUnificadoComponent },

  {
    path: 'mobile_recomendacion_vin',
    component: MobileRecomendacionVinComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_registrar_operacion_automatica',
    component: MobileRegistrarOperacionAutomaticaComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_despacho_mercado_nacional',
    component: MobileDespachoMercadoNacionalComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_ingresso_ecom',
    component: MobileEntradaEcomComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_control_despacho',
    component: MobileControlDespachoComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_registrar_ot',
    component: MobileRegistrarOpOtComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'qr',
    component: ScannviewComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_registrar_operacion_forzada',
    component: MobileRegistrarOperacionForzadaComponent,
    canActivate: [AutherizationGuard],
  },
  {
    path: 'mobile_reingreso_devolucion',
    component: MobileReingresoDevolucionComponent,
    canActivate: [AutherizationGuard],
  },

  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
