<mat-card>
    <mat-card-header>
        <mat-card-title>{{titulo}}</mat-card-title>
        <mat-card-subtitle>{{subtitulo}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <!-- Selector de cliente, cuenta, subcuenta -->
        <app-seguridad *ngIf='paso == 1'
            (valid)='onValidSeguridad($event,1)'
            [funcion]="funcion"
            [aceptar]="aceptar"  
        ></app-seguridad>

        <!-- Formulario de recepción -->
        <app-form-recepcion *ngIf='paso == 2'
            (valid)="onValidRecepcion($event)"
            [cliente]="cliente"
            [cuenta]="cuenta"
            [funcion]="funcion"
            [subcuenta]="subcuenta"
            (focusEvent)="onFocusEvent()"
        ></app-form-recepcion>
    </mat-card-content>
    <mat-card-actions>
        <div style="text-align: center;">
            <button mat-raised-button (click)="onCancel()">Cancelar</button>
            <button type="submit" mat-raised-button (click)="onClick()" 
                #aceptar
                [disabled]="!valid"
                color="primary">Aceptar</button>    
        </div>
    </mat-card-actions>
</mat-card>