import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Funcion } from 'src/app/model/funcion';
import { BackendRegOpAutoService } from '../../services/backendServices/registrarOpAuto.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';
import { MatButton } from '@angular/material/button';
import { RegistrarOperacionForzadaService } from 'src/app/services/registrar-operacion-forzada/registrar-operacion-forzada.service';
import { IdDescr } from 'src/app/model/iddescr';


export interface EventOnValidRegOpOt{
  valid:boolean;
  vin: any;
  operacion: any;
}

@Component({
  selector: 'app-reg-op-forzadas',
  templateUrl: './reg-op-forzadas.component.html',
  styleUrls: ['./reg-op-forzadas.component.scss'],
})
export class RegOpForzadaComponent implements OnInit {

  @Input() funcion:Funcion|null=null;
  @Input('aceptar') btnAceptar !: MatButton
  @Input() cliente: any;
  @Input() cuenta: any;
  @Input() subcuenta: any;
  @Output() valid = new EventEmitter<EventOnValidRegOpOt>();

  lineasOperacion:IdDescr[]= [];
  
  form = new FormGroup({
    vin: new FormControl(null, Validators.required),
    operacion: new FormControl(null, Validators.required)
  });

  enableOperacion = false;
  
  constructor(
    private msg:NotificationService,
    private el: ElementRef,
    private sound: SoundService,
    private router: Router,
    private back: RegistrarOperacionForzadaService
  ) { }

  ngOnInit(): void {
    this.form.statusChanges.subscribe(
      val => {
        let ret = ((<string>val) == 'VALID');
        this.valid.emit({
           valid:ret,
           vin: this.form.controls.vin.value,
           operacion:this.form.controls.operacion.value
          });
      }
    );
    this.setFocus('vin')
    this.buscarListadoOpForzadas() 
  }

 ClearForm(){
    this.form.reset();
  }

  setFocus(name:string){
    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="'+name+'"]');
    if(invalidControl){
      setTimeout (() => {
        invalidControl.focus();
      }, 0);      
    }
  }

  onClickSearch(){
    let payload = {
      vin: this.form.controls.vin.value,
      cliente: this.cliente,
      cuenta: this.cuenta,
      subcuenta: this.subcuenta
    };
    if(!this.funcion) return;
    this.back.validaVinOpForzada(payload, this.funcion).subscribe(
      (data: any) => {
        if(data && data.ok){
          this.msg.showSuccess('Vin encontrado');
          this.setFocus('operacion')
          this.sound.playBeepOk();
          this.form.controls.operacion.enable();       
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error:HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  buscarListadoOpForzadas(){  
      this.back.listadoOpForzadas().subscribe(data => {
        
        if(data && data.ok){
          this.lineasOperacion = data.operacionesForzadas
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error:HttpErrorResponse) => {
        this.msg.catchError(error);
      });
  }

  nextDanio(event:any){
    event.preventDefault();
    this.onClickSearch();
  }

  validaOperacion(){
    let payload = {
      vin: this.form.controls.vin.value,
      cliente: this.cliente,
      cuenta: this.cuenta,
      operacion: this.form.controls.operacion.value,
      operacionesForzadas: this.lineasOperacion,
    };
    if(!this.funcion) return;
    this.back.validaOperacion(payload).subscribe(
      (data: any) => {
        if(data && data.ok){
          this.btnAceptar.focus();     
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error:HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  changeFocusAceptar(event:any){
    event.preventDefault();
    this.validaOperacion();
  }
}
