import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogoComponent } from 'src/app/genericos/dialogo/dialogo.component';
import { IVin } from "../../model/ivin";
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';
import { QuaggaJSComponent } from 'src/app/scann/quagga-js/quagga-js.component';
import { parse } from 'path';

@Component({
  selector: 'app-mobile-transferencia',
  templateUrl: './mobile-transferencia.component.html',
  styleUrls: ['./mobile-transferencia.component.scss']
})
export class MobileTransferenciaComponent implements OnInit {
  @ViewChild('buscar', {static : false}) btnBuscar!: MatButton;
  @ViewChild('controlQuagga') controlQuagga!: QuaggaJSComponent;
  
  inputNewZone = false;
  form: FormGroup = this.fb.group({
    vin: ['', Validators.compose( 
      [Validators.required,
      Validators.pattern('[0-9A-Z]{8}(?:[0-9a-zA-Z]{9})?')
      ]
    )],
    newvin: [{value: '', disabled: !this.inputNewZone}, Validators.required]      
  });
  marca: string = '';
  modelo:string = '';
  ubicacion: string = '';

  
  pais:number = parseInt(localStorage.getItem("pais") || '1');
  cuenta:number = 0;
  subcuenta:number = 0;
  cliente:string = '';
  vinParameter: IVin|null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private msg: NotificationService,
    private back: BackendService,
    private el: ElementRef,
    private sound:SoundService
  ) { 
    let nav = this.router.getCurrentNavigation();
    //aqui llega el supuesto vin
    this.vinParameter = <IVin>nav?.extras?.state?.vin;
   // console.log("los cambios sí se están aplicando");
  }

  ngOnInit(): void {
    let vin = '';
    if(this.vinParameter){
      vin = this.vinParameter.id;
    }
    this.form = this.fb.group({
      vin: [vin, Validators.compose( 
        [Validators.required,
        Validators.pattern('[0-9a-zA-Z]{8}(?:[0-9a-zA-Z]{9})?')
        ]
      )],
      newvin: [{value: '', disabled: !this.inputNewZone}, Validators.compose( 
        [Validators.required,
        Validators.pattern('[0-9A-Z_-]{4,20}')
        ]
      )]      
    });

    this.form.controls.vin.valueChanges.subscribe(val => {
      this.form.controls.newvin.disable();
      this.form.controls.newvin.setValue('');
      this.btnBuscar.disabled = false;
      this.marca = '';
      this.modelo = '';
      this.ubicacion = '';      
    });    

    this.setFocus('vin');
  }

  onScannStart(){
    this.controlQuagga.Iniciar();
  }

  onScannDetect($event){
    this.form.controls.vin.setValue( $event );
    this.onClickSearch();
  }

  onVinKeyEnter(event:any){
    event.preventDefault();
    if(this.form.controls.vin.valid){
      this.onClickSearch();
    }else{
      this.sound.playBeepError();
    }
  }

  onNewVinKeyEnter(event:any){
    event.preventDefault();
    if(this.form.controls.newvin.valid){
      this.onClick();
    }else{
      this.sound.playBeepError();
    }
  }

  public onCancel = () => {
    this.router.navigate(['/']);
  }

  onClick(){
    this.form.updateValueAndValidity();
    if(!this.form.valid) {
      this.sound.playBeepError();
      return;
    }
    let newvin:string = this.form.value.newvin;
    
    // Validar trasferencia
    let vin:string = this.form.value.vin;
    this.back.validarTrasferencia(
      this.pais, vin, 59, this.cuenta, 
      this.subcuenta, newvin, this.cliente
    ).subscribe(
      data => {
        if(data && data.ok){
          this.transferir();
        }else{
          this.msg.contolError(data);
        }
      },
      error => {
        this.msg.catchError(error);
      }
    );    
  }

  transferir(){
    let nuevaUbicacion:any = this.form.value.newvin;
    let vin:string = this.form.value.vin;
    this.back.realizarTrasferencia(
      this.pais,
      vin,
      59, 
      this.cuenta, 
      this.subcuenta, nuevaUbicacion, this.cliente
    ).subscribe(
      data => {
        if(data && data.ok){
          this.msg.showSuccess('Nueva ubicación asignada','Transferencia');                
          this.clearForm(true);
          this.sound.playBeepOk();
        }else{
          this.msg.contolError(data);
        }
      },
      error => {
        this.msg.showError('Error al reubicar: '+error.statusText);
        this.sound.playBeepError();   
      }
    );
  }

  private clearForm(reset:boolean = false){
    if(reset){      
      this.form.reset();
      this.setFocus('vin');
      this.form.controls.newvin.disable();
    } 
    this.marca = '';
    this.modelo = '';
    this.ubicacion = '';
  }

  onClickSearch(){
    this.form.controls.newvin.disable();
    let vin:string = this.form.value.vin;
    let pais: string | null = localStorage.getItem("pais");
    this.back.findVin(pais, vin, 59).subscribe(
      data =>{
        if(data.ok){
          this.marca = data.marca.id + " - "+ data.marca.descripcion;
          this.modelo = data.modelo.descripcion;
          this.cuenta = data.cuenta;
          this.subcuenta = data.subcuenta;
          this.cliente = data.cliente;
          this.inputNewZone = true;
          this.ubicacion = `Calle: ${data.calle} Columna: ${data.columna} Nivel: ${data.nivel}`;
          this.form.controls.newvin.enable();
          this.setFocus('newvin');                
          this.btnBuscar.disabled = true;
          this.sound.playBeepOk();
        }else{
          this.clearForm();
          this.msg.showError('VIN no encontrado');
          this.sound.playBeepError();             
        }        
      },
      error => {
        this.msg.catchError(error);
      }
    );
  }

  private setFocus(name:string):void{
    const control = this.el.nativeElement.querySelector('[formcontrolname="'+name+'"]');
    if(control){
      setTimeout (() => {
        control.focus();
      }, 0);      
    }
  }
}
