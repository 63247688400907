import { Component, Inject, OnInit, } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  notaFiscal: String,
  concessionaria: String,
  transportador: String,
  endereco: String,
}

@Component({
  selector: 'app-resumen-dialog',
  templateUrl: './resumen-dialog.component.html',
  styleUrls: ['./resumen-dialog.component.scss']
})
export class ResumenDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  notaFiscal: string = this.data.notaFiscal.toString();
  concessionaria: string = this.data.concessionaria.toString();
  transportador: string = this.data.transportador.toString();
  endereco: string = this.data.endereco.toString();

  ngOnInit(): void {
  }

  onOkClick(): void {
    //this.dialogRef.close(true);
  }

}
