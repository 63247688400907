<form [formGroup]="form" autocomplete="off">
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Daño</mat-label>
        <input type="text"
           placeholder="Ingrese un daño"
           aria-label="Daño Código"
           matInput
           [matAutocomplete]="auto"
           (keydown.enter)="nextDanio($event)"
           (keydown.tab)="nextDanio($event)"
           formControlName="danio"
           onfocus="this.select()"
           >
        <div mat-icon-button matSuffix (click)="BuscarDanios()" [attr.aria-label]="'Buscar VIN'">
            <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
        </div>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let danio of daniosFiltro | async" [value]="danio.id +' - ' + danio.descripcion">
              {{danio.id +' - ' + danio.descripcion}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div class= "wrapper-inputs">
        <mat-form-field appearance="fill" style="width: 48%;">
            <mat-label>Unidad de Medida</mat-label>
            <mat-select formControlName="unidadMedida" #unidadMedida
                (selectionChange)="onSelectionChangeUnidadMedida($event)"
            >
                <mat-option *ngFor="let unidad of unidades" [value]="unidad">
                    {{unidad.descripcion}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 48%;">
            <mat-label>Valor</mat-label>
            <input matInput type="number" formControlName="valor" 
                placeholder="valor..."
                (keydown.enter)="nextValor($event)"
                (keydown.tab)="nextValor($event)"
            >
        </mat-form-field>
    </div>
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Parte</mat-label>
        <input type="text"
           placeholder="Código parte"
           aria-label="Parte"
           matInput
           formControlName="parte"
           [matAutocomplete]="parteSelect"
           (keydown.enter)="nextParte($event)"
           (keydown.tab)="nextParte($event)"
           >
        <div mat-icon-button matSuffix (click)="BuscarParte()" [attr.aria-label]="'Buscar VIN'">
            <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
        </div>
        <mat-autocomplete autoActiveFirstOption 
            #parteSelect="matAutocomplete"
        >
            <mat-option *ngFor="let parte of partesFiltro | async" [value]="parte.id + ' - ' + parte.descripcion">
                {{parte.id + ' - ' + parte.descripcion}}
            </mat-option>
        </mat-autocomplete>        
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Cuadrante</mat-label>
        <mat-select formControlName="cuadrante" 
            (selectionChange)="onCuadranteChange($event)">
            <mat-option *ngFor="let cuadrante of cuadrantes" [value]="cuadrante">
                {{cuadrante.descripcion}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Gravedad</mat-label>
        <input type="text"
           placeholder="Gravedad"
           aria-label="Gravedad"
           matInput
           formControlName="gravedad"
           [matAutocomplete]="gravedadSelect"
           (keydown.enter)="nextGravedad($event)"
           (keydown.tab)="nextGravedad($event)"
           >
        <div mat-icon-button matSuffix (click)="BuscarGravedad()" [attr.aria-label]="'Buscar VIN'">
            <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
        </div>
        <mat-autocomplete autoActiveFirstOption 
            #gravedadSelect="matAutocomplete"
        >
            <mat-option *ngFor="let gravedad of gravedadesFiltro | async" 
                [value]="gravedad.id + ' - ' + gravedad.descripcion">
                {{gravedad.id + ' - ' + gravedad.descripcion}}
            </mat-option>
        </mat-autocomplete>        
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Observación</mat-label>
        <input matInput #message1 formControlName="observacion" 
        maxlength="256" placeholder="Observación..."
        (keydown.enter)="onObservacionKeyEnter($event)"
        (keydown.tab)="onObservacionKeyEnter($event)"
        >
    </mat-form-field>
</form>
