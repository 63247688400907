import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Funcion } from 'src/app/model/funcion';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';
import { MatButton } from '@angular/material/button';
import { IdDescr } from 'src/app/model/iddescr';
import { ReingresoDevolucionService } from 'src/app/services/reingreso-devolucion/reingreso-devolucion.service';
import { iif } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export interface EventOnValidReingresoDevolucion {
  valid: boolean;
  vin: any;
  transportista: any;
  chofer: any;
  notaFiscalMan: any;
  serieMan: any;
  virouMarketing: boolean;
  serieProveedor: any;
  notaFiscalProveedor: any;
  fechaNotaFiscal: any;
  remitoDevolucion: any;
  remitoTransportista: any;
}

@Component({
  selector: 'app-reingreso-devolucion',
  templateUrl: './reingreso-devolucion.component.html',
  styleUrls: ['./reingreso-devolucion.component.scss'],
})
export class ReingresoDevolucionComponent implements OnInit {
  @Input() funcion: Funcion | null = null;
  @Input('aceptar') btnAceptar!: MatButton;
  @Input() cliente: any;
  @Input() cuenta: any;
  @Input() subcuenta: any;
  @Output() valid = new EventEmitter<EventOnValidReingresoDevolucion>();

  transportistas: IdDescr[] = [];
  choferes: any[] = [];
  vinAllData: any = null;
  remitoTransportista: any = null;
  remitoDevolucion: any = null;
  estadoVehiculo: any = null;

  form = new FormGroup({
    vin: new FormControl(null, Validators.required),
    transportista: new FormControl(null, Validators.required),
    chofer: new FormControl(null, Validators.required),
    serieMan: new FormControl(null, Validators.required),
    notaFiscalMan: new FormControl(null, Validators.required),
    virouMarketing: new FormControl(null, Validators.required),
    serieProveedor: new FormControl(null, Validators.required),
    notaFiscalProveedor: new FormControl(null, Validators.required),
    fechaNotaFiscal: new FormControl(new Date(), Validators.required),
  });

  enableOperacion = false;

  constructor(
    private msg: NotificationService,
    private el: ElementRef,
    private sound: SoundService,
    private router: Router,
    private back: ReingresoDevolucionService
  ) {}

  ngOnInit(): void {
    this.form.statusChanges.subscribe((val) => {
      let ret = <string>val == 'VALID';
      this.valid.emit({
        valid: ret,
        vin: this.form.controls.vin.value,
        transportista: this.form.controls.transportista.value,
        chofer: this.form.controls.chofer.value,
        serieMan: this.form.controls.serieMan.value,
        notaFiscalMan: this.form.controls.notaFiscalMan.value,
        virouMarketing: this.form.controls.virouMarketing.value,
        serieProveedor: this.form.controls.serieProveedor.value,
        notaFiscalProveedor: this.form.controls.notaFiscalProveedor.value,
        fechaNotaFiscal: this.form.controls.fechaNotaFiscal.value,
        remitoDevolucion: this.remitoDevolucion,
        remitoTransportista: this.remitoTransportista,
      });
    });
    this.setFocus('vin');
  }

  ClearForm() {
    this.form.reset();
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }

  onClickSearch() {
    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      subcuenta: this.subcuenta,
      vin: this.form.controls.vin.value,
    };

    this.back.validaVin(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.msg.showSuccess('Vin encontrado');
          this.sound.playBeepOk();
          this.form.patchValue({
            notaFiscalMan: data.notaFiscalMAN,
            serieMan: data.serieMAN,
            virouMarketing: data.virouMarketing,
          });
          this.remitoDevolucion = data.remitoDevolucion;
          this.estadoVehiculo = data.vin.estadoVehiculo;

          if (data.transportistas.length === 0) {
            this.msg.contolError({
              ok: false,
              err: { error: '', message: 'No se encuentran transportistas' },
            });
            this.sound.playBeepError();
          }

          this.transportistas = data.transportistas;
          this.buscarChofer();

          this.setFocus('transportista');

          if (this.transportistas.length == 1) {
            this.form.controls.transportista.setValue(data.transportistas[0]);
            this.setFocus('chofer');
          }

          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  buscarChofer() {
    let payload = {
      transportista: this.form.controls.transportista.value,
    };

    this.back.consultaChoferes(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          if (data.choferes.length === 0) {
            this.msg.contolError({
              ok: false,
              err: { error: '', message: 'No se encuentran choferes' },
            });
            this.sound.playBeepError();
          }

          this.setFocus('chofer');
          this.choferes = data.choferes;

          if (data.choferes.length == 1) {
            this.form.controls.chofer.setValue(this.choferes[0]);
            this.setFocus('serieProveedor');
          }

          return;
        }

        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  serieProveedor() {
    let payload = {
      serieProveedor: this.form.controls.serieProveedor.value,
      estadoVehiculo: this.estadoVehiculo,
    };

    this.back.validaSerieProveedor(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.remitoTransportista = data.remitoTransportista;
          this.setFocus('notaFiscalProveedor');
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  notaFiscalProveedor() {
    let payload = {
      serieProveedor: this.form.controls.serieProveedor.value,
      estadoVehiculo: this.estadoVehiculo,
      notaFiscalProveedor: this.form.controls.notaFiscalProveedor.value,
      remitoTransportista: this.remitoTransportista,
    };
    this.back.validaNotaFiscalProveedor(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.setFocus('fechaNotaFiscal');
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  onSelectionChange(id: any) {
    if (id == 1) {
      this.buscarChofer();
    }
    if (id == 2) {
      this.setFocus('serieProveedor');
    }
  }

  nextFocus(event: any, id: any) {
    if (id == 1) {
      event.preventDefault();
      this.onClickSearch();
    }
    if (id == 3) {
      event.preventDefault();
      this.serieProveedor();
    }
    if (id == 6) {
      event.preventDefault();
      this.notaFiscalProveedor();
    }
    if (id == 7) {
      event.preventDefault();
      this.btnAceptar.focus();
    }
  }
}
