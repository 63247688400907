import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendServiceRecepcion } from 'src/app/services/api-backend/validaRecepcion.service';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RegistrarProcesoUnificadoService } from 'src/app/services/registrar-proceso-unificado/registrar-proceso-unificado.service';
import { SoundService } from 'src/app/services/sound.service';
//import { RecepcionEventType } from '../form-recepcion/form-recepcion.component';

export type RecepcionOp = {
  valid: boolean;
  operacion: number;
  operacionDesc: string;
}
@Component({
  selector: 'app-generar-op-pendiente',
  templateUrl: './generar-op-pendiente.component.html',
  styleUrls: ['./generar-op-pendiente.component.scss']
})

export class GenerarOpPendienteComponent implements OnInit {
  @Output() focusEvent = new EventEmitter();
  @Output() EnableButton = new EventEmitter();
  @Output() EnableButtons = new EventEmitter();
  @Output() valid = new EventEmitter<RecepcionOp>();
  @Input() funcion: Funcion | null = null;
  @Input() pais: any = null;
  @Input() cliente: any = null;
  @Input() cuenta: IdDescr | null = null;
  @Input('aceptar') btnAceptado!: MatButton;
  @Input() esProcesoUnificado !: boolean;
  @Input() subcuenta: IdDescr | null = null
  public autoComplete: boolean = false;
  form = new FormGroup({
    operacionDesc: new FormControl(null, Validators.compose([
      Validators.required
    ])),
    operacion: new FormControl(null, Validators.compose([
      Validators.required
    ])),
    
  });
  valido: boolean = false;
  sistema: string = '59';
  constructor(
    private backRPU: RegistrarProcesoUnificadoService,
    private msg: NotificationService,
    private sound: SoundService,
    private el: ElementRef,
    private router: Router ) {}

  ngOnInit(): void {
    this.form.controls.operacionDesc.disable();
    
  }
  public validar(event:boolean){
        this.valid.emit({
          valid: event,
          operacion: this.form.controls.operacion.value,
          operacionDesc: this.form.controls.operacionDesc.value
        });
  
  }
  public ClearForm() {
    this.form.reset();
    this.setFocus('operacion');
    
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }
  nextFocus(event: any, id: any) {
    if (id == 1) {
   //   console.log('ingresa al if 1');
      event.preventDefault();
      this.onClickOperacion();
    }
  }
  onEmitFocus(event: boolean) {
    //console.log('ingresa');
    this.btnAceptado.focus();
    this.btnAceptado.disabled = event;
  }
  onClickOperacion(){
    let op = this.form.controls.operacion.value
    let payload = {
      operacion: op
    };

          this.backRPU.validarOperacion(payload, this.funcion).subscribe(
              (data: any) => {
                //console.log('data operacion',data);
                if(data) {
                 // console.log('ingresa a data operacion',data);
                  this.valido = true;
                  
                  this.msg.showSuccess('Ok');
                  this.form.patchValue({
                    operacionDesc: data.descripcion,
                    valid : true
                  })
                  this.validar(true);
                  this.onEmitFocus(false);
                  this.sound.playBeepOk();
                  return;
                }
                 
                  return;
              },
              (error: HttpErrorResponse) => {
                this.form.patchValue({
                  operacionDesc: "",
                  valid : false
                })
                this.validar(false);
                this.onEmitFocus(true);
                this.msg.catchError(error);
                this.sound.playBeepError();
              }
            );
  }
}
