import { Funcion } from "./funcion";

export class User {

  userName!: string;
  id!: string;
  token!:string;
  idioma!: {
      id: number;
  };
  pais!: {
      id: number;
      descripcion: string;
  };
  menu!: {
      funciones: [Funcion];
  };

  constructor(data?: any) {
    if (data) {
      this.userName = data.id;
      this.token = data.token;
      this.idioma = data.idioma;
      this.pais = data.pais;
      this.menu = data.menu;
    }
  }
}
