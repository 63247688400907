import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { Usuario } from 'src/app/model/usuario';
import { environment } from 'src/environments/environment';
import { BackendService } from '../backend.service';

type response = {
  ok:boolean;
  err:{
    error:string;
    message:string;
  }
};

@Injectable({
  providedIn: 'root'
})
export class CargaDaniosService {
  
  public usrServer: String = environment.urlBackend;
  public urlSeguridad: String = this.usrServer+"seguridad/";
  public urlToken: String = this.usrServer+"token/";
  public urlMobile:String = this.usrServer;

  constructor(private httpClient: HttpClient) { }
//este se utiliza en el componente mobile carga danios, permitiendo la insercción del daño
  public altaImagenDanios(payload: any): Observable<response> {
    
   const httpOptions = BackendService.getJsonOptions();
    
    let tempUser = localStorage.getItem('usuario');
    
    let url = `${this.urlMobile}danios/altaDaniosImagen`;
 
    if(tempUser) {
      let usuario = <Usuario>JSON.parse( tempUser );
      
      let body = {
        image1: payload.image1,
        image2: payload.image2,
        image3: payload.image3,
        pais: usuario.pais,  
        vin: payload.vin,
        cliente: payload.cliente,
        cuenta: payload.cuenta,
        funcion: payload.funcion,
        sistema: payload.sistema,
        tipoDeDanio: payload.tipoDeDanio,
        partesDanios: payload.partesDanios,
        gravedadDeDanio: payload.gravedadDeDanio,
        cuadrante: payload.cuadrante,
        unidadMedida: payload.unidadMedida,
        valorUnidadDeMedida: payload.valorUnidadDeMedida,
        observacion: payload.observacion,
        checkpoint: payload.checkpoint,
        imputacion: payload.imputacion,
        responsable: payload.responsable,
        transaccion: payload.transaccion,
        inspectorAveria: payload.inspectorAveria,
        fechaDanio: payload.fechaDanio,
      };

      return this.httpClient.post<response>(url,body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});

  }

}
