import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EventOnValidRegOpOt, RegOpForzadaComponent } from 'src/app/forms/registrar-operaciones-forzadas/reg-op-forzadas.component';
import { SeguridadEvent } from 'src/app/forms/seguridad/seguridad.component';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { NotificationService } from 'src/app/services/notification.service';
import { RegistrarOperacionForzadaService } from 'src/app/services/registrar-operacion-forzada/registrar-operacion-forzada.service';
import { SoundService } from 'src/app/services/sound.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-mobile-registrar-operacion-forzada',
  templateUrl: './mobile-registrar-operacion-forzada.component.html',
  styleUrls: ['./mobile-registrar-operacion-forzada.component.scss']
})
export class MobileRegistrarOperacionForzadaComponent implements OnInit {

  @ViewChild(RegOpForzadaComponent) formControlRegistroForzado!: RegOpForzadaComponent;

  funcion:Funcion|null = null;  
  sistema: string = '59';
  valid = false;
  paso:number = 1;
  subtitulo:string = 'Seguridad';
  // Seguridad
  cliente:IdsDescr|null = null;
  cuenta:IdDescr|null = null;
  subcuenta:IdDescr|null = null;
  //reg-op-forzadas
  operacion:any|null = null;
  vin:any|null = null;
  
  constructor(
    private storage:StorageService,
    private router: Router,
    private back: RegistrarOperacionForzadaService,
    private msg: NotificationService,
    private sound: SoundService
  ) { 
    
  }

  ngOnInit() {
    this.funcion = this.storage.getFuncion('mobile_registrar_operacion_forzada');
    if(this.funcion){
      this.sistema = this.funcion.sistema.id;
    }
  }

  onValidRegOpOt($event:EventOnValidRegOpOt){
    if($event.valid){
      this.vin = $event.vin;
      this.operacion = $event.operacion;
    }
    this.valid = $event.valid; 
  }

  onClick(){
    if(this.paso < 2) {
    this.setPaso(this.paso+1);
    }
    else {
      this.postRegistrarOpForzada()
    }        
  }

  postRegistrarOpForzada(){
    let payload = {
      vin: this.vin,
      operacion: this.operacion,
      cuenta: this.cuenta,
      cliente: this.cliente,
    }
    this.back.insertarOperacionPendiente(payload).subscribe(
      data => {
        if(data && data.ok ){
          this.msg.showSuccess('Operacion Registrada');
          this.formControlRegistroForzado.ClearForm();
          this.setPaso(2);
          this.sound.playBeepOk();
        }else{
          if(data && data.err){
            this.msg.showError(data.err.message,data.err.error);
            this.formControlRegistroForzado.ClearForm();
            this.setPaso(2);
            this.sound.playBeepError();
          }else{
            this.msg.showError("Error inesperado","Error");
            this.formControlRegistroForzado.ClearForm();
            this.setPaso(2);
            this.sound.playBeepError();
          }            
        }
      },
      (error:HttpErrorResponse) => { 
        this.msg.showError(error.statusText,"Error inesperado");
        this.sound.playBeepError();
      }
    );
  }

  setPaso(nuevoPaso: number) {
    this.valid = false;
    this.paso = nuevoPaso;
    switch (this.paso) {
      case 1:
        this.subtitulo = 'Seguridad';
        break;
      case 2:
        this.subtitulo = `Registración de Operaciones Forzadas - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`; 
        break;
    
      default:
        break;
    }
  }

  onCancel = () => {
    this.setPaso(this.paso-1);
    if(this.paso == 0) this.router.navigate(['/']);
  }

  onValidSeguridad($event: SeguridadEvent){    
      this.cliente = $event.cliente;
      this.cuenta = $event.cuenta;
      this.subcuenta = $event.subcuenta;
    //this.valid = $event.valid;
    this.valid = true;      
  }
}
