import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';

export type EmitEventTransaccion = {
  transaccion: IdsDescr;
  impector: IdDescr;
  remito: string;
  valid: boolean;
};

@Component({
  selector: 'app-transaccion',
  templateUrl: './transaccion.component.html',
  styleUrls: ['./transaccion.component.scss'],
})
export class TransaccionComponent implements OnInit {
  @Output() valid = new EventEmitter<EmitEventTransaccion>();
  @Input() vin: string | null = null;
  @Input() checkpoint: IdDescr | null = null;
  @Input() cliente: IdsDescr | null = null;
  @Input() cuenta: IdDescr | null = null;
  @Input() funcion: Funcion | null = null;
  @Input('aceptar') btnAceptar!: MatButton;

  form = new FormGroup({
    transaccion: new FormControl(null, Validators.required),
    impector: new FormControl(null),
    remito: new FormControl(null),
  });

  transacciones: IdDescr[] = [];
  impectores: IdDescr[] = [];

  constructor(
    private back: BackendService,
    private msg: NotificationService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    let funcionId = this.funcion?.id;
    this.back
      .consultaTransaccion(
        this.vin || '',
        this.checkpoint?.id || 0,
        this.cliente?.id || '',
        this.cuenta?.id || 0,
        funcionId || 'ErrorFuncion',
        this.funcion?.sistema.id || 'ErrorSistema'
      )
      .subscribe(
        (data) => {
          if (data && data.ok) {
            this.transacciones = data.transaccion;
            this.impectores = data.inspectorAveria;
            this.form.controls.remito.setValue(data.nroRemito);

            this.setFocus('transaccion');

            if (this.transacciones.length == 1) {
              this.form.controls.transaccion.setValue(this.transacciones[0]);
              this.setFocus('impector');
            }
          } else {
            this.msg.showError('Error al consultar Transaccion', 'Error');
          }
        },
        (error) => {
          this.msg.showError('Error inesperado', 'Error');
        }
      );

    this.form.statusChanges.subscribe((valid) => {
      let ret = <string>valid == 'VALID';
      this.valid.emit({
        impector: this.form.controls.impector.value,
        remito: this.form.controls.remito.value,
        transaccion: this.form.controls.transaccion.value,
        valid: ret,
      });
    });
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }

  nextDanio(event: any) {
    event.preventDefault();
  }

  onSelectionChangeImpector(event: any) {
    if (this.form.controls.transaccion.valid) {
      this.setFocus('impector');
    }
  }

  onSelectionChangeRemito(event: any) {
    if (this.form.controls.impector.valid) {
      this.setFocus('remito');
    }
  }

  remitoKeyEnter(event: any) {
    if (this.form.controls.remito.valid) {
      this.btnAceptar.focus();
    }
  }
}
