import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError, Observable } from 'rxjs';
import { IdDescr, IdsDescr } from "src/app/model/iddescr";
import { Usuario } from "src/app/model/usuario";
import { environment } from "src/environments/environment";
import { BackendService } from "../backend.service";

type response = {
  ok:boolean;
  err:{
    error:string;
    message:string;
  }
};

@Injectable({
    providedIn: 'root'
})
export class BackendServiceRecepcion {
  // Se definen las URLs de los servicios
  public usrServer: String = environment.urlBackend;
  public urlSeguridad: String = this.usrServer+"seguridad/";
  public urlToken: String = this.usrServer+"token/";
  public urlMobile: String = this.usrServer+"mobile/";

  constructor(private httpClient: HttpClient) { }

  // TODO: Listo para prueba de integración
  //a investigar si da de alta el vin o valida el modelo de recepcion en el componente mobile-recepcion
  public altaVinRecepcion(vin: string, cliente: IdsDescr, cuenta: IdDescr, subcuenta: IdDescr, 
    sistema: string, ordenDeFabricacion: string, motor: string, destino: string, modelo: string): Observable<response> {
    const token = localStorage.getItem('token'); // Obtiene el token del localStorage
    const user = localStorage.getItem('usuario'); // Obtiene el usuario del localStorage
    const url = `${this.urlMobile}altaVinRecepcion`; // Define la URL del servicio
    
    // En caso de que el usuario no este logueado, se lanza un error
    if (!token || !user) return throwError({statusText: 'Usuario no logueado'});

    const parsedUser = JSON.parse(user) as Usuario; // Parsea el usuario
    const body = {
      pais: parsedUser.pais.id, // Obtiene el id del pais del usuario
      vin, // Obtiene el vin
      cliente, // Obtiene el cliente
      cuenta, // Obtiene la cuenta
      subcuenta, // Obtiene la subcuenta
      modelo, // Obtiene el modelo
      ordenDeFabricacion, // Obtiene la orden de fabricacion
      motor, // Obtiene el motor
      destino // Obtiene el destino
    }

    return this.httpClient.post<response>(
      url, 
      body,
      { headers: { authorization: `Bearer ${token}` }
    }); // Hace la peticion al servicio
  }

  // TODO: Listo para prueba de integración
  //valida el modelo de la recepcion en los componentes form-recepcion y control de danios/form-recepcion
  public validaModeloRecepcion(vin: string, cliente: any, cuenta: number, sistema: string, modelo: string): Observable<response> {
    const token = localStorage.getItem('token');    
    const user = localStorage.getItem('usuario');
    const url = `${this.urlMobile}validaModeloRecepcion`;
    
    if (!token || !user) return throwError({ statusText: 'Usuario no logeado' })
    const parsedUser = JSON.parse(user) as Usuario;
    const body = {
      pais: parsedUser.pais.id,            
      vin:vin,
      cliente,
      cuenta,  
      token:token,
      sistema,
      modelo
    };
    
    return this.httpClient.post<response>(
      url,
      body,
      { headers: { authorization: `Bearer ${token}` } }
    );
  }

  // TODO: Listo para prueba de integración
  //valida la recepcion del vin en form-recepcion y en control-de-danios/form-recepcion (verificar en este ultimo
  //que no se esta enviando la subcuenta) 
  public validaVinRecepcion(vin: string, cliente: string, cuenta: number, ordenDeFabricacion: string, sistema: string, funcion: string, subcuenta: number): Observable<response> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile+'validaVinRecepcion';

    if (!token || !user) return throwError({ statusText: 'Usuario no logeado' })

    const parsedUser = JSON.parse(user) as Usuario;

    const body = {
      pais: parsedUser.pais.id,            
      vin:vin,
      cliente,
      cuenta,
      subcuenta,
      ordenDeFabricacion,    
      token:token,
      sistema,
      funcion
    };
    
    return this.httpClient.post<response>(
      url,
      body,
      { headers: { authorization: `Bearer ${token}` } }
    );
  }

  // TODO: Listo para prueba de integración
  /* método que no es utilizado en ninguna parte */
  public validaRecepcion(vin: string, cliente: string, cuenta: number, ordenDeFabricacion: string, sistema: string, funcion: string, motor: string, destino: string, modelo: string): Observable<response> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile+'validaRecepcion';

    if (!token || !user) return throwError({ statusText: 'Usuario no logeado' })

    const parsedUser = JSON.parse(user) as Usuario
    const body = {
      pais: parsedUser.pais.id,            
      vin:vin,
      cliente,
      cuenta,
      ordenDeFabricacion,    
      token:token,       
      funcion:funcion,
      sistema,
      motor,
      destino,
      modelo
    };
    
    return this.httpClient.post<response>(
      url,
      body,
      { headers: { authorization: `Bearer ${token}` } }
    );
  }
  
}