import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError, Observable, observable } from 'rxjs';
import { Funcion } from "../../model/funcion";
import { IdsDescr } from "../../model/iddescr";
import { Usuario } from "../../model/usuario";
import { environment } from "../../../environments/environment";
import { BackendService } from "../backend.service";

type responsePU = {
  ok: boolean;
  esProcesoUnificadoEcom: boolean
  err: {
    error: string;
    message: string;
  },
};
type responseCalle = {
  ok: boolean;
  calle: any
  err: {
    error: string;
    message: string;
  },
};
type response = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  },
};
type responseMarca = {
  ok: boolean;
  marca: any;
  err: {
    error: string;
    message: string;
  },
};
type responsePUMessage = {
  ok: boolean;
  esProcesoUnificadoEcom: boolean,
  message: string,
  err: {
    error: string;
    message: string;
  },
};

@Injectable({
  providedIn: 'root'
})

export class RegistrarProcesoUnificadoService {
  private usrServer: String = environment.urlBackend;
  private urlSeguridad: String = this.usrServer + "seguridad/";
  private urlToken: String = this.usrServer + "token/";
  private urlDanios: String = this.usrServer + "danios/";
  private urlMobile: String = this.usrServer + "mobile/";
  constructor(
    private httpClient: HttpClient
  ) { }
  
  public esProcesoUnificado(payload: any): Observable<responsePU> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    let url = `${this.urlDanios}esProcesoUnificadoEcom`;
    if (tempUser) {
      let body = {
        subcuenta: parseInt(payload.subcuenta.id),
        cuenta: parseInt(payload.cuenta.id)
      };
      return this.httpClient.post<responsePU>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no logueado' });
  }
  
  public findRecepcion(payload: any): Observable<responseCalle> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    let url = `${this.urlDanios}getCalleBy`;
    if (tempUser) {
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse(tempUser);
      // genera el body que se enviara en la peticion
      let body = {
        pais: usuario.pais.id,
        cliente: payload.cliente.id,
        subcuenta: parseInt(payload.subcuenta.id),
        cuenta: parseInt(payload.cuenta.id),
      };
      return this.httpClient.post<responseCalle>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no logueado' });
  }
  
  public validaOrdenDeFabricacion(payload: any): Observable<response> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    let url = `${this.urlMobile}ValidaOrdenDeFabricacion`;
    if (tempUser) {
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse(tempUser);
      // genera el body que se enviara en la peticion
      let body = {
        ordenDeFabricacion: payload.OrdenDeFabricacion
      };
      return this.httpClient.post<response>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no logueado' });
  }
  
  public validaModelo(payload: any, funcion: Funcion | null): Observable<responseMarca> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    let url = `${this.urlMobile}consultaModeloDaniosEcom`;
    if (tempUser) {
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse(tempUser);
      let sistema = funcion?.sistema.id || 'ErrorFuncionSistema';
      // genera el body que se enviara en la peticion
      let body = {
        pais: usuario.pais.id,
        vin: payload.vin,
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        funcion: funcion?.id,
        modelo: payload.modelo
      };
      return this.httpClient.post<responseMarca>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }
  
  public validaMotor(payload: any): Observable<response> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    let url = `${this.urlMobile}validaMotor`;
    if (tempUser) {
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse(tempUser);
      // genera el body que se enviara en la peticion
      let body = {
        motor: payload.motor
      };
      return this.httpClient.post<response>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }
  
  public validaVin(payload: any, funcion: Funcion | null): Observable<response> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    let url = `${this.urlMobile}consultaVinDaniosEcom`;
    if (tempUser) {
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse(tempUser);
      let sistema = funcion?.sistema.id || 'ErrorFuncionSistema';
      // genera el body que se enviara en la peticion
      let body = {
        pais: usuario.pais.id,
        vin: payload.vin,
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        subcuenta: payload.subcuenta.id,
        esProcesoUnificado: payload.esProcesoUnificado,
        ordenDeFabricacion: payload.ordenDeFabricacion,
        funcion: funcion?.id
      };
      return this.httpClient.post<response>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }
  
  public completarVin(payload: any, funcion: Funcion | null): Observable<response> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    const pais = localStorage.getItem('pais');
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    let url = `${this.urlMobile}vin/${payload.vin}?pais=${pais}&cliente=${payload.cliente.id}&cuenta=${payload.cuenta.id}`;
    if (tempUser) {
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse(tempUser);
      let sistema = funcion?.sistema.id || 'ErrorFuncionSistema';
      return this.httpClient.get<response>(url, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }
  //******************* */
  
  public validarOperacion(payload: any, funcion: Funcion | null): Observable<response> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    const pais = localStorage.getItem('pais');
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    let url = `${this.urlMobile}findOperacion?pais=${pais}&operacion=${payload.operacion}`;
    if (tempUser) {
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse(tempUser);
      let sistema = funcion?.sistema.id || 'ErrorFuncionSistema';
      return this.httpClient.get<response>(url, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public danioIngresoUnificado(payload: any, funcion: Funcion | null): Observable<responsePUMessage> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    let url = `${this.urlDanios}ingresoUnificado`;
    if (tempUser) {
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse(tempUser);
      let sistema = funcion?.sistema.id || 'ErrorFuncionSistema';
      // genera el body que se enviara en la peticion
      let body = {
        pais: usuario.pais,
        vin: payload.vin,
        cliente: payload.cliente,
        cuenta: payload.cuenta,
        subcuenta: payload.subcuenta,
        funcion: funcion?.id,
        destino: payload.destino,
        tipo: payload.tipo,
        danios: payload.tipoDeDanio,
        transaccion: null,
        calle: payload.puestoDeRecepcion,
        ordenFabricacion: payload.ordenFabricacion,
        motor: payload.motor,
        modelo: payload.modelo,
        marca: payload.marca.id
      };
      return this.httpClient.post<responsePUMessage>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }
  //ingreso sin ecom
  
  public danioIngresoUnificadoSinEcom(payload: any, funcion: Funcion | null): Observable<responsePUMessage> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    let url = `${this.urlMobile}ingresoUnificadoSinEcom`;
    if (tempUser) {
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse(tempUser);
      let sistema = funcion?.sistema.id || 'ErrorFuncionSistema';
      // genera el body que se enviara en la peticion
      let body = {
        pais: usuario.pais,
        vin: payload.vin,
        cliente: payload.cliente,
        cuenta: payload.cuenta,
        subcuenta: payload.subcuenta,
        funcion: funcion?.id,
        calle: payload.puestoDeRecepcion,
        operacion: payload.operacion,
        destino: payload.destino,
        ordenFabricacion: payload.ordenFabricacion,
        motor: payload.motor,
        modelo: payload.modelo,
        marca: payload.marca.id
        /*
        tipo: payload.tipo,
        danios: payload.tipoDeDanio,
        transaccion: null,
        
        */
      };
      return this.httpClient.post<responsePUMessage>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public validaDestino(payload: any, funcion: Funcion | null): Observable<response> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    let url = `${this.urlMobile}consultaDestinoDaniosEcom`;
    if (tempUser) {
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse(tempUser);
      let sistema = funcion?.sistema.id || 'ErrorFuncionSistema';
      // genera el body que se enviara en la peticion
      let body = {
        pais: usuario.pais.id,
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        funcion: funcion?.id,
        destino: payload.destino
      };
      return this.httpClient.post<response>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }


  public validaOperacionesPendientesExistentes(payload: any, funcion: Funcion | null): Observable<response> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    
    if (tempUser) {
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse(tempUser);
      let sistema = funcion?.sistema.id || 'ErrorFuncionSistema';
      let url = `${this.urlMobile}existenOperacionesPendientesAutomaticas?pais=${usuario.pais.id}&cliente=${payload.cliente.id}&cuenta=${payload.cuenta.id}&subcuenta=${payload.subcuenta.id}&vin=${payload.vin}&sinEcom=${payload.sinEcom}&funcion=${funcion?.id}`;

      return this.httpClient.get<response>(url, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public registrarOperacionesPendientes(payload: any, funcion: Funcion | null): Observable<response> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    let url = `${this.urlMobile}registrarOperacionesPendientesAutomaticas`;
    if (tempUser) {
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse(tempUser);
      let sistema = funcion?.sistema.id || 'ErrorFuncionSistema';
      // genera el body que se enviara en la peticion
      let body = {
        pais: usuario.pais,
        vin: {
          id: payload.vin,
        },
        cliente: payload.cliente,
        cuenta: payload.cuenta,
        subcuenta: payload.subcuenta,
        sinEcom: payload.sinEcom,
        funcion: funcion?.id,
      };
      
      return this.httpClient.post<response>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

}