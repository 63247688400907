<form [formGroup]="form" autocomplete="off">
  <!-- VIN -->
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>VIN</mat-label>
    <input
      matInput
      formControlName="vin"
      maxlength="256"
      placeholder="Ingrese un VIN..."
      (keydown.enter)="nextDanio($event)"
      (keydown.tab)="nextDanio($event)"
    />
    <button
      mat-icon-button
      matSuffix
      (click)="onClickSearch()"
      [attr.aria-label]="'Buscar VIN'"
    >
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

    <!-- lista operaciones Pendientes -->
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Operaciones pendientes</mat-label>
      <mat-select
        formControlName="codOpPendiente"
        (selectionChange)="focusProveedor()"
        #codOpPendeinte
        (closed)="focusProveedor()"
      >
        <mat-option *ngFor="let codOpPendiente of listaOpPendientes" [value]="codOpPendiente">
          {{ codOpPendiente.operacion.id}} - {{codOpPendiente.operacion.descripcion}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  <!-- Proveedor -->
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Proveedor</mat-label>
    <mat-select
      formControlName="proveedor"
      (selectionChange)="focusResultado('proveedor')"
      #proveedor
      (closed)="focusResultado('proveedor')"
    >
      <mat-option *ngFor="let proveedor of proveedores" [value]="proveedor">
        {{ proveedor.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Lineas de operación -->
  <mat-list role="list" style="overflow-y: scroll; height: 290px; margin-bottom: 18px;">
    <div mat-subheader>Lineas de Operación del Proveedor</div>
    <mat-list-item
      *ngFor="let operaciones of lineasOperaciones; let i = index"
      role="listitem"
      >{{ operaciones.descripcion }}
    </mat-list-item>
    <mat-list-item *ngIf="!lineasOperaciones.length" role="listitem">
      No se encontraron operaciones
    </mat-list-item>
  </mat-list>

    <!-- observação -->
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Observación</mat-label>
      <input
      matInput
        formControlName="observacion"
        maxlength="256"
        placeholder="Ingrese observación..."
        [(ngModel)]="observacion"
        (ngModelChange)="onObservacionChange($event)"
        (keydown.enter)="focusResultado('observacion')"
        (keydown.tab)="focusResultado('observacion')"
      />
    </mat-form-field>

    <!-- Combo resultado -->
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Resultado</mat-label>
      <mat-select
      matInput
        formControlName="resultado"
        (selectionChange)="focusResultado('resultado')"
        #resultado
      >
        <mat-option *ngFor="let resultado of comboResultados" [value]="resultado">
          {{ resultado.id }}
        </mat-option>
      </mat-select>
    </mat-form-field> 
  
</form>
