<form [formGroup]="form" autocomplete="off">
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>VIN</mat-label>
        <input matInput formControlName="vin" #vin
            maxlength="17" placeholder="Ingrese un VIN..."
            (keydown.enter)="nextVin($event)"
            (keydown.tab)="nextVin($event)"
        />
        <div matSuffix style="display:flex">
            <div mat-icon-button (click)="onClickScann()">
                <img src="assets/icons/qr_code_scanner.svg">
            </div>
            <div mat-icon-button (click)="onClickSearch()">
                <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
            </div>            
        </div>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Modelo</mat-label>
        <input matInput formControlName="modelo"  name="modelo"
            placeholder="Ingrese un Modelo..."
            (keydown.tab)="nextModelo($event)"
            (keydown.enter)="nextModelo($event)"
        >
         <div mat-icon-button matSuffix (click)="onClickSearchModelo()" [attr.aria-label]="'Buscar Modelo'">
            <img src="assets/icons/search.svg">
        </div>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Destino</mat-label>
        <input matInput formControlName="destino" name="destino"
            placeholder="Ingrese un Destino..."
            (keydown.enter)="nextDestino($event)"
            (keydown.tab)="nextDestino($event)"
        >
        <div mat-icon-button matSuffix (click)="onClickSearchDestino()" [attr.aria-label]="'Buscar Destino'">
            <img src="assets/icons/search.svg" >
        </div>      
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Tipo</mat-label>
        <input matInput formControlName="tipo" name="tipo"
        placeholder="Ingrese un Tipo..."
        (keydown.enter)="nextTipo($event)"
        (keydown.tab)="nextTipo($event)">
    </mat-form-field>
</form>
<app-scann
    *ngIf="showScann"
    (cancel)="onScannCancel()"
    (scan)="onScanScan($event)"
></app-scann>