import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-scann',
  templateUrl: './scann.component.html',
  styleUrls: ['./scann.component.scss']
})
export class ScannComponent implements OnInit {

  @Output() cancel = new EventEmitter();
  @Output() scan = new EventEmitter<string>();

  torchEnabled = false;
  currentDevice: MediaDeviceInfo | undefined = undefined;
  availableDevices: MediaDeviceInfo[] = [];

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_39
  ];

  hasDevices: boolean = false;
  hasPermission: boolean = false;
  qrResultString: string = '';

  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;
  deviceIndex = 0;
  constructor() { }

  ngOnInit(): void {
  }

  onCancelarClick(){
    this.cancel.emit();    
  }

  onNextClick(){
    if(this.availableDevices && this.availableDevices.length > 0){
      this.deviceIndex++;
      if(this.deviceIndex > (this.availableDevices.length-1)){
        this.deviceIndex=0;
      }
      this.currentDevice = this.availableDevices[this.deviceIndex];
    }    
  }

  clearResult(): void {
    this.qrResultString = '';
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.scan.emit(this.qrResultString);    
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || undefined;
  }

  openFormatsDialog() {
    
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  openInfoDialog() {
    
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }
}
