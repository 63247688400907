import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { Funcion } from 'src/app/model/funcion';
import { Usuario } from 'src/app/model/usuario';
import { environment } from 'src/environments/environment';
import { BackendService } from '../backend.service';

type response = {
  ok:boolean;
  err:{
    error:string;
    message:string;
  }
};

@Injectable({
  providedIn: 'root'
})
export class ControlGuardiaServices {
  
  private usrServer: String = environment.urlBackend;
  private urlMobile:String = `${this.usrServer}mobile/`;

  constructor(private httpClient: HttpClient) { }
//valida el control de guardia en componente control-de-guardia
  public validaControlGuardia(funcion: Funcion|null, cliente: string, cuenta: number, vin: string): Observable<response> {

    const httpOptions = BackendService.getJsonOptions();
    
    let tempUser = localStorage.getItem('usuario');
    
    let url = this.urlMobile+'validaControlGuardia';

    if(tempUser) {
      let usuario = <Usuario>JSON.parse( tempUser );
      
      let body = {
        pais: usuario.pais.id,
        vin,
        cliente,
        cuenta,
        funcion: funcion?.id
      };

      return this.httpClient.post<response>(url,body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }
//realiza el control de guardia en el componente mobile-control-guardia
  public realizaControlGuardia(payload: any, funcion: Funcion | null): Observable<response> {    
   const httpOptions = BackendService.getJsonOptions();

    let tempUser = localStorage.getItem('usuario');

    let url = `${this.urlMobile}realizaControlGuardia`;
    
    if(tempUser) {
      let usuario = <Usuario>JSON.parse( tempUser );
      let body = {
        pais: usuario.pais,
        cliente: payload.cliente,
        cuenta: payload.cuenta,
        vin: payload.vin,
        funcion: funcion?.id
      };
      
      return this.httpClient.post<response>(url, body, httpOptions);
    }
    return throwError({statusText:'Usuario no logueado'});
  }

}