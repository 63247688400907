<button mat-button (click)="onClick()">
  Iniciar
</button>
<button mat-button (click)="onClickQuagga()">
  Iniciar Quagga
</button>
<app-scann *ngIf="showScann"
  (cancel)="onCancel()"
  (scan)="onScann($event)"
></app-scann>
<app-quagga-js #controlQuagga (cancel)='onCancelQuagga()' (scan)='onScanQuagga($event)'></app-quagga-js>
<p>{{scannValue}}</p>