import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { ControlDeGuardiaComponent, ControlGuardiaEventEmitterType } from 'src/app/forms/control-de-guardia/control-de-guardia.component';
import { SeguridadEvent } from 'src/app/forms/seguridad/seguridad.component';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendService } from 'src/app/services/backend.service';
import { BackendControlGuardiaService } from 'src/app/services/backendServices/controlGuardia.service';
import { ControlGuardiaServices } from 'src/app/services/control-guardia/control-guardia.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';

@Component({
  selector: 'app-mobile-control-guardia',
  templateUrl: './mobile-control-guardia.component.html',
  styleUrls: ['./mobile-control-guardia.component.scss']
})
export class MobileControlGuardiaComponent implements OnInit {
  
  @ViewChild(ControlDeGuardiaComponent) fromControlGuardia !: ControlDeGuardiaComponent

  subtitulo:string = 'Seguridad';
  funcion:Funcion|null = null;
  sistema: string = '59';
  paso:number = 1;
  valid = false;
  // Seguridad
  cliente:IdsDescr|null = null;
  cuenta:IdDescr|null = null;
  subcuenta:IdDescr|null = null;
  // Control de Guardia
  vin:string|null = null;

  constructor(
    private router: Router,
    private msg:NotificationService,
    private back: ControlGuardiaServices,
    private sound: SoundService
  ) {
    let nav = this.router.getCurrentNavigation();
    this.funcion = <Funcion>nav?.extras?.state?.funcion;
    if(!this.funcion){
      this.router.navigate(['']);
    }
    this.sistema = this.funcion.sistema.id;
   }

  ngOnInit(): void {
  }

  onValidSeguridad($event: SeguridadEvent){   
      this.cliente = $event.cliente;
      this.cuenta = $event.cuenta;
      this.subcuenta = $event.subcuenta;
    //this.valid = $event.valid;
    this.valid = true;  
  }

  onValidControlGuardia($event: ControlGuardiaEventEmitterType){
    if($event.valid){
      this.vin = $event.vin;
    }
    this.valid = $event.valid;        
  }

  public onCancel = () => {
    this.setPaso(this.paso-1);
    if(this.paso == 0) this.router.navigate(['/']);
  }

  private setPaso(paso:number){
    this.valid = false;
    this.paso = paso;    
    switch (this.paso) {
      case 1:
        this.subtitulo = 'Seguridad';
        break;
      case 2:        
        this.subtitulo = `Control de Guardia - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`; 
        break;
      default:
        //this.subtitulo = 'Error'; o 0, que salio
        break;
    }
    
  }

  onClick(){    
    if(!this.valid) return;
    if(this.paso < 2){
      this.setPaso(this.paso+1);
    } else {
      this.avanzarConLaConfirmacion();
    }
  }

  avanzarConLaConfirmacion() {
    let payload = {
      vin: this.vin,
      cliente: this.cliente,
      cuenta: this.cuenta
    }
    this.back.realizaControlGuardia(payload, this.funcion).subscribe( data => {
      if(data && data.ok){
        this.msg.showSuccess("Control guardia ","Control guardia exitoso");
        this.sound.playBeepOk();
        this.fromControlGuardia.ClearForm();
        return;
      }
      if(data && !data.ok && data.err){
        this.msg.showError(data.err.message,data.err.error);
      }else{
        this.msg.showError("Error inesperado","Error");
      }
      this.sound.playBeepError();
    }, (error:HttpErrorResponse) => {
      this.msg.showError(error.message,"Error");
      this.sound.playBeepError();
    });
  }
}
