import { Component, OnInit, ViewChild } from '@angular/core';
import { QuaggaJSComponent } from '../quagga-js/quagga-js.component';

@Component({
  selector: 'app-scannview',
  templateUrl: './scannview.component.html',
  styleUrls: ['./scannview.component.scss']
})
export class ScannviewComponent implements OnInit {
  @ViewChild('controlQuagga') controlQuagga!: QuaggaJSComponent;

  showScann = false;
  showQuagga = false;
  scannValue = "";

  constructor() { }

  ngOnInit(): void {
  }

  onClick(){
    this.showScann = true;
  }

  onCancelQuagga(){
    this.showQuagga = false;
  }

  onScanQuagga($event){
    this.scannValue = $event;
  }

  onClickQuagga(){
    this.showQuagga = true;
    setTimeout(() => {
      this.controlQuagga.Iniciar();
    }, 1000); 
  }

  onCancel(){
    this.showScann = false;
  }

  onScann(value:string){
    this.showScann = false;
    alert(value);
  }
}
