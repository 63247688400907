export const implementado:[string,string][] = [
    ["mobile_sipco","mobile_transferencia"],
    ["mobile_sipco","mobile_despacho_mercado_nacional"],
    ["mobile_sipco","mobile_ingresso_ecom"],
    ["mobile_sipco","mobile_carga_danios"],
    ["mobile_sipco","mobile_recepcion"],
    ["mobile_sipco","mobile_control_guardia"],
    ["mobile_sipco","mobile_carga_danio_reducida"],
    ["mobile_sipco","mobile_danios"],
    ["mobile_sipco","mobile_recomendacion_vin"],
    ["mobile_sipco","mobile_control_ingreso_ecom_unificado"],
    ["mobile_sipco","mobile_registrar_operacion_automatica"],
    ["mobile_sipco","mobile_control_despacho"],
    ["mobile_sipco","mobile_registrar_operacion_forzada"],
    ["mobile_sipco", "mobile_reingreso_devolucion"],
    ["mobile_sipco","mobile_registrar_ot"],
];

