import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError, observable, Observable } from 'rxjs';
import { Funcion } from "../../model/funcion";
import { IdsDescr } from "../../model/iddescr";
import { Usuario } from "../../model/usuario";
import { environment } from "../../../environments/environment";
import { BackendService } from '../backend.service';

type response = {
  ok:boolean;
  err:{
    error:string;
    message:string;
  },
};

type responseVV = {
  ok:boolean;
  virouMarketing:boolean;
  serieMAN:any;
  notaFiscalMAN:any;
  transportistas:[],
  err:{
    error:string;
    message:string;
  },
};

type responseVSProveedores = {
  ok:boolean;
  remitotransportista: {
    id:any;
    puntodeventa: any;
    fecharetorno: any;
  }
  err:{
    error:string;
    message:string;
  },
};

type responseRemDev = {
  ok:boolean;
  remitodevolucion: {
    id:any;
    puntodeventa: any;
  }
  err:{
    error:string;
    message:string;
  },
};

@Injectable({
  providedIn: 'root'
})
export class ReingresoDevolucionService {
  
  private usrServer: String = environment.urlBackend;
  
  private urlMobile: String = this.usrServer+"mobile/";

  constructor(private httpClient: HttpClient) { }

  public insertarOperacionPendiente(payload:any): Observable<response>{
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();    
    
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    
    let url = `${this.urlMobile}insertarOperacionPendiente`;
    
    if(tempUser){
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse( tempUser );
      
      // genera el body que se enviara en la peticion
      let body = {
        pais: {id: usuario.pais.id},
        cliente: {id: payload.cliente.id},
        cuenta: {id: payload.cuenta.id},
        operacion: {id: parseInt( payload.operacion)},
        vin:{id: payload.vin}
      };

      return this.httpClient.post<response>(url, body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }

  public validaVin(payload: any): Observable<responseVV>{
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();    
    
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    
    let url = `${this.urlMobile}validaVinReingreso`;

    if(tempUser){
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse( tempUser );
      
      // genera el body que se enviara en la peticion
      let body = {
        pais: usuario.pais.id,
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        subcuenta: payload.subcuenta.id,
        vin: payload.vin,
      };

      return this.httpClient.post<responseVV>(url, body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }


  public consultaChoferes(payload:any): Observable<response>{
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();    
    
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    
    let url = this.urlMobile+'consultarChoferes';

    if(tempUser){
      // genera el body que se enviara en la peticion
      let body = {
        transportista: payload.transportista.id
      };

      return this.httpClient.post<response>(url,body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }

  public validaSerieProveedor(payload: any): Observable<responseVSProveedores>{
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();    
    
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    
    let url = `${this.urlMobile}validaSerieProveedor`;
  
    if(tempUser){
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse( tempUser );
      
      // genera el body que se enviara en la peticion
      let body = {
        serieproveedor: parseInt(payload.serieProveedor),
        vin: {
          estadovehiculo: {
            id: payload.estadoVehiculo.id
          }
        }
      };
      
      return this.httpClient.post<responseVSProveedores>(url, body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }

  public validaNotaFiscalProveedor(payload: any): Observable<responseVSProveedores> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    
    let url = `${this.urlMobile}validaNotaFiscalProveedor`;
  
    if(tempUser){
      // genera el body que se enviara en la peticion
      let body = {
        serieproveedor: parseInt(payload.serieProveedor),
        notafiscalproveedor: parseInt(payload.notaFiscalProveedor),
        vin: {
          estadovehiculo: {
            id: payload.estadoVehiculo.id
          }
        },
        remitoTransportista:{
          id: payload.remitoTransportista.id,
          puntodeventa: payload.remitoTransportista.puntoDeVenta,
          fecharetorno: payload.remitoTransportista.fechaRetorno
        }
      };

      return this.httpClient.post<responseVSProveedores>(url, body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }

  public validaSerieDevolucion(payload: any): Observable<responseRemDev> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();    
    
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');

    let url = `${this.urlMobile}validaSerieDevolucion`;
  
    if(tempUser){
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse( tempUser );
      
      // genera el body que se enviara en la peticion
      let body = {
        seriedevolucion: payload.seriedevolucion,
      };
     
      return this.httpClient.post<responseRemDev>(url, body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }

  public realizarDevolucionIngreso(payload: any): Observable<response> {
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();
    
    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    
    let url = `${this.urlMobile}realizarDevolucion`;

    if(tempUser){
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse( tempUser );
      
      // genera el body que se enviara en la peticion
      let body = {
        pais: usuario.pais,
        cliente: payload.cliente,
        cuenta: payload.cuenta,
        subcuenta: payload.subcuenta,
        vin: {
          id: payload.vin
        },
        transportista: parseInt(payload.transportista.id),
        chofer: parseInt(payload.chofer.id),
        marketing: payload.marketing,
        fechanotafiscal: payload.fechanotafiscal,
        remitoTransportista: payload.remitotransportista,
        remitoDevolucion: payload.remitodevolucion
      };

      return this.httpClient.post<response>(url, body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }
}
