import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BackendService } from '../services/backend.service';

@Injectable({
  providedIn: 'root'
})
export class AutherizationGuard implements CanActivate {
  
  constructor(private router: Router, private back: BackendService) {}

  canActivate(): boolean {
    if (this.back.getTokenn() !== null) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
  
}
