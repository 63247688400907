import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Funcion } from '../model/funcion';
import { Usuario } from '../model/usuario';
import { implementado } from '../model/implement'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit,OnChanges {
  @Output() onMenu: EventEmitter<Funcion> = new EventEmitter();
  @Input()
  usuario: Usuario|null = null;
  rootMenu = new Array<Funcion>();
  
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {    
    this.rootMenu = new Array<Funcion>();

    if(this.usuario){
      this.usuario.menu.funciones.forEach(
        (value,index,array) =>{
          let ret = this.isValidRootMenu(value);
          if(ret){
            this.rootMenu.push(ret);
          }
        }
      );
    }    
  }

  isValidRootMenu(menuRoot:Funcion): Funcion | null {
    let ret:Funcion[] =[];

    if(!menuRoot.activo){
      return null;
    }

    menuRoot.children.forEach(
      (value,index,array) =>{        
        if(implementado.some(
          x => x[0] == menuRoot.id 
          && x[1] == value.id 
          && value.activo)){
          // Esta impplementado
          ret.push(value);
        }
      }
    )

    if(ret.length > 0) {
      let ret2 = menuRoot;
      ret2.children = ret;
      return ret2;
    }

    return null;
  }

  ngOnInit(): void {}

  onClickMenu(funcion:Funcion){
    this.onMenu.emit(funcion);
  }
}
