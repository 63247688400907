import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { NavigationExtras, Router } from '@angular/router';
import { EventOnValidRecomandacionVin } from 'src/app/forms/recomandacion-vin/recomandacion-vin.component';
import { SeguridadEvent } from 'src/app/forms/seguridad/seguridad.component';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { IVin } from 'src/app/model/ivin';
import { BackendRecomandacionVinService } from 'src/app/services/backendServices/recomendacionVin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';

@Component({
  selector: 'app-mobile-recomendacion-vin',
  templateUrl: './mobile-recomendacion-vin.component.html',
  styleUrls: ['./mobile-recomendacion-vin.component.scss']
})
export class MobileRecomendacionVinComponent implements OnInit {

  
  titulo:string = "Titulo";
  funcion:Funcion|null = null;  
  sistema: string = '59';
  subtitulo:string = 'Seguridad';
  valid = false;
  paso:number = 1;
  show:boolean=false;
  // Seguridad
  cliente:IdsDescr|null = null;
  cuenta:IdDescr|null = null;
  subcuenta:IdDescr|null = null;
  // recomandacion form
  vin:IVin|null = null;
  confirmarVin:string|null = null;
  observaciones:string|null = null;
  calle: string|null = null;
  columna: string|null = null;
  grupo: IdDescr|null = null;

  constructor(
    private router: Router,
    private back:BackendRecomandacionVinService,
    private msg: NotificationService,
    private sound: SoundService
  ) {
    let nav = this.router.getCurrentNavigation();
    this.funcion = <Funcion>nav?.extras?.state?.funcion;
    if(!this.funcion){
      this.router.navigate(['']);
    }
    this.sistema = this.funcion.sistema.id;
    this.titulo = this.funcion.descripcion;
   }

  ngOnInit(): void {
  }

  onValidSeguridad($event: SeguridadEvent){    
      this.cliente = $event.cliente;
      this.cuenta = $event.cuenta;
      this.subcuenta = $event.subcuenta;
    
    //this.valid = $event.valid; 
    this.valid = true;         
  }

  onValidRecomandacionVin($valid:EventOnValidRecomandacionVin){
    this.valid = $valid.valid;
    this.vin = $valid.vin;
    this.calle = $valid.calle;
    this.columna = $valid.columna;
    this.grupo = $valid.grupo;
  }

  onChangeVin($event:string){
    this.confirmarVin = $event;
  }

  onChangeObserv($event:string){
    this.observaciones = $event;
  }

  onClick(){
    if(!this.valid) return;

    if(this.paso < 3){
      this.setPaso(this.paso+1);
      if(this.paso == 3){
        this.show=true;
        if(!this.cliente || !this.cuenta || !this.subcuenta ||
          !this.grupo || !this.vin){
          this.msg.showError('Error de Datos');
          return;
        }
        this.back.tomarVin(
          this.cliente, this.cuenta,this.subcuenta,
          this.vin, this.calle||'ErrorCalle', this.columna||'ErrorColumna','0',
          this.grupo
        ).subscribe(
          data => {
            if(data && data.ok){
              this.msg.showSuccess('Tomar Vin Ok');
              this.sound.playBeepOk();
              return;
            }
            this.setPaso(2);
            this.msg.contolError(data);
            this.sound.playBeepError(); 
          }, (error:HttpErrorResponse) => {
            this.msg.catchError(error);     
            this.sound.playBeepError();  
          }
        );        
      }
    }
  }

  onLiberar(){
    if(this.cliente && this.cuenta && this.subcuenta && this.grupo){
      this.back.liberarVin(
        this.cliente,this.cuenta,this.subcuenta,
        this.vin, this.calle||'NoCalle', this.columna||'NoColumna', '0', this.grupo
      ).subscribe(
        data => {
          if(data && data.ok){
            this.msg.showSuccess('Libarar Ok');
            this.sound.playBeepOk();
            this.router.navigate(['']);
            return;
          }
          this.msg.contolError(data);
        }, (error:HttpErrorResponse) => {
          this.msg.catchError(error);
          this.sound.playBeepError();     
        }
      );
    }else{
      this.msg.showError('Datos Faltantes');
    }//if(this.cliente){
  }

  onListaNegra(){
    if(this.cliente && this.cuenta && this.subcuenta && this.grupo){
      this.back.listaNegraVin(
        this.cliente,this.cuenta,this.subcuenta,
        this.vin, this.calle||'NoCalle', this.columna||'NoColumna', '0', this.grupo,
        this.observaciones||'ObsNULL'
      ).subscribe(
        data => {
          if(data && data.ok){
            this.msg.showSuccess('Lista Negra Ok');
            this.sound.playBeepOk();
            this.router.navigate(['']);
            return;
          }
          this.msg.contolError(data);
          this.sound.playBeepError();
        }, (error:HttpErrorResponse) => {
          this.msg.catchError(error);    
          this.sound.playBeepError();  
        }
      );
    }else{
      this.msg.showError('Datos Faltantes');
    }//if(this.cliente){
  }

  onProcesar(){
    //asi paso datos a la nueva ventana - asi toma el objeto state del root y lo pasa
    let nav: NavigationExtras = { state: {vin: this.vin}};
    this.router.navigate(['mobile_transferencia'],nav)
  }

  private setPaso(paso:number){
    this.valid = false;
    this.paso = paso;    
    switch (this.paso) {
      case 1:
        this.subtitulo = 'Seguridad';
        break;

      case 2:        
        this.subtitulo = `Recomendacion VIN - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`; 

        break;
      
      default:
        //this.subtitulo = 'Error'; o 0, que salio
        break;
    }    
  }

  public onCancel = () => {
    this.setPaso(this.paso-1);
    if(this.paso == 0) this.router.navigate(['/']);
  }
}
