<form [formGroup]="form">
    <mat-form-field appearance="fill" style="width: 100%;">
      <mat-label>VIN</mat-label>
      <input matInput 
      formControlName="vin" 
      maxlength="256" 
      placeholder="Ingrese un VIN..."
      (keydown.enter)="nextFocus($event, 1)"
      (keydown.tab)="nextFocus($event, 1)">
      <button mat-icon-button matSuffix (click)="onClickSearch()">
          <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>Transportista</mat-label>
        <mat-select formControlName="transportista"
        (selectionChange)="onSelectionChange(1)"
         #transportista
         (closed)="onSelectionChange(1)"
         >
            <mat-option *ngFor="let transportista of transportistas"
            [value]="transportista"
            >
                {{transportista.descripcion}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>Chofer</mat-label>
        <mat-select formControlName="chofer"
        (selectionChange)="onSelectionChange(2)"
         #chofer
         (closed)="onSelectionChange(2)"
         >
            <mat-option *ngFor="let chofer of choferes"
            [value]="chofer">
                {{chofer.apellido}} {{chofer.nombre}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="wrapper-inputs">
        <mat-form-field appearance="fill" style="width: 48%;">
            <mat-label>Serie MAN</mat-label>
            <input matInput 
            readonly
            formControlName="serieMan" 
            maxlength="256"
            >
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 48%">
            <mat-label>Nota Fiscal MAN</mat-label>
            <input matInput 
            readonly
            [disabled]="!valid"
            formControlName="notaFiscalMan" 
            maxlength="256"
            >
          </mat-form-field>
    </div>
      <mat-checkbox formControlName="virouMarketing"
      [disabled]="true"
      class="example-full-width"
      style="margin: 20px;">
      Virou Marketing
     </mat-checkbox>
    <div class="wrapper-inputs">
        <mat-form-field appearance="fill" style="width: 48%;">
            <mat-label>Serie Prov.</mat-label>
            <input matInput 
            formControlName="serieProveedor" 
            maxlength="256"
            (keydown.enter)="nextFocus($event, 3)"
            (keydown.tab)="nextFocus($event, 3)">
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 48%;">
            <mat-label>Nota Fiscal Proveedor</mat-label>
            <input matInput 
            formControlName="notaFiscalProveedor" 
            maxlength="256"
            (keydown.enter)="nextFocus($event, 6)"
            (keydown.tab)="nextFocus($event, 6)">
        </mat-form-field>
    </div>
    <mat-form-field appearance="fill" class="example-full-width" >
        <mat-label>Fecha Nota Fiscal</mat-label>
        <input matInput 
        formControlName="fechaNotaFiscal" 
        [matDatepicker]="picker" 
        (keydown.enter)="nextFocus($event, 7)"
        (keydown.tab)="nextFocus($event, 7)"
        >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker></mat-datepicker>
    </mat-form-field>
  </form>
  