import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { Funcion } from '../../model/funcion';
import { IdsDescr } from '../../model/iddescr';
import { Usuario } from '../../model/usuario';
import { environment } from '../../../environments/environment';
import { BackendService } from '../backend.service';

type responseVin = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
  stockPlaya: any;
};

type responseOperacion = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
  operacionData: any;
  operacionesPendientes: boolean;
  permiteRegistrarOperacion: boolean;
  proveedores: IdsDescr[];
  proveedor: any;
  lineasOperacion: any[];
  inhabilitarProveedor: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class BackendRegOpAutoService {
  private usrServer: String = environment.urlBackend;
  private urlMobile: String = this.usrServer + 'mobile/';

  constructor(private httpClient: HttpClient) {}

  // TODO: Listo para prueba de integración
  //consulta de vin en componente reg-op-auto
  public findVin(vin: string, funcion: Funcion): Observable<responseVin> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'stockPlaya/findVin';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;
    const body = {
      pais: parsedUser.pais.id,
      vin: vin,
      funcion: funcion.id,
    };

    return this.httpClient.post<responseVin>(url, body, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  public findResults(operacion: string): Observable<any> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'resultadoOperacion/findResults';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;
    const body = {
      pais: parsedUser.pais.id,
      operacion: operacion,
    };

    return this.httpClient.post<any>(url, body, {
      headers: { authorization: `Bearer ${token}` },
    });
  }  

  // TODO: Listo para prueba de integración
  /**no se utiliza en componentes  */
  public validaOperacion(
    cliente: string,
    cuenta: number,
    subcuenta: number,
    vin: string,
    operacion: number
  ): Observable<responseOperacion> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'validaOperacion';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;
    const body = {
      pais: parsedUser.pais.id,
      cliente,
      cuenta,
      subcuenta,
      operacion,
      vin,
    };

    return this.httpClient.post<responseOperacion>(url, body, {
      headers: { authorization: `Bearer ${token}` },
    });
  }
}
