import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendServiceRecepcion } from 'src/app/services/api-backend/validaRecepcion.service';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';
import { RegistrarProcesoUnificadoService } from 'src/app/services/registrar-proceso-unificado/registrar-proceso-unificado.service';
import { HttpErrorResponse } from '@angular/common/http';

export type RecepcionEventType = {
  valid: boolean;
  orden: string;
  vin: string;
  modelo: string;
  motor: string;
  destino: string;
};

@Component({
  selector: 'app-form-recepcion',
  templateUrl: './form-recepcion.component.html',
  styleUrls: ['./form-recepcion.component.scss'],
})
export class FormRecepcionComponent implements OnInit, AfterViewInit {
  @Output() focusEvent = new EventEmitter();

  @Output() valid = new EventEmitter<RecepcionEventType>();
  @Input() funcion: Funcion | null = null;
  @Input() cliente: any = null;
  @Input() cuenta: IdDescr | null = null;
  @Input() subcuenta: IdDescr | null = null;

  form: FormGroup = new FormGroup({
    orden: new FormControl(
      null,
      Validators.compose([
        Validators.pattern('[0-9A-Z]{8}'),
        Validators.required,
      ])
    ),
    vin: new FormControl(
      null,
      Validators.compose([
        Validators.pattern('[0-9A-Z]{17}'),
        Validators.required,
      ])
    ),
    modelo: new FormControl(
      null,
      Validators.compose([
        Validators.pattern('[0-9A-Z]{16}'),
        Validators.required,
      ])
    ),
    motor: new FormControl(
      null,
      Validators.compose([
        Validators.pattern('[0-9A-Z]{13,15}'),
        Validators.required,
      ])
    ),
    destino: new FormControl(
      null,
      Validators.compose([
        Validators.pattern('[0-9A-Z]{4}'),
        Validators.required,
      ])
    ),
  });
  valido: boolean = false;

  @ViewChild('orden') txtOrden!: ElementRef;
  @ViewChild('vin') txtVin!: ElementRef;
  @ViewChild('modelo') txtModelo!: ElementRef;
  @ViewChild('motor') txtMotor!: ElementRef;
  @ViewChild('destino') txtDestino!: ElementRef;

  constructor(
    private back: BackendServiceRecepcion,
    private msg: NotificationService,
    private sound: SoundService,
    private serviceMotor: RegistrarProcesoUnificadoService
  ) {}

  ngOnInit(): void {
    this.form.controls.modelo.disable();
    this.form.controls.motor.disable();
    this.form.controls.destino.disable();

    this.form.statusChanges.subscribe((valid) => {
      let ret = <string>valid == 'VALID';
      this.valid.emit({
        valid: ret && this.valido,
        orden: this.form.controls.orden.value,
        vin: this.form.controls.vin.value,
        modelo: this.form.controls.modelo.value,
        motor: <string>this.form.controls.motor.value,
        destino: this.form.controls.destino.value,
      });
    });
  }

  nextOrden(event: any) {
    event.preventDefault();
    if (this.form.controls.orden.valid) {
      this.txtVin.nativeElement.focus();
      this.sound.playBeepOk();
    } else {
      this.msg.showError('Formato campo incorrecto');
      this.txtOrden.nativeElement.select();
    }
  }

  nextVin(event: any) {
    event.preventDefault();

    if (!this.form.controls.vin.valid) {
      this.msg.showError('El campo debe tener 17 caracteres', 'Error');
      return this.txtVin.nativeElement.select();
    }

    this.onClickSearch();
  }

  nextModelo(event: any) {
    event.preventDefault();
    if (this.form.controls.modelo.valid) {
      this.txtMotor.nativeElement.focus();
      this.sound.playBeepOk();
      this.back
        .validaModeloRecepcion(
          this.form.controls.vin.value,
          this.cliente?.id,
          this.cuenta?.id || -1,
          this.funcion?.sistema.id || 'ErrorIdSistema',
          this.form.controls.modelo.value
        )
        .subscribe(
          (data) => {
            if (data && data.ok) {
              return;
            } else {
              if (data && data.err) {
                this.msg.showError(data.err.message, data.err.error);
                return;
              }
            }
            this.msg.showError('Error en validaModeloRecepcion', 'Error');
          },
          (error) => {
            this.msg.showError('Error en validaModeloRecepcion', 'Error');
          }
        );
    } else {
      this.msg.showError('Formato campo incorrecto');
      this.txtModelo.nativeElement.select();
    }
  }

  nextMotor(event: any) {
    event.preventDefault();
    this.validaMotor();
  }

  onClickMotor() {
    this.validaMotor();
  }

  validaMotor() {
    let payload = {
      motor: this.form.controls.motor.value,
    };
    this.serviceMotor.validaMotor(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          //this.form.controls.destino.enable();
          this.sound.playBeepOk();
          this.txtDestino.nativeElement.focus();
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  nextDestino(event: any) {
    event.preventDefault();
    if (this.form.controls.destino.valid && this.form.status == 'VALID') {
      this.focusEvent.emit();
    } else {
      this.msg.showError('Formato campo incorrecto');
      this.txtDestino.nativeElement.select();
    }
  }

  ngAfterViewInit() {
    //(new Promise(resolve => {this.txtOrden.nativeElement.focus();}));
    //this.txtOrden.nativeElement.focus();
    setTimeout(() => {
      this.setFocusFirst();
    }, 0);
  }

  async setFocusFirst() {
    this.txtOrden.nativeElement.focus(); /*
    this.form.controls.orden.markAsTouched();
    this.form.controls.orden.markAsUntouched();*/
  }

  onClickSearch() {
    // Desactiva los campos para que no se puedan modificar mientras se valida el vin
    this.form.controls.destino.disable();
    this.form.controls.modelo.disable();
    this.form.controls.motor.disable();

    this.valido = false;

    if (!this.form.controls.vin.valid)
      return this.msg.showError('Vin invalido');

      const vin: string = this.form.controls.vin.value.toUpperCase();
    const orden: string = this.form.controls.orden.value;

    this.back
      .validaVinRecepcion(
        vin,
        this.cliente?.id || 'ErrorCliente',
        this.cuenta?.id || -1,
        orden,
        this.funcion?.sistema.id || 'ErrorSistema',
        this.funcion?.id || 'ErrorFuncion',
        this.subcuenta?.id || -1
      )
      .subscribe(
        (ret) => {
          if (ret && ret.ok) {
            this.form.controls.modelo.enable();
            this.form.controls.motor.enable();
            this.form.controls.destino.enable();
            this.valido = true;
            this.txtModelo.nativeElement.focus();
            this.sound.playBeepOk();
          } else {
            this.msg.showError(ret.err.message, ret.err.error);
          }
        },
        (error) => {
          this.msg.catchError(error);
        }
      );
  }

  public ClearForm() {
    this.form.reset();
    this.setFocusFirst();
  }
}
