<form [formGroup]="form" autocomplete="off">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>VIN</mat-label>
    <input
      matInput
      formControlName="vin"
      maxlength="256"
      placeholder="Ingrese un VIN..."
      (keydown.enter)="nextDanio($event)"
      (keydown.tab)="nextDanio($event)"
    />
    <button
      mat-icon-button
      matSuffix
      (click)="onClickSearch()"
      [attr.aria-label]="'Buscar VIN'"
    >
      <img src="assets/icons/search.svg" style="margin-bottom: 15px" />
    </button>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>CheckPoints</mat-label>
    <mat-select
      formControlName="checkPoint"
      #checkPoint
      (selectionChange)="onSelectionChangeCheckPoint($event)"
      (closed)="onSelectionChangeCheckPoint($event)"
    >
      <mat-option *ngFor="let checkPoint of checkPoints" [value]="checkPoint">
        {{ checkPoint.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Imputaciones</mat-label>
    <mat-select
      formControlName="imputacion"
      #imputacion
      (selectionChange)="onSelectionChangeImputacion($event)"
      (closed)="onSelectionChangeImputacion($event)"
    >
      <mat-option *ngFor="let imputacion of imputaciones" [value]="imputacion">
        {{ imputacion.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Responsable</mat-label>
    <mat-select
      formControlName="responsable"
      (selectionChange)="onSelectionChangeResponsable($event)"
      (closed)="onSelectionChangeResponsable($event)"
    >
      <mat-option
        *ngFor="let responsable of responsables"
        [value]="responsable"
      >
        {{ responsable.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
