<form [formGroup]="form" autocomplete="off">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Orden de Fabricación</mat-label>
    <input matInput formControlName="orden" #orden (keydown.enter)="nextFocus($event, 1)"
      (keydown.tab)="nextFocus($event, 1)" type="text" maxlength="8" placeholder="Orden de Fabricación..."
      onfocus="this.select()">
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>VIN</mat-label>
    <input matInput formControlName="vin" #vin style="text-transform:uppercase" maxlength="17"
      placeholder="Ingrese un VIN..." onfocus="this.select()" (keydown.enter)="nextFocus($event, 2)"
      (keydown.tab)="nextFocus($event, 2)">
    <div mat-icon-button matSuffix (click)="onClickSearch()" [attr.aria-label]="'Buscar VIN'">
      <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
    </div>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Modelo</mat-label>
    <input matInput formControlName="modelo" #modelo (keydown.enter)="nextFocus($event, 3)"
      (keydown.tab)="nextFocus($event, 3)" maxlength="16" placeholder="Modelo..." onfocus="this.select()">
    <div mat-icon-button matSuffix (click)="onClickModelo()" [attr.aria-label]="'Buscar VIN'">
      <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
    </div>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Motor</mat-label>
    <input matInput formControlName="motor" #motor (keydown.enter)="nextFocus($event, 4)"
      (keydown.tab)="nextFocus($event, 4)" maxlength="15" placeholder="Motor..." onfocus="this.select()">
    <div mat-icon-button matSuffix (click)="onClickMotor()" [attr.aria-label]="'Buscar VIN'">
      <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
    </div>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Destino</mat-label>
    <input matInput formControlName="destino" #destino maxlength="4" placeholder="Destino..." onfocus="this.select()"
      (keydown.enter)="nextFocus($event, 5)" (keydown.tab)="nextFocus($event, 5)">
    <div mat-icon-button matSuffix (click)="onClickDestino()" [attr.aria-label]="'Buscar VIN'">
      <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
    </div>
  </mat-form-field>
</form>