import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Funcion } from './model/funcion';
import { Usuario } from './model/usuario';
import { SoundService } from './services/sound.service';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = environment.titulo;
  autenticado = false;
  usuario:Usuario|null = null;
  nombre:string = 'Inicie sesión';
  sonido = true;
  public userInactivity: any;

  ambienteProd = environment.production;

  @ViewChild('snav', {static : false}) snav!: MatSidenav;

  constructor(
    private storge: StorageService,
    private sonidoSrv: SoundService,
    private router:Router
  ){ console.log("los cambios sí se están aplicando")}
  ngOnInit(): void {
    this.inactivityTime();
    this.storge.autenticado().subscribe(
      auth => {
        this.autenticado = auth;
        if(auth){
          this.storge.getUsuario().subscribe(
            user => {
              if(user){
                this.usuario = user;
                this.nombre = this.usuario.id;
              }
            }
          );          
        }else{
          this.usuario = null;
        }
      }
    );

    this.sonido = this.sonidoSrv.getConfigSonido();
  }

  logout(){  
    this.storge.logOut();
    this.router.navigate(['/']);
  }

  onMenu($event:Funcion){
    this.snav.close();
    let nav: NavigationExtras = { state: {funcion: $event}};
    this.router.navigate([$event.id],nav);
  }

  async onToggleSoundStatus($event: MouseEvent){
    $event.stopPropagation();
    
    await this.sonidoSrv.setConfigSonido(!this.sonido);
    console.log(`⚙️ Change sound status: ${this.sonido}`);
  }
  
  public inactivityTime() {
    window.onload = this.resetTimer;
    document.onmousemove = this.resetTimer;
    document.onkeypress = this.resetTimer;
  };

  public resetTimer = (() => {
    clearTimeout(this.userInactivity);
    this.userInactivity = setTimeout(() => {
      this.logout();
    }, 1800000)
}) 
}
