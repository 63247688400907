<form [formGroup]="form" autocomplete="off">
  <mat-form-field appearance="fill" class="full-width">
      <mat-label>Grupo</mat-label>
      <mat-select formControlName="grupo">
        <mat-option *ngFor="let grupo of grupos" [value]="grupo">
            {{grupo.descripcion}}
        </mat-option>
    </mat-select>
  </mat-form-field>
  <p>Operacion</p>
  <p>VIN {{vin?.id}}</p>
  <p>Ubicacion {{ubicacionString}}</p>

  <div class="example-container mat-elevation-z8">
    <div class="example-loading-shade"
         *ngIf="isLoadingResults || isRateLimitReached">
      <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
        Seleccione un grupo...
      </div>
    </div>
  
    <div class="example-table-container">
  
      <table mat-table [dataSource]="operaciones" class="example-table"
             matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
        <!-- Number Column -->
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row">{{row.number}}</td>
        </ng-container>
  
        <!-- Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Operacion</th>
          <td mat-cell *matCellDef="let row">{{row.title}}</td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  
    <mat-paginator [length]="resultsLength" [pageSize]="5"></mat-paginator>
  </div>

  <div *ngIf='paso == 3'>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Confirmar VIN</mat-label>
      <input matInput type="number" formControlName="confirmVin" placeholder="Confirmar VIN...">
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Observaciones</mat-label>
      <input matInput type="number" formControlName="observaciones" placeholder="Observaciones...">
    </mat-form-field>
  </div>
</form>
