import { HttpErrorResponse } from '@angular/common/http';
import {
  Component, 
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Funcion } from 'src/app/model/funcion';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';
import { MatButton } from '@angular/material/button';
import { BackendDespMercNacional } from 'src/app/services/backendServices/despachoMercNacional.service';
import { ResumenDialogComponent } from 'src/app/genericos/resumen-dialog/resumen-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface EventOnValidDespMercNac {
  valid: boolean;
  codOpPendiente: any;
  vin: any;
  comboResultados: any;
  lineas: any;
  vinDetail: any;
}

@Component({
  selector: 'app-despacho-mercado-nacional',
  templateUrl: './despacho-mercado-nacional.component.html',
  styleUrls: ['./despacho-mercado-nacional.component.scss']
})
export class DespachoMercadoNacionalComponent implements OnInit {
  @Input() funcion: Funcion | null = null;
  @Input() cliente: any;
  @Input() cuenta: any;
  @Input() subcuenta: any;
  @Input() codOpPendiente: any;
  @Input('aceptar') btnAceptar!: MatButton;

  @Output() valid = new EventEmitter<EventOnValidDespMercNac>();

  form = new FormGroup({
    vin: new FormControl(null, Validators.required),
    codOpPendiente: new FormControl(null, Validators.required),
    operacion: new FormControl(null, Validators.required),
    vinDetail: new FormControl(null),
  });

  comboResultados: any;
  listaOpPendientes: any;
  vinDetail: any;
  lineasOperaciones: any;
  enableOperacion = false;

  constructor(
    private back: BackendDespMercNacional,
    private msg: NotificationService,
    private el: ElementRef,
    private sound: SoundService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.form.statusChanges.subscribe((val) => {
      let ret = <string>val == 'VALID';
      this.valid.emit({
        valid: ret,
        vin: this.form.controls.vin.value,
        codOpPendiente: this.form.controls.vin.value,
        comboResultados: this.comboResultados,
        vinDetail: this.vinDetail,
        lineas: this.lineasOperaciones,
      });
    });

    this.setFocus('vin');
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
    //this.form.controls.operacion.disable();
  }

  ClearForm() {
    this.form.reset();
  }

    openDialog() {
    this.dialog.open(ResumenDialogComponent, {
      data: {
          notaFiscal: this.vinDetail.remito.id.toString(),
          concessionaria: this.vinDetail.concesionarioP.descripcion,
          transportador: this.vinDetail.hojaRuta.administradorTractor.descripcion,
          endereco: this.vinDetail.direccionEntregaP.direccion,
      },
    });
  }

  findDetailVin(){

    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      subCuenta: this.subcuenta,
      vin: this.form.controls.vin.value,
    };

    this.back.findDetailVin(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          
          if (data.LineaHojaRuta[0].vin.tieneOperacionPendientesBloquea) {
            this.msg.showError('Existem operações pendentes bloqueantes.');
            this.sound.playBeepError();
           return;
          }

          if (data.LineaHojaRuta[0].vin.fechaBloqueoPreparacion != null) {
            this.msg.showError('Existem operações bloqueantes.');
            this.sound.playBeepError();
            return;
          }

          this.msg.showSuccess('Ok');
          this.sound.playBeepOk();
          this.vinDetail = data.LineaHojaRuta[0];
          this.openDialog();
          this.vinDetail = this.form.controls.vinDetail.setValue(data.LineaHojaRuta[0]);
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  /** verifica se o vin está no stock */
  onClickSearch() {
    let vin: string = this.form.controls.vin.value;

    if (!this.funcion) return;
    this.back.findVin(vin, this.funcion).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.form.controls.vin.setValue(data.stockPlaya.vin.id);
          this.form.controls.operacion.enable(); 

          this.findDetailVin();
          this.lineasOperaciones = data.stockPlaya.opPendientes;
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  nextDanio(event: any) {
    event.preventDefault();
    this.onClickSearch();
  }
}
