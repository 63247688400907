import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Funcion } from 'src/app/model/funcion';
import { BackendRegOpAutoService } from '../../services/backendServices/registrarOpAuto.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ResgistracionOperacionAutomaticaService } from 'src/app/services/registracion-operacion-automatica/registracion-operacion-automatica.service';
import { SoundService } from 'src/app/services/sound.service';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { MatButton } from '@angular/material/button';

export interface EventOnValidRegOpAuto {
  valid: boolean;
  codOpPendiente: any;
  vin: any;
  operacion: any;
  proveedor: any;
  comboResultados: any;
  listaOpPendientes: any;
  resultado: any;
  lineas: any;
  observacion: any;
}

@Component({
  selector: 'app-reg-op-auto',
  templateUrl: './reg-op-auto.component.html',
  styleUrls: ['./reg-op-auto.component.scss'],
})
export class RegOpAutoComponent implements OnInit {
  @Input() funcion: Funcion | null = null;
  @Input() cliente: any;
  @Input() cuenta: any;
  @Input() subcuenta: any;
  @Input() codOpPendiente: any;
  @Input() observacion: any;
  @Input('aceptar') btnAceptar!: MatButton;

  @Output() valid = new EventEmitter<EventOnValidRegOpAuto>();

  form = new FormGroup({
    vin: new FormControl(null, Validators.required),
    codOpPendiente: new FormControl(null, Validators.required),
    operacion: new FormControl(null, Validators.required),
    proveedor: new FormControl(null, Validators.required),
    resultado: new FormControl(null, Validators.required),
    observacion: new FormControl(null),
  });

  proveedores: any;
  comboResultados: any;
  listaOpPendientes: any;
  lineasOperaciones: IdDescr[] = [];
  enableOperacion = false;

  constructor(
    private back: BackendRegOpAutoService,
    private msg: NotificationService,
    private el: ElementRef,
    private sound: SoundService,
    private regOperacionAutomatica: ResgistracionOperacionAutomaticaService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form.statusChanges.subscribe((val) => {
      let ret = <string>val == 'VALID';
      this.valid.emit({
        valid: ret,
        resultado: this.form.controls.resultado.value,
        operacion: this.form.controls.operacion.value,
        vin: this.form.controls.vin.value,
        codOpPendiente: this.form.controls.vin.value,
        comboResultados: this.comboResultados,
        listaOpPendientes: this.listaOpPendientes,
        proveedor: this.form.controls.proveedor.value,
        lineas: this.lineasOperaciones,
        observacion: this.form.controls.observacion,
      });
    });

    this.setFocus('vin');
  }

  onObservacionChange(value: string) {
    this.observacion = value;
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
    //this.form.controls.operacion.disable();
  }

  ClearForm() {
    this.form.reset();
  }

  listaOpPendientesComboBox(data: [{}]){
    this.listaOpPendientes = data;

    if (this.listaOpPendientes.length == 0 ) {
      this.msg.contolError({
        ok: false,
        err: { error: '', message: 'No se encuentran operaciones pendientes' },
      });
      this.sound.playBeepError();
    }

    if (this.listaOpPendientes.length == 1) {
      this.form.controls.codOpPendiente.setValue(this.listaOpPendientes[0]);
      this.focusProveedor();
    }

    this.setFocus('codOpPendiente');
  }

  findResults(){
    let { operacion } = this.form.controls.codOpPendiente.value;

    this.back.findResults(operacion.id).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.msg.showSuccess('Ok');
          this.sound.playBeepOk();
          this.comboResultados = data.resultadoOperacion
          this.setFocus('observacion');
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  /** Busca la información */
  onClickSearch() {
    let vin: string = this.form.controls.vin.value;
    if (!this.funcion) return;
    this.back.findVin(vin, this.funcion).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.form.controls.vin.setValue(data.stockPlaya.vin.id);
          this.msg.showSuccess('Ok');
          this.sound.playBeepOk();
          this.form.controls.operacion.enable(); 

          this.listaOpPendientesComboBox(data.stockPlaya.opPendientes);
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  nextDanio(event: any) {
    event.preventDefault();
    this.onClickSearch();
    this.focusOperacion();
  }

  focusOperacion() {
    this.setFocus('operacion');
  }

  focusProveedor() {
    this.form.controls.operacion.setValue(this.form.value.codOpPendiente.operacion.id)

    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      subcuenta: this.subcuenta,
      vin: this.form.controls.vin.value,
      operacion: parseInt(this.form.value.codOpPendiente.operacion.id),
    };
    
    this.regOperacionAutomatica.validaOperacion(payload).subscribe(
      (data) => {

        if (data && data.ok) {
          this.msg.showSuccess('Ok');
          this.sound.playBeepOk();

          if (data.proveedores.length === 0) {
            this.msg.contolError({
              ok: false,
              err: { error: '', message: 'No se encuentran proveedores' },
            });
            this.sound.playBeepError();
          }

          this.proveedores = data.proveedores;
          this.setFocus('observacion');

          if (this.proveedores.length == 1) {
            this.form.controls.proveedor.setValue(this.proveedores[0]);
            this.setFocus('observacion');
          }

          this.lineasOperaciones = data.lineasOperacion;
          this.findResults();

          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  focusResultado(type: string) {
    if (type === 'observacion') {
      this.form.value.observacion;
      this.setFocus('resultado');
    }
    if (type === 'proveedor') {
      let payload = {
        proveedor: parseInt(this.form.value.proveedor.id),
        operacion: parseInt(this.form.value.operacion),
      };

      this.regOperacionAutomatica.validaProveedor(payload).subscribe(
        (data) => {
          if (data && data.ok) {
            this.msg.showSuccess('Ok');
            this.sound.playBeepOk();
            this.setFocus('resultado');
            return;
          }
          this.msg.contolError(data);
          this.sound.playBeepError();
        },
        (error: HttpErrorResponse) => {
          this.msg.catchError(error);
        }
      );
    }

    if (type === 'resultado') {
      let payload = {
        operacion: parseInt(this.form.value.operacion),
        linea: 31,
        resultado: this.form.value.resultado.id
      };

      this.regOperacionAutomatica.validaResultado(payload).subscribe(
        (data) => {
          if (data && data.ok) {
            this.msg.showSuccess('Ok');
            this.sound.playBeepOk();
            this.btnAceptar.focus();
            return;
          }
          this.msg.contolError(data);
          this.sound.playBeepError();
        },
        (error: HttpErrorResponse) => {
          this.msg.catchError(error);
        }
      );
    }
  }
}
