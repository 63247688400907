import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError, observable } from 'rxjs';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Funcion } from '../model/funcion';
import { IdDescr, IdsDescr } from '../model/iddescr';
import { Pais } from '../model/pais';
import { ResponseConsultaTrasferencia } from '../model/response-consulta-trasferencia';
import { Usuario } from '../model/usuario';
import { StorageService } from './storage.service';
//import { JwtHelperService } from '@auth0/angular-jwt';
//import { TokenUser } from '../model/token-user.model';

// prueba

/* export interface responseJWT {
  hasError: boolean,
  stacktrace: string,
  message: string,
  response: {
      token: string,
      userName: string,
      esLdapUser: string

  }
} */

type responseChkpt = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
  transaccion: any;
  responsable: any;
  imputacion: any;
};

type responseVin = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
  modeloDescripcion: any;
  ubicacionActual: any;
  cliente: any;
  cuenta: any;
  subcuenta: any;
};

type responseDanios2 = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
};

type responseDanios = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
  message: string;
};

type responseTransaccion = {
  ok: boolean;
  transaccion: IdDescr[];
  inspectorAveria: IdDescr[];
  nroRemito: string;
};

type responseCuadrante = {
  ok: boolean;
  cuadrante: IdDescr[];
};

type consultaDaniosResponse = {
  ok: boolean;
  tipoDanio: IdsDescr[];
  parteDanio: IdsDescr[];
  gravedadDanio: IdsDescr[];
};

type responce = {
  ok: boolean;
  checkpoint: IdDescr[];
  vin: string;
  err: {
    error: string;
    message: string;
  };
};

type responsePlaya = {
  ok: boolean;
  unidadMedidaPlaya: IdDescr[];
};

type response = {
  ok: boolean;
  data: IdDescr[];
};

type responsePaises = {
  ok: boolean;
  data: Pais[];
};

type responseJWT = {
  ok: boolean;
  data: any;
  token: string;
  err: {
    error: string;
    message: string;
  };
};

export interface ResponseData<T> {
  hasError: boolean;
  message: string;
  response: T;
}

interface KeyValue {
  [index: string]: any;
}

interface HttpOptions {
  headers?: HttpHeaders;
  params?: HttpParams;
  observe?: 'body';
}

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  public usrServer: String = environment.urlBackend;

  public urlSeguridad: String = this.usrServer + 'seguridad/';

  public urlToken: String = this.usrServer + 'token/';

  public urlMobile: String = this.usrServer + 'mobile/';

  public headers = new Headers();
  public token: any;
  public auth: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private static readonly HEADER_AUTHORIZATION = 'Authorization';

  constructor(
    private httpClient: HttpClient,
    private storage: StorageService
  ) {}

  public getPaises(): Observable<Pais[]> {
    return this.httpClient.get<Pais[]>(this.urlSeguridad + 'findPaises');
  }

  public login(
    username: string,
    password: string,
    pais: number
  ): Observable<responseJWT> {
    let url = this.urlSeguridad + 'login';

    let body = {
      username,
      password,
      pais,
    };

    return this.httpClient.post<responseJWT>(url, body).pipe(
      tap((res: responseJWT) => {
        if (res) {
          // guardar token
          this.saveToken(res.data.token, res.data); //, res.dataUser.expiresIn
          this.storage.saveUsuario(res.data);
        }
      })
    );
  }

  private saveToken(token: string, user: any): void {
    //, expiresIn: string
    this.token = token;
    StorageService.setUser(user);
    localStorage.setItem('token', token);
    /* localStorage.setItem("EXPIRES_IN", expiresIn); */
  }

  public getTokenn(): string | null {
    if (!this.token) {
      this.token = localStorage.getItem('token');
    }
    return this.token;
  }

  public logout(): void {
    this.token = '';
    localStorage.removeItem('token');
    /* localStorage.removeItem("EXPIRES_IN"); */
  }

  public static getJsonOptions(customHeaders?: any): HttpOptions {
    return BackendService.getOptions({
      ...customHeaders,
      'Content-Type': 'application/json',
    });
  }

  public static getOptions(customHeaders?: any, others?: any): HttpOptions {
    const headers: any = { ...customHeaders };
    let user = StorageService.getUser();
    if (user) {
      if (user.token) {
        headers[BackendService.HEADER_AUTHORIZATION] = 'Bearer ' + user.token;
      }
    }
    return { headers: new HttpHeaders(headers), ...others };
  }

  public static getBackendApiUrl(url: string): string {
    return environment.urlBackend + url;
  }

  public getEndpointBack(path: string): string {
    return BackendService.getBackendApiUrl(path);
  }

  public changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<any> {
    const parameter = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    const httpUrl = this.usrServer + 'seguridad/updatePassword';
    const httpOptions = BackendService.getJsonOptions();

    /*  if(parameter){
      httpOptions.params = this.toHttpParams(parameter);
    } */

    return this.httpClient
      .put<any>(httpUrl, parameter, httpOptions)
      .pipe(map((data: any) => data));
  }

  private toHttpParams(params: KeyValue): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach((key) => {
        if (!this.isNil(params[key])) {
          httpParams = httpParams.set(key, params[key]);
        }
      });
    }
    return httpParams;
  }

  private isNil(value: any) {
    return value == null;
  }

  public getToken(
    username: string,
    password: string,
    pais: number,
    idioma: number
  ): Observable<string> {
    let url =
      this.urlToken +
      'findToken?username=' +
      username +
      '&password=' +
      password +
      '&pais=' +
      pais +
      '&idioma=' +
      idioma;
    return this.httpClient.get<string>(url);
  }

  public findVin(
    pais: any,
    vin: string,
    sistema: number
  ): Observable<ResponseConsultaTrasferencia> {
    let url = this.urlMobile + 'stockPlaya/consultaTrasferencia';

    const httpOptions = BackendService.getJsonOptions();

    let body = {
      pais: pais,
      vin: vin,
      funcion: 'mobile_transferencia',
    };

    return this.httpClient.post<ResponseConsultaTrasferencia>(
      url,
      body,
      httpOptions
    );
  }

  public validarTrasferencia(
    pais: number,
    vin: string,
    sistema: number,
    cuenta: number,
    subcuenta: number,
    ubicacionNueva: string,
    cliente: string
  ): Observable<any> {
    let url = this.urlMobile + 'stockPlaya/validarTrasferencia';

    const httpOptions = BackendService.getJsonOptions();

    let body = {
      pais: pais,
      vin: vin,
      cuenta: cuenta,
      subcuenta: subcuenta,
      ubicacionNueva: ubicacionNueva,
      funcion: 'mobile_transferencia',
      sistema: sistema,
      cliente: cliente,
    };

    return this.httpClient.post<any>(url, body, httpOptions);
  }

  public realizarTrasferencia(
    pais: number,
    vin: string,
    sistema: number,
    cuenta: number,
    subcuenta: number,
    ubicacionNueva: any,
    cliente: string
  ): Observable<any> {
    let url = this.urlMobile + 'stockPlaya/realizarTrasferencia';

    const httpOptions = BackendService.getJsonOptions();

    let body = {
      pais: pais,
      vin: vin,
      cuenta: cuenta,
      subcuenta: subcuenta,
      ubicacionNueva: ubicacionNueva,
      funcion: 'mobile_transferencia',
      sistema: sistema,
      cliente: cliente,
    };

    return this.httpClient.post<any>(url, body, httpOptions);
  }

  //************************************************************************************* */

  public findClientes(funcionId: string) {
    let token = localStorage.getItem('token'); // Obtiene el token del localstorage
    let user = localStorage.getItem('usuario'); // Obtiene el usuario del local storage
    let url = this.urlSeguridad + 'findClientes'; // Define la url del servicio

    if (!user) return throwError({ statusText: 'Usuario no logueado' }); // Si no hay usuario, lanza un error

    const parsedUser = JSON.parse(user) as Usuario; // Parsea el usuario

    // Realiza la peticion y retorna el listado de clientes
    return this.httpClient.get<IdsDescr[]>(url, {
      params: {
        funcionId,
        funcion: funcionId,
        paisId: parsedUser.pais.id.toString(),
        usuarioId: parsedUser.id.toString(),
      },
      headers: { authorization: `Bearer ${token}` },
    });
  }

  //************************************************************************************** */

  public findCuentas(
    clienteId: string,
    funcionId: string
  ): Observable<IdDescr[]> {
    let token = localStorage.getItem('token'); // Obtiene el token del localstorage
    let user = localStorage.getItem('usuario'); // Obtiene el usuario del local storage
    let url = this.urlSeguridad + 'findCuentas'; // Define la url del servicio

    if (!user) return throwError({ statusText: 'Usuario no logueado' }); // Si no hay usuario, lanza un error

    const parsedUser = JSON.parse(user) as Usuario; // Parsea el usuario

    // Realiza la peticion y retorna el listado de clientes
    return this.httpClient.get<IdDescr[]>(url, {
      params: {
        funcion: funcionId,
        funcionId,
        clienteId,
      },
      headers: { authorization: `Bearer ${token}` },
    });
  }

  //************************************************************************************* */

  public findSubcuentas(
    clienteId: string,
    cuentaId: string,
    sistemaID: string,
    funcionId: string
  ): Observable<IdDescr[]> {
    let paisId = localStorage.getItem('pais');

    let sistemaId = 59;

    let params = {
      funcionId: funcionId,
      clienteId: clienteId,
      usuarioId: '',
      sistema: sistemaId,
      cuentaId: cuentaId,
      funcion: funcionId,
    };

    const httpOptions = BackendService.getJsonOptions();

    httpOptions.params = this.toHttpParams(params);

    let url = this.urlSeguridad + 'findSubcuentas';

    let tempUser = localStorage.getItem('usuario');

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);

      params.usuarioId = usuario.id;

      return this.httpClient.get<IdDescr[]>(url, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  //************************************************************************************* */

  public consultaCheckpoint(
    vin: string,
    cliente: string,
    cuenta: number,
    sistemaId: string
  ): Observable<responce> {
    let tempUser = localStorage.getItem('usuario');

    let funcionId = 'mobile_carga_danios';

    let url = this.urlMobile + 'consultaCheckpoint';

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);

      let pais = usuario.pais.id;

      let body = {
        pais: pais,
        vin: vin,
        cliente: cliente,
        cuenta: cuenta,
        funcion: funcionId,
        sistema: sistemaId,
      };

      return this.httpClient.post<responce>(url, body, httpOptions);
    }

    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public consultaTipoDanios(
    cliente: string,
    tipoDanio: string
  ): Observable<any> {
    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'consultaTipoDanios';

    let sistemaId = 59;

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);

      let pais = usuario.pais.id;

      let body = {
        pais: pais,
        sistema: sistemaId,
        cliente: cliente,
        tipo: tipoDanio,
      };

      return this.httpClient.post<any>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public consultaImputaciones(
    cliente: string,
    cuenta: number,
    funcion: string,
    sistema: string,
    checkpoint: string
  ): Observable<any> {
    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'consultaImputaciones';

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);
      let pais = usuario.pais.id;
      let body = {
        pais: pais,
        funcion: funcion,
        sistema: sistema,
        cliente: cliente,
        cuenta: cuenta,
        checkpoint: checkpoint,
      };

      return this.httpClient.post<any>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public consultaResponsable(
    cliente: string,
    cuenta: number,
    funcion: string,
    sistema: string,
    chk: any,
    imputacion: number
  ): Observable<response> {
    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'consultaResponsable';

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);
      let pais = usuario.pais.id;
      let body = {
        pais,
        funcion,
        sistema,
        cliente,
        cuenta,
        checkpoint: chk,
        imputacion,
      };

      return this.httpClient.post<response>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public consultaDanios(
    sistema: number,
    cliente: string
  ): Observable<consultaDaniosResponse> {
    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'consultaDanios';

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);
      let pais = usuario.pais.id;
      let body = {
        pais: pais,
        sistema: sistema,
        cliente: cliente,
      };

      return this.httpClient.post<consultaDaniosResponse>(
        url,
        body,
        httpOptions
      );
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public consultaUnidadMedida(
    sistema: number,
    cliente: string,
    tipo: string
  ): Observable<responsePlaya> {
    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'consultaUnidadMedida';

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);

      let pais = usuario.pais.id;

      let body = {
        pais: pais,
        sistema: sistema,
        cliente: cliente,
        tipo: tipo,
      };

      return this.httpClient.post<responsePlaya>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public consultaCuadrante(
    sistema: number,
    cliente: string,
    parte: string
  ): Observable<responseCuadrante> {
    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'consultaCuadrante';

    let funcion = 'parte';

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);
      let pais = usuario.pais.id;
      let body = {
        pais: pais,
        funcion: funcion,
        sistema: sistema,
        cliente: cliente,
        parte: parte,
      };

      return this.httpClient.post<responseCuadrante>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public consultaTransaccion(
    vin: string,
    checkpoint: number,
    cliente: string,
    cuenta: number,
    funcion: string,
    sistema: string
  ): Observable<responseTransaccion> {
    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'consultaTransaccion';

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);

      let pais = usuario.pais.id;

      let body = {
        pais,
        funcion,
        vin,
        checkpoint,
        cliente,
        cuenta,
        sistema,
      };

      return this.httpClient.post<responseTransaccion>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public validaDanios(
    cliente: IdsDescr,
    cuenta: IdDescr,
    vin: string,
    tipoDeDanio: IdsDescr,
    partesDanios: IdsDescr,
    gravedadDeDanio: IdsDescr,
    cuadrante: IdDescr | null,
    unidadMedida: IdDescr,
    valorUnidadDeMedida: number,
    observacion: string,
    checkpoint: IdDescr,
    imputacion: IdDescr | null,
    responsable: IdDescr | null,
    transaccion: IdsDescr | null,
    inspectorAveria: IdDescr | null,
    fechaDanio: string | Date,
    funcion: Funcion
  ): Observable<responseDanios> {
    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'validaDanios';

    const httpOptions = BackendService.getJsonOptions();

    if (!valorUnidadDeMedida) {
      valorUnidadDeMedida = 0;
    }

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);

      let body = {
        pais: usuario.pais,
        vin,
        cliente,
        cuenta,
        tipoDeDanio,
        partesDanios,
        gravedadDeDanio,
        cuadrante,
        unidadMedida,
        valorUnidadDeMedida,
        observacion,
        checkpoint,
        imputacion,
        responsable,
        transaccion,
        inspectorAveria,
        fechaDanio,
        funcion: funcion.id,
        sistema: funcion.sistema.id,
      };

      return this.httpClient.post<responseDanios>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public altaDanios(
    vin: string,
    cliente: IdsDescr,
    cuenta: IdDescr,
    tipoDeDanio: IdsDescr,
    parteDanio: IdsDescr,
    gravedadDeDanio: IdsDescr,
    cuadrante: IdDescr | null,
    unidadMedidaPlaya: IdDescr,
    valorUnidadDeMedida: number,
    observación: string,
    checkpoint: IdDescr,
    imputacion: IdDescr | null,
    responsable: IdDescr | null,
    transaccion: IdsDescr | null,
    inspectorAveria: IdDescr | null,
    fechaDanio: string | Date,
    sistema: string,
    funcion: string
  ): Observable<responseDanios2> {
    console.log("Ejecutando alta de daños")
    console.log({ fechaDanio })

    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'altaDanios';

    const httpOptions = BackendService.getJsonOptions();

    if (!valorUnidadDeMedida) {
      valorUnidadDeMedida = 0;
    }

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);

      let body = {
        pais: usuario.pais,
        paisId: usuario.pais.id,
        vin: vin,
        cliente,
        cuenta,
        tipoDeDanio,
        partesDanios: parteDanio,
        gravedadDeDanio,
        cuadrante,
        unidadMedida: unidadMedidaPlaya,
        valorUnidadDeMedida,
        observacion: observación,
        checkpoint,
        imputacion,
        responsable,
        transaccion,
        inspectorAveria,
        fechaDanio,
        funcion: funcion,
        sistema,
      };

      return this.httpClient.post<responseDanios2>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public controlDaniosEcom(
    vin: string,
    modelo: string,
    destino: string,
    tipo: string,
    sistema: string
  ): Observable<responseDanios2> {
    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'controlDaniosEcom';

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);

      let body = {
        pais: usuario.pais.id,
        vin,
        modelo,
        destino,
        tipo,
        sistema,
      };

      return this.httpClient.post<responseDanios2>(url, body, httpOptions);
    } else {
      return throwError({ statusText: 'Usuario no loguiado' });
    }
  }

  public validaDespacho(payload: any): Observable<responseDanios2> {
    let tempUser = localStorage.getItem('usuario');

    let url = `${this.urlMobile}validaUbicacionDespacho`;

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);

      let body = {
        pais: usuario.pais.id,
        vin: payload.vin,
        cliente: '00000453',
        cuenta: 1,
        subcuenta: 1,
        ubicacion: payload.ubicacion,
        funcion: 'mobile_carga_danios',
      };

      return this.httpClient.post<responseDanios2>(url, body, httpOptions);
    }

    return throwError({ statusText: 'Usuario no logueado' });
  }

  public validaVinRemito(
    vin: string,
    sistema: string,
    funcion: string
  ): Observable<responseDanios2> {
    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'validaVinRemito';

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);

      let body = {
        pais: usuario.pais.id,
        vin: vin,
        funcion: funcion,
        sistema,
      };

      return this.httpClient.post<responseDanios2>(url, body, httpOptions);
    }

    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public validaVinRecibido(
    vin: string,
    vinRemito: string,
    sistema: string,
    funcion: string
  ): Observable<responseVin> {
    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'validaVinRecibido';

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);

      let body = {
        pais: usuario.pais.id,
        vin,
        vinRemito,
        funcion,
      };

      return this.httpClient.post<responseVin>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  public consultaCheckpointDefault(
    funcion: Funcion | null,
    cliente: string,
    cuenta: number,
    vin: string,
    checkpoint: number
  ): Observable<responseChkpt> {
    let tempUser = localStorage.getItem('usuario');

    let url = this.urlMobile + 'consultaCheckpointDefault';

    const httpOptions = BackendService.getJsonOptions();

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);

      let sistema = funcion?.sistema.id || 'ErrorFuncionSistema';

      let body = {
        pais: usuario.pais.id,
        vin: vin,
        cliente,
        cuenta,
        checkpoint,
        sistema,
      };

      return this.httpClient.post<responseChkpt>(url, body, httpOptions);
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }
}
