import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { Usuario } from 'src/app/model/usuario';
import { environment } from 'src/environments/environment';
import { BackendService } from '../backend.service';

type responseLO = {
  ok: boolean;
  lineasOperacion: any;
  proveedores: any;
  err: {
    error: string;
    message: string;
  };
};

type response = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
};
@Injectable({
  providedIn: 'root',
})
export class ResgistracionOperacionAutomaticaService {
  usrServer: String = environment.urlBackend;
  urlSeguridad: String = this.usrServer + 'seguridad/';
  urlToken: String = this.usrServer + 'token/';
  urlMobile: String = `${this.usrServer}mobile`;

  constructor(private httpClient: HttpClient) {}
  //existe en el component de reg-op-auto y valida la operacion
  public validaOperacion(payload: any): Observable<responseLO> {
    let tempUser = localStorage.getItem('usuario');

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);
      let body = {
        pais: usuario.pais.id,
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        subcuenta: payload.subcuenta.id,
        operacion: payload.operacion,
        vin: payload.vin,
      };
      const httpOptions = BackendService.getJsonOptions();

      return this.httpClient.post<responseLO>(
        `${this.urlMobile}/validaOperacion`,
        body,
        httpOptions
      );
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }
  //valida si existe proveedor en el componente reg-op-auto
  public validaProveedor(payload: any): Observable<response> {
    let tempUser = localStorage.getItem('usuario');
   // console.log('valida provee:', payload);
    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);
      let body = {
        pais: usuario.pais.id,
        proveedor: payload.proveedor,
        operacion: payload.operacion,
      };
      const httpOptions = BackendService.getJsonOptions();

      return this.httpClient.post<response>(
        `${this.urlMobile}/validaProveedor`,
        body,
        httpOptions
      );
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  //valida el resultado desde reg-op-auto
  public validaResultado(payload: any): Observable<response> {
    let tempUser = localStorage.getItem('usuario');

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);
      let body = {
        pais: usuario.pais.id,
        operacion: payload.operacion,
        resultado: payload.resultado,
        linea: payload.linea,
      };

      const httpOptions = BackendService.getJsonOptions();

      return this.httpClient.post<response>(
        `${this.urlMobile}/validaResultado`,
        body,
        httpOptions
      );
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }

  //registra los post operaciones
  public postOperacionAutomatica(payload: any): Observable<response> {
    let tempUser = localStorage.getItem('usuario');

    if (tempUser) {
      let usuario = <Usuario>JSON.parse(tempUser);
      let body = {
        pais: usuario.pais,
        cliente: payload.cliente,
        cuenta: payload.cuenta,
        subcuenta: payload.subcuenta,
        proveedor: { id: payload.proveedor.id },
        vin: payload.vin,
        operacion: payload.operacion,
        lineas: payload.lineas,
        funcion: payload.funcion.id,
        observacion: payload.observacion,
      };

      const httpOptions = BackendService.getJsonOptions();

      return this.httpClient.post<response>(
        `${this.urlMobile}/registrarOperacionAutomatica`,
        body,
        httpOptions
      );
    }
    return throwError({ statusText: 'Usuario no loguiado' });
  }
}
