import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError, Observable } from 'rxjs';
import { Funcion } from "../../model/funcion";
import { IdsDescr } from "../../model/iddescr";
import { Usuario } from "../../model/usuario";
import { environment } from "../../../environments/environment";
import { BackendService } from "../backend.service";

type response = {
  ok:boolean;
  err:{
    error:string;
    message:string;
  },
};

type responseOF = {
  ok:boolean;
  err:{
    error:string;
    message:string;
  },
  operacionesForzadas:[];
};

@Injectable({
  providedIn: 'root'
})
export class RegistrarOperacionForzadaService {

  private usrServer: String = environment.urlBackend;
  
  private urlMobile: String = this.usrServer+"mobile/";

  constructor(
    private httpClient: HttpClient
  ) { }

  public insertarOperacionPendiente(payload: any): Observable<response> {
    
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();

    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    
    let url = `${this.urlMobile}insertarOperacionPendiente`;
    
    if(tempUser){
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse( tempUser );

      // genera el body que se enviara en la peticion
      let body = {
        pais: {id: usuario.pais.id},
        cliente: {id: payload.cliente.id},
        cuenta: {id: payload.cuenta.id},
        operacion: {id: parseInt( payload.operacion)},
        vin: {id: payload.vin}, 
      };
      
      return this.httpClient.post<response>(url, body, httpOptions);
      
    }
    return throwError({statusText:'Usuario no loguiado'});
  }

  public validaVinOpForzada(payload: any, funcion:Funcion): Observable<response>{
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();

    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    
    let url = `${this.urlMobile}validaVinOperacionesForzadas`;
    
    if(tempUser){
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse( tempUser );
      
      // genera el body que se enviara en la peticion
      let body = {
        pais: usuario.pais.id,
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        subcuenta: payload.subcuenta.id,
        vin: payload.vin
      };

      return this.httpClient.post<response>(url, body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }

  public  validaOperacion(payload:any): Observable<response>{
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();

    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    
    let url = this.urlMobile+'validaOperacionForzada';

    if(tempUser){
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse( tempUser );

      // genera el body que se enviara en la peticion
      let body = {
        pais: usuario.pais.id,
        cliente: payload.cliente.id,
        cuenta: payload.cuenta.id,
        operacion: parseInt(payload.operacion),
        operacionesForzadas: payload.operacionesForzadas,
        vin: payload.vin,
      };

     return this.httpClient.post<response>(url,body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }

  public listadoOpForzadas(): Observable<responseOF>{
    // obtiene el token por header
    const httpOptions = BackendService.getJsonOptions();

    // obtiene el usuario desde el local storage del navegador
    let tempUser = localStorage.getItem('usuario');
    
    let url = `${this.urlMobile}operacionesForzadas`;
  
    if(tempUser){
      // parsea el usuario obtenido del local storage a tipo Usuario
      let usuario = <Usuario>JSON.parse( tempUser );

      // genera el body que se enviara en la peticion
      let body = {
        pais: usuario.pais.id
      };
  
      return this.httpClient.post<responseOF>(url,body, httpOptions);
    }
    return throwError({statusText:'Usuario no loguiado'});
  }
  
}
