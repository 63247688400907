import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defer, NEVER, Observable } from 'rxjs';
import { finalize, share } from 'rxjs/operators';
import { DialogData, DialogoComponent } from '../genericos/dialogo/dialogo.component';
import { SpinnerOverlayComponent } from '../genericos/spinner-overlay/spinner-overlay.component';
import { SoundService } from './sound.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private toastr: ToastrService,
    public dialog: MatDialog,
    private overlay: Overlay,
    private router: Router,
    private sound: SoundService
  ) { }

  catchError(error: HttpErrorResponse) {
    if(error.status == 401){
      this.showError('El token expiro','Token inválido');
      this.router.navigate(['login']);
    }else{      
      if(error.error && error.error.err){
        this.showError(error.error.err.message,error.error.err.error);
      }else{
        this.showError(error.statusText,'Error inesperado');
      }      
    }    
  }

  contolError(data: { ok: boolean; err: { error: string; message: string; }; }) {
    if(data && data.ok){
      this.showSuccess("Ok","Ok");
    }else{
      if(data && !data.ok){
        if(data.err){
          this.showError(data.err.message,data.err.error);
          return;
        }
      }
      this.showError("Error desconocido","Error");
    }
  }

  private overlayRef: OverlayRef|undefined = undefined;
  
  public readonly spinner$ = defer(() => {
    this.show();
    return NEVER.pipe(
      finalize(() => {
        this.hide();
      })
    );
  }).pipe(share());

  showSuccess(message?:string, title?:string){
    this.toastr.success(message, title);
  }

  showInfo(message?:string, title?:string){
    this.toastr.info(message, title);
  }

  showError(message?:string, title?:string){
    this.toastr.error(message, title);
    this.sound.playBeepError();
  }

  openDialog(titulo:string,mensaje:string):Observable<any> {    
    let data:DialogData =
    {
      mensaje,
      titulo
    };
   const dialogRef = this.dialog.open(DialogoComponent, {
      width: '250px',
      data
    });

    return dialogRef.afterClosed();
  }  

  public show(): void {
    // Hack avoiding `ExpressionChangedAfterItHasBeenCheckedError` error
    Promise.resolve(null).then(() => {
      if (!this.overlayRef) {
        this.overlayRef = this.overlay.create({
          positionStrategy: this.overlay
            .position()
            .global()
            .centerHorizontally()
            .centerVertically(),
          hasBackdrop: true,
        });
        this.overlayRef.attach(new ComponentPortal(SpinnerOverlayComponent));
      }
    });
  }

  public hide(): void {
    if(this.overlayRef){
      this.overlayRef.detach();
      this.overlayRef = undefined;
    }
      
  }
}
