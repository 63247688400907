import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendControlDaniosEcomService } from 'src/app/services/backendServices/controlDaniosEcom.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';

export type ConDniosEcomValidEvent = {
  valid: boolean;
  vin: string;
  modelo: string;
  destino: string;
  tipo: string;
  marca: IdsDescr | null;
};

@Component({
  selector: 'app-control-de-danios-ecom',
  templateUrl: './control-de-danios-ecom.component.html',
  styleUrls: ['./control-de-danios-ecom.component.scss'],
})
export class ControlDeDaniosEcomComponent implements OnInit, AfterViewInit {
  @Output() valid = new EventEmitter<ConDniosEcomValidEvent>();
  @Output() emitFocus = new EventEmitter();

  @Input() funcion: Funcion | null = null;
  @Input() cliente: IdsDescr | null = null;
  @Input() cuenta: IdDescr | null = null;
  @ViewChild('vin') txtVin: ElementRef | null = null;

  marca: IdsDescr | null = null;
  showScann = false;

  form = new FormGroup({
    vin: new FormControl(
      null,
      Validators.compose([
        Validators.pattern('[0-9A-Z]{8}(?:[0-9a-zA-Z]{9})?'),
        Validators.required,
      ])
    ),
    modelo: new FormControl(
      null,
      Validators.compose([
        Validators.pattern('[0-9A-Z]{16}'),
        Validators.required,
      ])
    ),
    destino: new FormControl(
      null,
      Validators.compose([
        Validators.pattern('[0-9A-Z]{4}'),
        Validators.required,
      ])
    ),
    tipo: new FormControl(
      null,
      Validators.compose([Validators.pattern('[0-9]{1}'), Validators.required])
    ),
  });

  constructor(
    private back: BackendControlDaniosEcomService,
    private msg: NotificationService,
    private sound: SoundService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
   // console.log('control de danios ecom')
    this.form.controls.modelo.disable();
    this.form.controls.destino.disable();
    this.form.controls.tipo.disable();
    this.form.statusChanges.subscribe((valid) => {
      let ret = <string>valid == 'VALID';
      this.valid.emit({
        destino: this.form.controls.destino.value,
        modelo: this.form.controls.modelo.value,
        tipo: this.form.controls.tipo.value,
        valid: ret,
        vin: this.form.controls.vin.value,
        marca: this.marca,
      });
    });

    // this.form.controls.vin.statusChanges.subscribe(
    //   valid => {
    //     let sValid:string = valid;
    //     if(sValid == 'VALID'){
    //       this.onClickSearch();
    //     }
    //   }
    // );

    // this.form.controls.modelo.statusChanges.subscribe(
    //   valid => {
    //     let sValid:string = valid;
    //     if(sValid == 'VALID'){
    //       this.onClickSearchModelo();
    //     }
    //   }
    // );

    // this.form.controls.destino.statusChanges.subscribe(
    //   valid => {
    //     let sValid:string = valid;
    //     if(sValid == 'VALID'){
    //       this.onClickSearchDestino();
    //     }
    //   }
    // );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.txtVin?.nativeElement.focus();
    }, 0);
  }

  onClickScann() {
    this.showScann = true;
  }

  onScannCancel() {
   // console.log('btn cancel danios ecom');
    this.showScann = false;
  }

  onScanScan(value: string) {
    this.showScann = false;
    this.form.controls.vin.setValue(value);
    this.onClickSearch();
  }

  nextVin(event: any) {
    event.preventDefault();
    if (this.form.controls.vin.valid) {
      this.onClickSearch();
    }
  }

  nextModelo(event: any) {
    event.preventDefault();
    if (this.form.controls.modelo.valid) {
      this.onClickSearchModelo();
    }
  }

  nextDestino(event: any) {
    event.preventDefault();
    if (this.form.controls.destino.valid) {
      this.onClickSearchDestino();
    }
  }

  public ClearForm() {
    this.form.reset();
    this.setFocus('vin');
  }

  onClickSearch() {
    let vin: string = this.form.controls.vin.value;
    let inputVin = this.el.nativeElement.querySelector("input[name='vin']");
    let inputModelo = this.el.nativeElement.querySelector(
      "input[name='modelo']"
    );
    this.back
      .consultaVinDaniosEcom(
        vin,
        this.funcion,
        this.cliente?.id || 'ClienteIdError',
        this.cuenta?.id || -1
      )
      .subscribe(
        (data) => {
          if (data && data.ok) {
            this.form.controls.modelo.enable();
            inputModelo.focus();
            this.sound.playBeepOk();
          } else {
            this.form.controls.modelo.disable();
            this.msg.contolError(data);
            this.sound.playBeepError();
            this.setFocus('vin');
          }
        },
        (error: HttpErrorResponse) => {
          this.msg.catchError(error);
          this.sound.playBeepError();
        }
      );
  }

  onClickSearchModelo() {
    let vin: string = this.form.controls.vin.value;
    let modelo: string = this.form.controls.modelo.value;
    this.back
      .consultaModeloDaniosEcom(
        vin,
        this.funcion,
        this.cliente?.id || 'ClienteIdError',
        this.cuenta?.id || -1,
        modelo
      )
      .subscribe(
        (data) => {
          if (data && data.ok) {
            this.form.controls.destino.enable();
            this.marca = data.marca;
            let inputDestino = this.el.nativeElement.querySelector(
              "input[name='destino']"
            );
            inputDestino.focus();
            this.sound.playBeepOk();
          } else {
            this.form.controls.destino.disable();
            this.msg.contolError(data);
          }
        },
        (error: HttpErrorResponse) => {
          this.msg.catchError(error);
        }
      );
  }

  onClickSearchDestino() {
    let destino: string = this.form.controls.destino.value;
    this.back
      .consultaDestinoDaniosEcom(
        this.funcion,
        this.cliente?.id || 'ClienteIdError',
        this.cuenta?.id || -1,
        destino
      )
      .subscribe(
        (data) => {
          if (data && data.ok) {
            this.form.controls.tipo.enable();
            let tipo =
              this.el.nativeElement.querySelector("input[name='tipo']");
            tipo.focus();
            this.sound.playBeepOk();
          } else {
            this.form.controls.tipo.disable();
            this.msg.contolError(data);
          }
        },
        (error: HttpErrorResponse) => {
          this.msg.catchError(error);
        }
      );
  }

  nextTipo(event: any) {
    event.preventDefault();
    if (this.form.valid) {
      this.emitFocus.emit();
    }
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
        invalidControl.select();
      }, 0);
    }
  }
}
