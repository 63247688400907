import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  EventOnValidRegOpAuto,
  RegOpAutoComponent,
} from 'src/app/forms/reg-op-auto/reg-op-auto.component';
import { SeguridadEvent } from 'src/app/forms/seguridad/seguridad.component';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { NotificationService } from 'src/app/services/notification.service';
import { ResgistracionOperacionAutomaticaService } from 'src/app/services/registracion-operacion-automatica/registracion-operacion-automatica.service';
import { SoundService } from 'src/app/services/sound.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-mobile-registrar-operacion-automatica',
  templateUrl: './mobile-registrar-operacion-automatica.component.html',
  styleUrls: ['./mobile-registrar-operacion-automatica.component.scss'],
})
export class MobileRegistrarOperacionAutomaticaComponent implements OnInit {
  @ViewChild(RegOpAutoComponent)
  formControlOperacionAutomatica!: RegOpAutoComponent;

  funcion: Funcion | null = null;
  sistema: string = '59';
  paso: number = 1;
  subtitulo: string = 'Seguridad';
  // Seguridad
  cliente: IdsDescr | null = null;
  cuenta: IdDescr | null = null;
  subcuenta: IdDescr | null = null;
  //Valores Componente hijo
  valid = false;
  vin: any;
  operacion: any;
  codOpPendeinte: any;
  proveedor: any;
  observacion: any;
  resultado: any;
  lineas: any;

  constructor(
    private storage: StorageService,
    private router: Router,
    private back: ResgistracionOperacionAutomaticaService,
    private msg: NotificationService,
    private sound: SoundService
  ) {}

  ngOnInit() {
    this.funcion = this.storage.getFuncion(
      'mobile_registrar_operacion_automatica'
    );
    if (this.funcion) {
      this.sistema = this.funcion.sistema.id;
    }
  }

  onValidRegOpAuto($event: EventOnValidRegOpAuto) {
    //this.valid = $event.valid;
    this.valid = true;
    this.vin = $event.vin;
    this.operacion = $event.operacion;
    this.codOpPendeinte = $event.listaOpPendientes;
    this.proveedor = $event.proveedor;
    this.observacion = $event.observacion;
    this.resultado = $event.resultado;
    this.lineas = $event.lineas;
    this.lineas.forEach( (valorLinea:any) => {
    valorLinea.valor = this.resultado.id;
    });    
  }

  onClick() {
    if (this.paso < 2) {
      this.setPaso(this.paso + 1);
    } else {
      this.postRegistrarOperacionAutomatica();
    }
  }

  postRegistrarOperacionAutomatica() {

    if(this.observacion.value == null) this.observacion.value = "";

    const payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      subcuenta: this.subcuenta,
      codOpPendeinte: this.codOpPendeinte,
      proveedor: this.proveedor,
      vin: this.vin,
      operacion: { id: parseInt(this.operacion) },
      lineas: this.lineas,
      funcion: this.funcion,
      sistema: this.sistema,
      observacion: this.observacion.value,
    };

    this.back.postOperacionAutomatica(payload).subscribe(
      (data) => {
        if (data && data.ok) {
          this.msg.showSuccess('Operacion Registrada');
          this.formControlOperacionAutomatica.ClearForm();
          this.setPaso(2);
          this.sound.playBeepOk();
        } else {
          if (data && data.err) {
            this.msg.showError(data.err.message, data.err.error);
            this.formControlOperacionAutomatica.ClearForm();
            this.setPaso(2);
            this.sound.playBeepError();
          } else {
            this.msg.showError('Error inesperado', 'Error');
            this.formControlOperacionAutomatica.ClearForm();
            this.setPaso(2);
            this.sound.playBeepError();
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.msg.showError(error.statusText, 'Error inesperado');
        this.sound.playBeepError();
      }
    );
  }

  setPaso(nuevoPaso: number) {
    this.valid = false;
    this.paso = nuevoPaso;
    switch (this.paso) {
      case 1:
        this.subtitulo = 'Seguridad';
        break;
      case 2:
        this.subtitulo = `Registración de Operaciones Automaticas - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`;
        break;

      default:
        break;
    }
  }

  onCancel = () => {
    this.setPaso(this.paso - 1);
    if (this.paso == 0) this.router.navigate(['/']);
  };

  onValidSeguridad($event: SeguridadEvent) {
    if ($event.valid) {
      this.cliente = $event.cliente;
      this.cuenta = $event.cuenta;
      this.subcuenta = $event.subcuenta;
    }
    //this.valid = $event.valid;

    //inicio
    this.valid = true;
    this.cliente = $event.cliente;
    this.cuenta = $event.cuenta;
    this.subcuenta = $event.subcuenta;
    //fin (esto no deberia ir cuando se valide de manera correcta)
  }
}
