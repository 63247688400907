<form [formGroup]="form" autocomplete="off">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>VIN</mat-label>
    <input
      matInput
      formControlName="vin"
      maxlength="17"
      placeholder="Ingrese un VIN..."
      (keydown.enter)="nextDanio($event)"
      (keydown.tab)="nextDanio($event)"
    />
    <button
      mat-icon-button
      matSuffix
      (click)="onClickSearch()"
      [attr.aria-label]="'Buscar VIN'"
      [disabled]="!form.controls.vin.valid"
    >
      <img src="assets/icons/search.svg" style="margin-bottom: 15px" />
    </button>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Checkpoint</mat-label>
    <mat-select
      formControlName="checkPoint"
      #checkPoint
      (selectionChange)="onSelectionChangeDanio($event)"
    >
      <mat-option *ngFor="let checkPoint of checkPoints" [value]="checkPoint">
        {{ checkPoint.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Daño</mat-label>
    <mat-select
      formControlName="danio"
      #danio
      (selectionChange)="onSelectionChangeMedidas($event)"
      (closed)="onSelectionChangeMedidas($event)"
    >
      <mat-option *ngFor="let danio of danios" [value]="danio">
        {{ danio.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="half-width">
    <mat-label>Un.Med.Daño</mat-label>
    <mat-select
      formControlName="unidadDanio"
      #unidadDanio
      (selectionChange)="onSelectionChangeValor($event)"
      (closed)="onSelectionChangeValor($event)"
    >
      <mat-option *ngFor="let unidad of unidadDanios" [value]="unidad">
        {{ unidad.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="half-width">
    <mat-label>Valor</mat-label>
    <input
      type="number"
      matInput
      formControlName="valor"
      maxlength="256"
      placeholder="valor..."
      (keydown.enter)="valorKeyEnter($event)"
      (keydown.tab)="valorKeyEnter($event)"
    />
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Parte</mat-label>
    <mat-select
      formControlName="parte"
      (selectionChange)="onSelectionChangeCuadrante($event)"
      (closed)="onSelectionChangeCuadrante($event)"
    >
      <mat-option *ngFor="let parte of partes" [value]="parte">
        {{ parte.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="half-width">
    <mat-label>Cuadrante</mat-label>
    <mat-select
      formControlName="cuadrante"
      (selectionChange)="onSelectionChangeGravedad($event)"
    >
      <mat-option *ngFor="let cuadrante of cuadrantes" [value]="cuadrante">
        {{ cuadrante.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="half-width">
    <mat-label>Gravedad</mat-label>
    <mat-select
      formControlName="gravedad"
      (selectionChange)="onSelectionChangeObservacion($event)"
    >
      <mat-option *ngFor="let gravedad of gravedades" [value]="gravedad">
        {{ gravedad.descripcion }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Observación</mat-label>
    <input
      matInput
      formControlName="observacion"
      maxlength="256"
      placeholder="Observación..."
      (keydown.enter)="observacionKeyEnter($event)"
      (keydown.tab)="observacionKeyEnter($event)"
    />
  </mat-form-field>
</form>
