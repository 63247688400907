import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { EventOnValidReingresoDevolucion, ReingresoDevolucionComponent } from 'src/app/forms/reingreso-devolucion/reingreso-devolucion.component';
import { SeguridadEvent } from 'src/app/forms/seguridad/seguridad.component';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { NotificationService } from 'src/app/services/notification.service';
import { RegistrarOperacionForzadaService } from 'src/app/services/registrar-operacion-forzada/registrar-operacion-forzada.service';
import { ReingresoDevolucionService } from 'src/app/services/reingreso-devolucion/reingreso-devolucion.service';
import { SoundService } from 'src/app/services/sound.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-mobile-reingreso-devolucion',
  templateUrl: './mobile-reingreso-devolucion.component.html',
  styleUrls: ['./mobile-reingreso-devolucion.component.scss']
})
export class MobileReingresoDevolucionComponent implements OnInit {

  @ViewChild(ReingresoDevolucionComponent) formControlReingresoDevolucion!: ReingresoDevolucionComponent;

  funcion:Funcion|null = null;  
  sistema: string = '59';
  valid = false;
  paso:number = 1;
  subtitulo:string = 'Seguridad';
  // Seguridad
  cliente:IdsDescr|null = null;
  cuenta:IdDescr|null = null;
  subcuenta:IdDescr|null = null;
  //reg-devolucion-reingreso
  operacion:any|null = null;
  vin:any|null = null;
  transportista:any|null = null;
  chofer:any|null = null;
  marketing:any|null = null;
  fechanotafiscal:any|null = null;
  remitotransportista:any|null = null;
  remitodevolucion:any|null = null;
  fecharetorno:any|null = null;
  
  constructor(
    private storage:StorageService,
    private router: Router,
    private back: ReingresoDevolucionService,
    private msg: NotificationService,
    private sound: SoundService
  ) { 
    
  }

  ngOnInit() {
    this.funcion = this.storage.getFuncion('mobile_registrar_operacion_forzada');
    if(this.funcion){
      this.sistema = this.funcion.sistema.id;
    }
  }

  onValidReingresoDevolucion($event:EventOnValidReingresoDevolucion){
    if($event.valid){
      this.vin = $event.vin;
      this.transportista = $event.transportista;
      this.chofer = $event.chofer;
      this.fechanotafiscal = $event.fechaNotaFiscal;
      this.remitodevolucion = $event.remitoDevolucion;
      this.remitotransportista = $event.remitoTransportista;
      this.marketing = $event.virouMarketing;
    }
    this.valid = $event.valid; 
  }

  onClick(){
    if(this.paso < 2) {
    this.setPaso(this.paso+1);
    }
    else {
      this.postDevolucionReingreso()
    }        
  }

  postDevolucionReingreso(){
    let formatDate = moment(this.fechanotafiscal).format('MM/DD/YYYY h:mm:ss');
    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      subcuenta: this.subcuenta,
      vin: this.vin,
      transportista: this.transportista,
      chofer : this.chofer,
      marketing: this.marketing,
      fechanotafiscal: formatDate,
      remitotransportista: this.remitotransportista,
      remitodevolucion: this.remitodevolucion   
    }
    this.back.realizarDevolucionIngreso(payload).subscribe(
      data => {
        if(data && data.ok ){
          this.msg.showSuccess('Operacion Registrada');
          this.formControlReingresoDevolucion.ClearForm();
          this.setPaso(2);
          this.sound.playBeepOk();
        }else{
          if(data && data.err){
            this.msg.showError(data.err.message,data.err.error);
            this.sound.playBeepError();
          }else{
            this.msg.showError("Error inesperado","Error");
            this.sound.playBeepError();
          }            
        }
      },
      (error:HttpErrorResponse) => {
        this.msg.showError(error.statusText,"Error inesperado");
        this.sound.playBeepError();
      }
    );
  }

  setPaso(nuevoPaso: number) {
    this.valid = false;
    this.paso = nuevoPaso;
    switch (this.paso) {
      case 1:
        this.subtitulo = 'Seguridad';
        break;
      case 2:
        this.subtitulo = `Devolucion / Reingreso - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`; 
        break;
    
      default:
        break;
    }
  }

  onCancel = () => {
    this.setPaso(this.paso-1);
    if(this.paso == 0) this.router.navigate(['/']);
  }

  onValidSeguridad($event: SeguridadEvent){ 
      this.cliente = $event.cliente;
      this.cuenta = $event.cuenta;
      this.subcuenta = $event.subcuenta;
    
    //this.valid = $event.valid;
    this.valid = true;          
  }

  eventEnter(event:any){
    event.preventDefault()
    this.onClick();
  }
}
