<mat-card>
  <mat-card-header>
    <mat-card-title>Carga de Daños</mat-card-title>
    <mat-card-subtitle>{{ subtitulo }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content style="height: fit-content">
   <app-seguridad
      [funcion]="funcion"
      [aceptar]="aceptar"
      *ngIf="paso == 1"
      (valid)="onValidSeguridad($event)"
    ></app-seguridad>
    <app-checkpoint
      *ngIf="paso == 2"
      [cliente]="cliente"
      [cuenta]="cuenta"
      [funcion]="funcion"
      (valid)="onValidCheckPoint($event)"
      [aceptar]="aceptar"
    ></app-checkpoint>
    <app-danios
      (valid)="onValidDanios($event)"
      [cliente]="cliente"
      *ngIf="paso == 3"
      (emitFocus)="('onEmitFocus()')"
      [aceptar]="aceptar"
    ></app-danios>
    <app-transaccion
      [vin]="vin"
      [checkpoint]="checkpoint"
      [cliente]="cliente"
      [cuenta]="cuenta"
      [funcion]="funcion"
      *ngIf="paso == 4"
      (valid)="onValidTransaccion($event)"
      [aceptar]="aceptar"
    >
    </app-transaccion>
    <app-camara
      (valid)="onValidOtros($event)"
      *ngIf="paso == 5"
      [captures]="captures"
      [imagePath]="imagePath"
    ></app-camara>
  </mat-card-content>
  <mat-card-actions>
    <div style="text-align: center">
      <button mat-raised-button (click)="onCancel()">Cancelar</button>
      <button
        type="submit"
        mat-raised-button
        (click)="onClick()"
        #aceptar
        color="primary"
      >
        Aceptar
      </button>
    </div>
  </mat-card-actions>
</mat-card>
<!-- [disabled]="!valid"  -->
