<mat-card>
    <mat-card-header>
        <mat-card-title>Registración de Operaciones Forzadas</mat-card-title>
        <mat-card-subtitle>{{subtitulo}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content style="height: fit-content;">
        <app-seguridad 
            [funcion]="funcion"
            [aceptar]="aceptar"
            *ngIf='paso == 1' 
            (valid)='onValidSeguridad($event)'            
        ></app-seguridad>
        <app-reg-op-forzadas
          [funcion]='funcion'
          [aceptar]='aceptar'
          (valid)='onValidRegOpOt($event)'
          *ngIf='paso == 2'
          [cliente]="cliente"
          [cuenta]="cuenta"
          [subcuenta]="subcuenta"
        ></app-reg-op-forzadas>
    </mat-card-content>
    <mat-card-actions>
        <div style="text-align: center;">
            <button mat-raised-button (click)="onCancel()">Cancelar</button>
            <button type="submit" mat-raised-button (click)="onClick()" 
                [disabled]="!valid"
                #aceptar
                color="primary">Aceptar</button>    
        </div>
    </mat-card-actions>
  </mat-card>
  <!-- [disabled]="!valid"  -->