<mat-card>
  <mat-card-header>
    <mat-card-title>Carga daño reducida</mat-card-title>
    <mat-card-subtitle>{{ subtitulo }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <app-seguridad
      *ngIf="paso == 1"
      [conSubcuenta]="false"
      [aceptar]="aceptar"
      [funcion]="funcion"
      (valid)="onValidSeguridad($event)"
    ></app-seguridad>
    <app-danios-reducida
      [cliente]="cliente"
      [cuenta]="cuenta"
      [funcion]="funcion"
      (valid)="onValidDanio($event)"
      *ngIf="paso == 2"
      [aceptar]="aceptar"
    >
    </app-danios-reducida>
  </mat-card-content>
  <mat-card-actions>
    <div style="text-align: center">
      <button mat-raised-button (click)="onCancel()">Cancelar</button>
      <button
        type="submit"
        #aceptar
        mat-raised-button
        (click)="onClick()"
        color="primary"
      >
        Aceptar
      </button>
    </div>
  </mat-card-actions>
</mat-card>
<!-- [disabled]="!valid" -->
