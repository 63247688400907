import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IdDescr } from '../model/iddescr';
import { BackendService } from '../services/backend.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-camara',
  templateUrl: './camara.component.html',
  styleUrls: ['./camara.component.scss'],
})
export class CamaraComponent implements AfterViewInit {
  WIDTH = 370;
  HEIGHT = 370;

  @ViewChild('video')
  public video: ElementRef | any;

  @ViewChild('canvas')
  public canvas: ElementRef | any;

  @Output() valid = new EventEmitter<[boolean, Date]>();

  @Input() captures: string[] = [];
  @Input() imagePath: string[] = [];

  @ViewChild('imageInput0') imageInput0!: ElementRef;

  @ViewChild('imageInput1') imageInput1!: ElementRef;

  @ViewChild('imageInput2') imageInput2!: ElementRef;

  error: any;
  camaraActivated: boolean = true;
  imgBoolean: boolean[] = [false, false, false];
  newImagen: boolean = false;
  upImagen: any;
  upImagen2: any;
  stream: any;
  cameraOn : boolean = true;

  fecha: IdDescr[] = [];
  importe : any|null;


  form = new FormGroup({
    fecha: new FormControl(new Date()),
    importe: new FormControl(0),
  });

  constructor(
    private back: BackendService,
    private msg: NotificationService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.form.statusChanges.subscribe((valid) => {
      let ret = <string>valid == 'VALID';
      this.valid.emit([
        ret,
        this.form.controls.fecha.value,
      ]);
    });
  }

  async ngAfterViewInit() {
      await this.setupDevices();
    this.setFocus('fecha');
  }

  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (this.stream) {
          this.video.nativeElement.srcObject = this.stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'You have no output video device';
          this.camaraActivated = false;
        }
      } catch (e) {
        this.error = e;
      }
    }
  }

  async stopVideoOnly() {
    this.video.nativeElement.srcObject.getTracks().forEach(function(track) {
      track.stop();
    });
}

  capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.captures.push(this.canvas.nativeElement.toDataURL('image/png'));
  }

  setPhoto(idx: number) {
    var image = new Image();
    image.src = this.captures[idx];
    this.drawImageToCanvas(image);
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }

  openVideo(index: any) {
    this.capture();
    var removed: any = String;
    if (index === 0) {
      this.imgBoolean[0] = true;
      if (this.imgBoolean[0] && this.newImagen === false) {
        this.imagePath[0] = this.captures[0];
      }
      if (this.imgBoolean[0] && this.newImagen) {
        this.uploadImg(index);
        this.imagePath[0] = this.captures[0];
        removed = this.captures.splice(3);
      }
    }

    if (index === 1) {
      if (this.imagePath[0] != null) {
        this.imgBoolean[1] = true;
        if (this.imgBoolean[1] && this.newImagen === false) {
          this.imagePath[1] = this.captures[1];
        }
        if (this.imgBoolean[1] && this.newImagen) {
          this.uploadImg(index);
          this.imagePath[1] = this.captures[1];
          removed = this.captures.splice(3);
        }
      } else {
        this.msg.showError('Debe cargar las fotos en orden', 'Error');
      }
    }

    if (index === 2) {
      if (this.imagePath[0] != null && this.imagePath[1] != null) {
        this.imgBoolean[2] = true;
        if (this.imgBoolean[2] && this.newImagen === false) {
          this.imagePath[2] = this.captures[2];
        }
        if (this.imgBoolean[2] && this.newImagen) {
          this.uploadImg(index);
          this.imagePath[2] = this.captures[2];
          removed = this.captures.splice(3);
        }
      } else {
        this.msg.showError('Debe cargar las fotos en orden', 'Error');
      }
    }
  }
  borrarImage(index: any) {
    this.newImagen = true;

    if (index === 0) {
      this.imgBoolean[0] = false;
    }
    if (index === 1) {
      this.imgBoolean[1] = false;
    }
    if (index === 2) {
      this.imgBoolean[2] = false;
    }
  }

  agregarImg(event: any, index: any) {
    var removed: any = String;

    var file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      if (index === 0) {
        this.imgBoolean[0] = true;
        if (this.imgBoolean[0] && this.newImagen === false) {
          this.imagePath[0] = reader.result as string;
        }
        if (this.imgBoolean[0] && this.newImagen) {
          this.uploadImg(index);
          this.imagePath[0] = reader.result as string;
          removed = this.imagePath.splice(3);
        }
      }

      if (index === 1) {
        if (this.imagePath[0] != null) {
          this.imgBoolean[1] = true;
          if (this.imgBoolean[1] && this.newImagen === false) {
            this.imagePath[1] = reader.result as string;
          }
          if (this.imgBoolean[1] && this.newImagen) {
            this.uploadImg(index);
            this.imagePath[1] = reader.result as string;
            removed = this.imagePath.splice(3);
          }
        } else {
          this.msg.showError('Debe cargar las fotos en orden', 'Error');
        }
      }

      if (index === 2) {
        if (this.imagePath[0] != null && this.imagePath[1] != null) {
          this.imgBoolean[2] = true;
          if (this.imgBoolean[2] && this.newImagen === false) {
            this.imagePath[2] = reader.result as string;
          }
          if (this.imgBoolean[2] && this.newImagen) {
            this.uploadImg(index);
            this.imagePath[2] = reader.result as string;
            removed = this.imagePath.splice(3);
          }
        } else {
          this.msg.showError('Debe cargar las fotos en orden', 'Error');
        }
      }
    };
  }

  uploadImgButton(index: any) {
    this.upImagen2 = this.imagePath[this.imagePath.length - 1];
    this.imagePath.splice(index, 1, this.upImagen2);
  }

  uploadImg(index: any) {
    this.upImagen = this.captures[this.captures.length - 1];
    this.captures.splice(index, 1, this.upImagen);
  }

  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }

  nextImporte(event: any) {
    event.preventDefault();
    this.setFocus('importe');
  }

  onClickFileInputButton(event: any, id: any) {
    event.preventDefault();

    if (id == 0) {
      this.imageInput0.nativeElement.click();
    }

    if (id == 1) {
      this.imageInput1.nativeElement.click();
    }

    if (id == 2) {
      this.imageInput2.nativeElement.click();
    }
  }
}
