<form [formGroup]="form" autocomplete="off">
  <!-- VIN -->
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>VIN</mat-label>
    <input matInput formControlName="vin" maxlength="256" placeholder="Ingrese un VIN..."
      (keydown.enter)="nextDanio($event)" (keydown.tab)="nextDanio($event)" />
    <button mat-icon-button matSuffix (click)="onClickSearch()" [attr.aria-label]="'Buscar VIN'">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <mat-list *ngIf="lineasOperaciones?.length" role="list"
    style="overflow-y: scroll; height: 290px; margin-bottom: 18px;">
    <div mat-subheader>Operações pendentes bloqueantes</div>
    <mat-list-item *ngFor="let operaciones of lineasOperaciones; let i = index" role="listitem">{{
      operaciones.operacion.id }} - {{operaciones.operacion.descripcion}}
    </mat-list-item>
  </mat-list>

</form>