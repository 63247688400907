import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  private isSoundEnabled: boolean = true; // Indica si el sonido está activado o no

  constructor(private storage: StorageService) {
    console.log("🔈 Initializing SoundService");
    this.isSoundEnabled = this.getConfigSonido();

    console.log("🔈 With sound status at:", this.isSoundEnabled);
  }

  /** Reproduce el sonido de Ok */
  async playBeepOk() {
    console.log("🔈 Paying beep ok");

    // Si el sonido está desactivado, cancela la reproducción
    if (!this.isSoundEnabled) return console.log("🔈 Sound is disabled 🚫");

    // Reproduce el sonido de Ok
    await this.play100_2670();
  }

  /** Reproduce el sonido de Ok Operacional */
  async playBeepOperOk() {
    console.log("🔈 Paying beep oper ok");

    // Si el sonido está desactivado, cancela la reproducción
    if (!this.isSoundEnabled) return console.log("🔈 Sound is disabled 🚫");

    // Reproduce el sonido de Ok Operacional
    await this.play200_2670();
    await this.delay(300);
    await this.play200_1000();
  }

  /** Reproduce el sonido de Error */
  async playBeepError() {
    console.log("🔈 Paying beep error");

    // Si el sonido está desactivado, cancela la reproducción
    if (!this.isSoundEnabled) return console.log("🔈 Sound is disabled 🚫");

    // Reproduce el sonido de Error
    await this.play200_2670();
    await this.delay(300);
    await this.play200_2670();
    await this.delay(300);
    await this.play200_2670();
  }

  private async play100_2670() {
    const audio = new Audio(); // Crea un nuevo objeto Audio
    audio.src = '../../../assets/beep100-2670.wav'; // Asigna la ruta del archivo de sonido
    audio.load(); // Carga el archivo de sonido
    await audio.play(); // Reproduce el archivo de sonido
  }

  private async play200_2670() {
    const audio = new Audio(); // Crea un nuevo objeto Audio
    audio.src = '../../../assets/beep200-2670.wav'; // Asigna la ruta del archivo de sonido
    audio.load(); // Carga el archivo de sonido
    await audio.play(); // Reproduce el archivo de sonido
  }

  private async play200_1000() {
    const audio = new Audio(); // Crea un nuevo objeto Audio
    audio.src = '../../../assets/beep200-1000.wav'; // Asigna la ruta del archivo de sonido
    audio.load(); // Carga el archivo de sonido
    await audio.play(); // Reproduce el archivo de sonido
  }

  private delay(ms: number) {
    console.log("🔈 Making delay with promise time " + ms + " ms");

    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /** Obtiene el estado del sonido de la configuración de la aplicación */
  getConfigSonido(): boolean {
    console.log("🔈 Getting sonido config from storage");

    const soundStatusFromStorage: boolean = 
      this.storage.getParam<boolean>('sonido', true) ?? true;

    return soundStatusFromStorage;
  }

  async setConfigSonido(value: boolean): Promise<void> {
    console.log("🔈 Setting sonido config to " + value);

    this.isSoundEnabled = value;
    this.storage.setParam('sonido', value);

    await this.playBeepOk(); // Reproduce un beep para indicar que se ha cambiado el estado del sonido
  }
}
