<form [formGroup]="form" autocomplete="off">
    
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Código de operación</mat-label>
        <input matInput formControlName="operacion" #operacion placeholder="Código de operación..." onfocus="this.select()"
          (keydown.enter)="nextFocus($event, 1)" (keydown.tab)="nextFocus($event, 1)">
        <div mat-icon-button matSuffix (click)="onClickOperacion()" [attr.aria-label]="'Buscar Operación'">
          <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
        </div>
      </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Descripcion operación</mat-label>
      <input matInput formControlName="operacionDesc" #operacionDesc (keydown.enter)="nextFocus($event, 2)"
        (keydown.tab)="nextFocus($event, 2)" type="text"  placeholder="Descripcion operación..."
        onfocus="this.select()">
    </mat-form-field>
</form>