import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import {
  ConDniosEcomValidEvent,
  ControlDeDaniosEcomComponent,
} from 'src/app/forms/control-de-danios-ecom/control-de-danios-ecom.component';
import {
  DaniosComponent,
  EventParamDanios,
} from 'src/app/forms/danios/danios.component';
import { GenerarOpPendienteComponent, RecepcionOp } from 'src/app/forms/generar-op-pendiente/generar-op-pendiente.component';
import {
  IngresoVinUnificadoComponent,
  RecepcionEventType,
} from 'src/app/forms/ingreso-vin-unificado/ingreso-vin-unificado.component';
import { SeguridadEvent } from 'src/app/forms/seguridad/seguridad.component';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendService } from 'src/app/services/backend.service';
import { BackendControlDaniosEcomService } from 'src/app/services/backendServices/controlDaniosEcom.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RegistrarProcesoUnificadoService } from 'src/app/services/registrar-proceso-unificado/registrar-proceso-unificado.service';
import { SoundService } from 'src/app/services/sound.service';

@Component({
  selector: 'app-mobile-danios',
  templateUrl: './mobile-danios.component.html',
  styleUrls: ['./mobile-danios.component.scss'],
})
export class MobileDaniosComponent implements OnInit {
  @ViewChild(DaniosComponent) formControDanios!: DaniosComponent;
  @ViewChild(ControlDeDaniosEcomComponent)
  formControlDaniosEcom!: ControlDeDaniosEcomComponent;
  @ViewChild(GenerarOpPendienteComponent)
  formControlGenerateOP!: GenerarOpPendienteComponent;
  @ViewChild(IngresoVinUnificadoComponent)
  formIngresoVinUnificado!: IngresoVinUnificadoComponent;
  @ViewChild(IngresoVinUnificadoComponent) destinoOk; 
  @ViewChild('okey') btnAceptado!: MatButton;
  @ViewChild('conDanio') btnConDanio!: MatButton;
  @ViewChild('rechazo') btnRechazo!: MatButton;

  funcion: Funcion | null = null;
  sistema: string = '59';
  valid = false;
  paso: number = 1;
  subtitulo: string = 'Seguridad';
  acces1 = false;
  acces2 = false;
  acces3 = false;
  acces4 = false;
  enabledButton = true;
  hacces = false;
  esProcesoUnificado = false;
  danios: any[] = [];
  tipoNumber: number = 0;
  // Seguridad
  cliente: IdsDescr | null = null;
  cuenta: IdDescr | null = null;
  subcuenta: IdDescr | null = null;
  puestoDeRecepcion: any = [];
  // ControlDaniosEcom
  vin: string | null = null;
  modelo: string | null = null;
  destino: string | null = null;
  tipo: string | null = null;
  // dañios
  operacion: number | null = null;
  operacionDesc: string | null = null; 
  valor: number = 0;
  unidadMedida: IdDescr | null = null;
  parte: IdsDescr | null = null;
  observacion: string = '';
  gravedad: IdsDescr | null = null;
  danio: IdsDescr | null = null;
  cuadrante: IdDescr | null = null;
  marca: IdsDescr | null = null;
  //vin unificado
  orden: any = null;
  motor: any = null;
  descripcionVin = '';
  constructor(
    private router: Router,
    private msg: NotificationService,
    private back: BackendService,
    private backDaniosEcom: BackendControlDaniosEcomService,
    private sound: SoundService,
    private backUnificado: RegistrarProcesoUnificadoService,
    private el: ElementRef
  ) {
    let nav = this.router.getCurrentNavigation();
    this.funcion = <Funcion>nav?.extras?.state?.funcion;
    if (!this.funcion) {
      this.router.navigate(['']);
    }
    this.sistema = this.funcion.sistema.id;
  }
  ngOnInit(): void { }
  onEmitFocus() {
    if (this.valid) {
      this.onClick();
    }
    GenerarOpPendienteComponent
  }
  onValidControlDanios($event: EventParamDanios) {
    if ($event.valid) {
      this.cuadrante = $event.cuadrante;
      this.danio = $event.danio;
      this.gravedad = $event.gravedad;
      this.observacion = $event.observacion;
      this.parte = $event.parte;
      this.unidadMedida = $event.unidadMedida;
      this.valid = $event.valid;
      this.valor = $event.valor;
    }
    this.valid = $event.valid;
  }
  onValidSeguridad($event: SeguridadEvent) {
    this.cliente = $event.cliente;
    this.cuenta = $event.cuenta;
    this.subcuenta = $event.subcuenta;
    this.esProcesoUnificado = $event.esProcesoUnificado;
    this.puestoDeRecepcion = $event.puestoDeRecepcion;
    //this.valid = $event.valid;
    this.valid = true;
  }
  onValidControlDaniosEcom($event: ConDniosEcomValidEvent) {
    this.valid = $event.valid;
    if (this.valid) {
      this.vin = $event.vin;
      this.modelo = $event.modelo;
      this.destino = $event.destino;
      this.tipo = $event.tipo;
      this.marca = $event.marca;
    }
  }
  
  onValidIngresoVinUnificado($event: RecepcionEventType) {
    this.vin = $event.vin;
    this.orden = $event.orden;
    this.modelo = $event.modelo;
    this.destino = $event.destino;
    this.motor = $event.motor;
    this.marca = $event.marca;

    //console.log('rep', this.destinoOk); se creo destinoOk pa utilizarse dado el caso de que ka accion de abajo no se deba dar y se deba habilitar el destino cuando este ok solamente (no se esta utilizando destinoOk).
    if ($event.destino && $event.modelo
      && $event.motor && $event.vin && $event.orden) {
    
      this.acces1 = true;
       this.acces2 = true;
       this.acces3 = true;
       this.acces4 = true;
     }else{
      this.acces1 = false;
       this.acces2 = false;
       this.acces3 = false;
       this.acces4 = false;
     }
    this.valid = $event.valid;
  }

  onValidOperacion($event: RecepcionOp) {
    
    this.operacion = $event.operacion;
    this.operacionDesc = $event.operacionDesc;
   // console.log("valid op", $event);

    if ($event.operacionDesc) {
      console.log("ingresa if");
      this.acces1 = true;
      this.acces2 = true;
      this.acces3 = true;
      this.acces4 = true;
    }
    this.valid = $event.valid;
  }

  onEnableButton($event: any) {
   // console.log("on enable BTN", $event)
    if(typeof($event) !== 'boolean') {
   //   console.log("entra if");
      this.acces1 = $event.acces1;
      this.acces2 = $event.acces2;
      this.acces3 = $event.acces3;
      this.acces4 = $event.acces4;
    } else {
     // console.log("entra else")
      this.acces1 = $event;
      this.acces2 = $event;
      this.acces3 = $event;
      this.acces4 = $event;
    }
    // this.enabledButton = $event;
    // this.acces = !$event;
  }
  
  onEnableButtons($event: boolean) {
   // console.log("on enable BTNS", $event)
    this.acces1 = $event;
    this.acces2 = $event;
    this.acces3 = $event;
    this.acces4 = $event;
  }
  
  public onCancel = () => {
   // console.log("cancel")
    if (this.paso == 4){
      this.setPaso(this.paso - 2, 0);
      this.acces1 = false;
      this.acces2 = false;
      this.acces3 = false;
      this.acces4 = false;
    }else{
    this.setPaso(this.paso - 1, 0);
    if (this.paso == 0) this.router.navigate(['/']);
      this.acces1 = false;
      this.acces2 = false;
      this.acces3 = false;
      this.acces4 = false;
    }
  };
  
  onClick() {
    if (!this.valid) return;
    if (this.paso == 2) {
      // si estamos en el formulario hay que validar los datos
      if (
        !this.cliente ||
        !this.cuenta ||
        !this.subcuenta ||
        !this.tipo ||
        !this.funcion ||
        !this.vin ||
        !this.modelo ||
        !this.marca ||
        !this.destino
      ) {
        this.msg.showError('Faltan datos', 'Error');
        return;
      }
      let destino: IdsDescr = { id: this.destino, descripcion: null };
      let modelo: IdsDescr = { id: this.modelo, descripcion: null };
      this.backDaniosEcom
        .altaDaniosEcom(
          this.cliente,
          this.cuenta,
          this.subcuenta,
          this.tipo,
          this.funcion,
          this.vin,
          modelo,
          this.marca,
          destino
        )
        .subscribe(
          (ret) => {
            if (ret && ret.ok) {
              if (this.tipo === '1') {
                this.msg.showSuccess(
                  'Validacion Exitosa',
                  'Carga de Daño Ecom'
                );
                this.formControlDaniosEcom.ClearForm();
                this.setPaso(2, 0);
                this.sound.playBeepOk();
                return;
              }
              this.setPaso(3, 0);
              return;
            }
            this.msg.contolError(ret);
          },
          (error: HttpErrorResponse) => {
            this.msg.catchError(error);
          }
        );
      return;
    }
    if (this.paso < 3) {
      this.setPaso(this.paso + 1, 0);
      this.sound.playBeepOk();
    } else if (this.paso = 4){
      this.ingresoUnificadoSinEcom();
      //this.formControlGenerateOP.ClearForm();
    }else {
      this.avanzarConLaConfirmacion();
    }
  }
  public setPaso(paso: number, tipo: number) {
    if (tipo == 2) {
      this.tipoNumber = 2;
    }
    if (tipo == 3) {
      this.tipoNumber = 3;
    }
    if (tipo == 4) {
      this.tipoNumber = 4;
    }
    this.valid = false;
    this.paso = paso;
    switch (this.paso) {
      case 1:
        this.subtitulo = 'Seguridad';
        break;
      case 2:
        if (!this.esProcesoUnificado) {
          this.subtitulo = `Control de Daños ECOM - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`;
        } else {
          this.subtitulo = `Ingreso Vin Unificado - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id}) - ${this.puestoDeRecepcion}`;
        }
        break;
      case 3:
        if (!this.esProcesoUnificado) {
          this.subtitulo = `Ingreso Daño - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`;
          this.descripcionVin = 'VIN: ' + this.vin + ' - Tipo: ' + this.tipo;
        } else {
          this.subtitulo = `Daño Ecom Unificado - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id}) - ${this.vin} - ${this.puestoDeRecepcion}`;
        }
        break;
        case 4:
          this.subtitulo = `cargar OP - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id}) - ${this.vin} - ${this.puestoDeRecepcion}`;
        break;
      default:
        //this.subtitulo = 'Error'; o 0, que salio
        break;
    }
  }


  ingresoUnificadoSinEcom() {
    

    const payload = {
      vin: this.vin,
      cliente: this.cliente,
      cuenta: this.cuenta,
      subcuenta: this.subcuenta,
      operacion: {
        id: this.operacion
      },
      destino: this.destino,
      puestoDeRecepcion: this.puestoDeRecepcion,
      ordenFabricacion: this.orden,
      motor: this.motor,
      modelo: this.modelo,
      marca: this.marca,
      /*tipo: this.tipoNumber,
     */
    };

    this.backUnificado.danioIngresoUnificadoSinEcom(payload, this.funcion).subscribe(
      (data) => {
        
        if (data && data.ok) {
          this.msg.showSuccess('Operacion exitosa');
         // console.log("danios ing unif");
          this.danios = [];
          this.acces1 = false;
          this.acces2 = false;
          this.acces3 = false;
          this.acces4 = false;
          this.setPaso(2, 0);
          this.formControlGenerateOP.ClearForm();
          this.sound.playBeepOk();
        } else {
          if (data && data.err) {
            this.msg.showError(data.err.message, data.err.error);
            this.danios = [];
            this.sound.playBeepError();
          } else {
            this.msg.showError('Error inesperado', 'Error');
            this.sound.playBeepError();
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.msg.showError(error.statusText, 'Error inesperado');
      }
    );
  }
  avanzarConLaConfirmacion() {
    if (
      this.cliente &&
      this.cuenta &&
      this.danio &&
      this.parte &&
      this.gravedad &&
      this.unidadMedida
    ) {
      this.backDaniosEcom
        .altaHistoricoDaniosEcom(
          this.funcion,
          this.cliente,
          this.cuenta,
          this.vin || 'ErrorVIN',
          this.danio,
          this.parte,
          this.gravedad,
          this.cuadrante,
          this.unidadMedida,
          this.valor,
          this.observacion,
          this.tipo
        )
        .subscribe(
          (ret) => {
            if (ret && ret.ok) {
              this.msg.showSuccess('Control Exitoso', 'Carga de Daño Ecom');
              //this.router.navigate(['']);
              //this.setPaso(2);
              this.formControDanios.ClearForm();
              this.sound.playBeepOk();
              return;
            }
            this.msg.contolError(ret);
          },
          (error: HttpErrorResponse) => {
            this.msg.catchError(error);
          }
        );
    } else {
      this.msg.showError('Cliente/Cuenta null', 'Error inesperado');
    }
  }

  nuevoDanio() {
    let cargarDanio = {
      tipoDeDanio: this.danio,
      unidadMedida: this.unidadMedida,
      valorUnidadDeMedida: this.valor,
      partesDanios: this.parte,
      cuadrante: this.cuadrante,
      gravedadDeDanio: this.gravedad,
      observacion: this.observacion,
      imputacion: null,
      responsable: null,
      transaccion: null,
      fechaDanio: null,
      inspectorAveria: null,
    };
    this.formControDanios.ClearForm();
    this.danios.push(cargarDanio);
  }
  
  cargarDanios(type: number) {
    // Si el tipo no es "1. Ok" o "4. Bloqueo", se agrega el daño
    if (type != 1) { this.nuevoDanio(); }

    this.tipoNumber = type;

    const payload = {
      vin: this.vin,
      cliente: this.cliente,
      cuenta: this.cuenta,
      subcuenta: this.subcuenta,
      tipo: this.tipoNumber,

      // Si los tipos son "1. Ok" o "4. Bloqueo", el tipo de daño es vacio
      tipoDeDanio: (type == 1 ) ? [] : this.danios,
      
      destino: this.destino,
      puestoDeRecepcion: this.puestoDeRecepcion,
      ordenFabricacion: this.orden,
      motor: this.motor,
      modelo: this.modelo,
      marca: this.marca,
    };

    this.backUnificado.danioIngresoUnificado(payload, this.funcion).subscribe(
      (data) => {
        if (data && data.ok) {
          this.msg.showSuccess(data.message);
          this.danios = [];
          this.acces1 = false;
          this.acces2 = false;
          this.acces3 = false;
          this.acces4 = false;
          this.setPaso(2, 0);
          this.formIngresoVinUnificado.ClearForm();
          this.sound.playBeepOk();
        } else {
          if (data && data.err) {
            this.msg.showError(data.err.message, data.err.error);
            this.danios = [];
            this.sound.playBeepError();
          } else {
            this.msg.showError('Error inesperado', 'Error');
            this.sound.playBeepError();
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.msg.showError(error.statusText, 'Error inesperado');
      }
    );
  }
  
  focus(event: any) {
    event.preventDefault();
    if (event.key == 1) {
      this.btnAceptado.focus();
    }
    if (event.key == 2) {
      this.btnConDanio.focus();
    }
    if (event.key == 3) {
      this.btnRechazo.focus();
    }
  }
  pressClick(event: any, id: any) {
    event.preventDefault();
    if (id == 1) {
      this.cargarDanios(1);
    }
    if (id == 2) {
      this.setPaso(3, 2);
    }
    if (id == 3) {
      this.setPaso(3, 3);
    }
  }
}