import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatSelect } from '@angular/material/select';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RegistrarProcesoUnificadoService } from 'src/app/services/registrar-proceso-unificado/registrar-proceso-unificado.service';
import { StorageService } from 'src/app/services/storage.service';
import { IngresoEcomUnificadoComponent } from '../ingreso-ecom-unificado/ingreso-ecom-unificado.component';

export type SeguridadEvent = {
  valid: boolean;
  cliente: IdsDescr;
  cuenta: IdDescr;
  subcuenta: IdDescr;
  puestoDeRecepcion: IdDescr;
  esProcesoUnificado: boolean;
};

@Component({
  selector: 'app-seguridad',
  templateUrl: './seguridad.component.html',
  styleUrls: ['./seguridad.component.scss'],
})
export class SeguridadComponent implements OnInit {
  @Output() valid = new EventEmitter<SeguridadEvent>();
  @Input() funcion: Funcion | null = null;
  @Input() conSubcuenta: boolean = true;
  @Input('aceptar') btnAceptar!: MatButton;
  @Input() conRecepcion: boolean = true;

  clientes: IdsDescr[] = []!;
  cuentas: IdDescr[] = [];
  subcuentas: IdDescr[] = [];
  puestoDeRecepciones: any[] = [];
  esProcesoUnificado: boolean = false;

  sistema = '0';
  form: FormGroup = new FormGroup({
    cliente: new FormControl(null, Validators.required),
    cuenta: new FormControl(null, Validators.required),
    subcuenta: new FormControl(null, Validators.required),
    puestoDeRecepcion: new FormControl(null, Validators.required),
  });

  constructor(
    private back: BackendService,
    private el: ElementRef,
    private msg: NotificationService,
    private storage: StorageService,
    private backUnificado: RegistrarProcesoUnificadoService
  ) {}

  ngOnInit(): void {
    if (!this.conSubcuenta) {
      this.form = new FormGroup({
        cliente: new FormControl(null, Validators.required),
        cuenta: new FormControl(null, Validators.required),
        subcuenta: new FormControl(null),
        puestoDeRecepcion: new FormControl(null),
      });
    }

    if (!this.conRecepcion) {
      this.form = new FormGroup({
        cliente: new FormControl(null, Validators.required),
        cuenta: new FormControl(null, Validators.required),
        subcuenta: new FormControl(null, Validators.required),
        puestoDeRecepcion: new FormControl(null),
      });
    }

    if(this.funcion)
    {
      this.back.findClientes(this.funcion.id).subscribe(
        (data) => {
          this.clientes = data;
          if (data && data.length > 0) {
            let defCliente = data[0];
            let tmpCliente = this.storage.getParam<IdsDescr>('valDefCliente');
  
            if (tmpCliente) {
              let refCliente = data.find((e) => e.id === tmpCliente?.id);
              if (refCliente) {
                defCliente = refCliente;
              }
            }
            this.form.controls.cliente.setValue(defCliente);
          }
          this.form.controls.cuenta.setValue(null);
          this.cuentas = [];
        },
        (error) => {
          this.msg.showError(error.statusText, 'Error');
        }
      );
      this.form.controls.cliente.valueChanges.subscribe((val) => {
        if (val) {
          this.storage.setParam('valDefCliente', val);
          this.back.findCuentas(val.id, this.funcion!.id).subscribe((data) => {
            this.cuentas = data;
            this.form.controls.cuenta.setValue(null);
            this.subcuentas = [];
            if (data && data.length > 0) {
              let defCuenta = data[0];
              let tmpCuenta = this.storage.getParam<IdDescr>('valDefCuenta');
              if (!tmpCuenta) {
                let refCliente = data.find((e) => e.id === tmpCuenta?.id);
                if (refCliente) {
                  defCuenta = refCliente;
                }
              }
              this.form.controls.cuenta.setValue(defCuenta);
            }
          });
        }
      });
  
      this.form.controls.cuenta.valueChanges.subscribe((val) => {
        if (val) {
          this.storage.setParam('valDefCuenta', val);
        }
        if (val && this.conSubcuenta) {
          this.back
            .findSubcuentas(
              this.form.controls.cliente.value.id,
              val.id,
              this.funcion?.sistema.id || 'sinSistemaIdError',
              this.funcion!.id
            )
            .subscribe((data) => {
              if (data) {
                this.subcuentas = data;
                this.form.controls.subcuenta.setValue(null);
                if (data && data.length > 0) {
                  let defSubCuenta = data[0];
                  let tmpSubCuenta =
                    this.storage.getParam<IdDescr>('valDefSubCuenta');
                  if (!tmpSubCuenta) {
                    let refSubCuenta = data.find(
                      (e) => e.id === tmpSubCuenta?.id
                    );
                    if (refSubCuenta) {
                      defSubCuenta = refSubCuenta;
                    }
                  }
                  this.form.controls.subcuenta.setValue(defSubCuenta);
                }
              }
            });
        }
      });
    }


    this.form.controls.subcuenta.valueChanges.subscribe((val) => {
      if (val && this.conSubcuenta) {
        this.findProccesUnificado();
      }
    });

    this.form.statusChanges.subscribe((valid) => {
      let ret = <string>valid == 'VALID';
      if (ret) {
        this.storage.setParam<IdDescr>(
          'valDefSubCuenta',
          this.form.controls.subcuenta.value
        );
      }

      this.valid.emit({
        valid: ret,
        cliente: this.form.controls.cliente.value,
        cuenta: this.form.controls.cuenta.value,
        subcuenta: this.form.controls.subcuenta.value,
        puestoDeRecepcion: this.form.controls.puestoDeRecepcion.value,
        esProcesoUnificado: this.esProcesoUnificado,
      });
    });
    this.setFocus('cliente');
  }

  onEmitFocus() {
    this.btnAceptar.focus();
  }

  findProccesUnificado() {
    let payload = {
      cuenta: this.form.controls.cuenta.value,
      subcuenta: this.form.controls.subcuenta.value,
    };

    this.backUnificado.esProcesoUnificado(payload).subscribe((data) => {
      if (data.ok && !this.conRecepcion) {
        this.esProcesoUnificado = data.esProcesoUnificadoEcom;
      }
      if (this.esProcesoUnificado) {
        this.encontrarRecepcion();
      }

      this.valid.emit({
        valid: true,
        cliente: this.form.controls.cliente.value,
        cuenta: this.form.controls.cuenta.value,
        subcuenta: this.form.controls.subcuenta.value,
        puestoDeRecepcion: this.form.controls.puestoDeRecepcion.value,
        esProcesoUnificado: this.esProcesoUnificado,
      });
    });
  }

  encontrarRecepcion() {
    let payload = {
      cliente: this.form.controls.cliente.value,
      cuenta: this.form.controls.cuenta.value,
      subcuenta: this.form.controls.subcuenta.value,
    };
    this.backUnificado.findRecepcion(payload).subscribe((data) => {
      if (data.ok) {
        this.puestoDeRecepciones = data.calle;
        this.form.controls.puestoDeRecepcion.setValue(
          this.puestoDeRecepciones[0]
        );
        if (this.puestoDeRecepciones.length == 0) {
          this.esProcesoUnificado = false;
        }
        if (this.puestoDeRecepciones.length == 1) {
          this.setFocus('aceptar');
          this.onEmitFocus();
        } else {
          this.setFocus('puestoDeRecepcion');
        }
      }
    });
  }

  private setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }

  onSelectionChangeCliente(event: any) {
    if (this.form.controls.cliente.valid) {
      this.setFocus('cuenta');
    }
  }

  onSelectionChangeCuenta(event: any) {
    if (!this.conSubcuenta) {
      this.setFocus('aceptar');
    } else {
      this.setFocus('subcuenta');
    }
  }

  closeMethod(select: MatSelect, id: number) {
    if (id == 1) {
      this.setFocus('cuenta');
    }
    if (id == 2) {
      if (!this.conSubcuenta) {
        this.onEmitFocus();
      } else {
        this.setFocus('subcuenta');
      }
    }
    if (id == 3) {
      if (!this.esProcesoUnificado) {
        this.onEmitFocus();
      } else {
        this.setFocus('puestoDeRecepcion');
      }
    }
    if (id == 4) {
      this.setFocus('aceptar');
      this.onEmitFocus();
    }
  }

  onSelectionChangeSubcuenta(event: any) {
    /*     this.findProccesUnificado();
    if (this.esProcesoUnificado) {
      this.setFocus('puestoDeRecepcion');
    } else {
      this.setFocus('aceptar');
    } */
  }

  onSelectionChangePuestoDeRecepcion(event: any) {
    this.setFocus('aceptar');
    this.onEmitFocus();
  }
}
