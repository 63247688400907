import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormRecepcionComponent, RecepcionEventType } from 'src/app/forms/form-recepcion/form-recepcion.component';
import { SeguridadEvent } from 'src/app/forms/seguridad/seguridad.component';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendServiceRecepcion } from 'src/app/services/api-backend/validaRecepcion.service';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';

@Component({
  selector: 'app-mobile-recepcion',
  templateUrl: './mobile-recepcion.component.html',
  styleUrls: ['./mobile-recepcion.component.scss']
})
export class MobileRecepcionComponent implements OnInit {
  @ViewChild(FormRecepcionComponent) formRecepcionPlaya !: FormRecepcionComponent;

  titulo!:string;
  subtitulo:string = 'Seguridad';
  paso:number = 1;
  valid = false;
  
  // Seguridad
  cliente:IdsDescr|null = null;
  cuenta:any=null;
  subcuenta:IdDescr|null = null;
  puestoDeRecepcion:IdDescr|null = null;

  // Recepcion
  vin:string = '';
  destino: string = '';
  motor: string = '';
  orden: string = '';
  modelo: string = '';

  funcion:Funcion|null = null;  
  sistema: string = '59';
  
  constructor(
    private router: Router,
    private msg:NotificationService,
    private back: BackendServiceRecepcion,
    private sound: SoundService
  ) { 
    let nav = this.router.getCurrentNavigation();
    this.funcion = <Funcion>nav?.extras?.state?.funcion;
    if(!this.funcion){
      this.router.navigate(['']);
    }
    this.sistema = this.funcion.sistema.id;
    this.titulo = this.funcion.descripcion;
  }

  ngOnInit(): void {}

  onValidSeguridad($event: SeguridadEvent, quePaso: number){    
      this.cliente = $event.cliente;
      this.cuenta = $event.cuenta;
      this.subcuenta = $event.subcuenta;
      this.puestoDeRecepcion = $event.puestoDeRecepcion;
    
    this.valid = true;
  }

  onValidRecepcion(event: RecepcionEventType){
    if(event.valid){
      this.destino = event.destino;
      this.modelo = event.modelo;
      this.motor = event.motor;
      this.orden = event.orden;
      this.vin = event.vin;
    }
    this.valid = event.valid;
  }

  onFocusEvent(){
    if(this.valid){
      this.onClick();
    }
  }

  onClick(){
    if(this.paso < 2) {
      this.setPaso(this.paso+1);      
      this.sound.playBeepOk();      
      return;
    }
    this.altaVinRecepcion();
  }
  
  altaVinRecepcion() {
    if(!this.cliente || !this.cuenta || !this.subcuenta){
      this.msg.showError('Datos faltante','Error');
      return;
    }
    this.back.altaVinRecepcion(
      this.vin,this.cliente,
      this.cuenta, this.subcuenta,this.funcion?.sistema.id||'ErrorIdSistema',
      this.orden, this.motor,this.destino,this.modelo
    ).subscribe(data => {
      if(data && data.ok){
        this.msg.showSuccess('Recepcion Ok','Recepcion');
        this.formRecepcionPlaya.ClearForm();
        this.sound.playBeepOk();
        return;
      }else{
        if(data && data.err){
          this.msg.showError(data.err.message,data.err.error);
          return;
        }
      }
      this.msg.showError("Error en validaModeloRecepcion","Error");
    }, error => {
      this.msg.showError("Error en validaModeloRecepcion","Error");
    });
  }

  public onCancel = () => {
    this.setPaso(this.paso-1);
    if(this.paso == 0) this.router.navigate(['/']);
  }

  private setPaso(paso:number){
    this.valid = false;
    this.paso = paso;    
    switch (this.paso) {
      case 1:
        this.subtitulo = 'Seguridad';
        break;
      case 2:        
        this.subtitulo = `Recepción - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`; 
        break;    
      default:
        //this.subtitulo = 'Error'; o 0, que salio
        break;
    }
    
  }
}
