import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Usuario } from '../model/usuario';
import { startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Funcion } from '../model/funcion';
import { User } from '../model/user.model';
//import { TokenUser } from '../model/token-user.model';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  
  private usuario$:BehaviorSubject<Usuario|null>;  
  private autenticado$:BehaviorSubject<boolean>; 
  private static readonly USER = 'username';

  constructor(private router: Router) { 
    
    let user = this.getParam<Usuario>('usuario');
    
    if(user) {      
      this.usuario$ = new BehaviorSubject<Usuario|null>(user);
      this.autenticado$ = new BehaviorSubject<boolean>(true);
    } else {
      this.usuario$ = new BehaviorSubject<Usuario|null>(null);
      this.autenticado$ = new BehaviorSubject<boolean>(false);
    }
  }

  public saveUsuario(usuario: Usuario): void {
    this.setParam('usuario', usuario);
    this.usuario$.next(usuario);
    this.autenticado$.next(true);
  }
  
  public static getUser(): User | null {
    const userJson = localStorage.getItem(StorageService.USER);
    return userJson ? new User(JSON.parse(userJson)) : null;
  }
  
  public static setUser(user: any): void {
    localStorage.setItem(StorageService.USER, JSON.stringify(user));
  }

  public getUsuario(): Observable<Usuario|null> {
    return this.usuario$.asObservable();
  }

  public autenticado(): Observable<boolean> {
    return this.autenticado$.asObservable();
  }

  public logOut(navigate: boolean = true){
    // Borramos la información del usuario
    localStorage.removeItem('username')
    localStorage.removeItem('token')
    localStorage.removeItem('usuario')
    localStorage.removeItem('pais')

    // Seteamos los valores por defecto
    this.usuario$.next(null);
    this.autenticado$.next(false);

    // Navegamos al login
    this.router.navigate(['/login']);
  }

  public getParam<T>(name:string, def:T|null = null): T | null {
    let temp = localStorage.getItem(name);
    if(temp){
      let obj:T = JSON.parse(temp);
      return obj;
    }
    return def;
  }

  public setParam<T>(name: string, value:T): void {
    localStorage.setItem(name, JSON.stringify(value));
  }

  public getFuncion(idFuncion:string): Funcion|null {
    let usuario = this.usuario$.getValue();
    
    let funcionesRoot: Funcion[]|undefined = usuario?.menu.funciones;
    
    let ret: Funcion|null = null;
    
    if(funcionesRoot){
      funcionesRoot.forEach(funcionRoot => {
        funcionRoot.children.forEach(funcion => {
          if(funcion.id == idFuncion){
            ret = funcion;
          }
        });
      });
    }

    return ret;
  }
  
}
