<form [formGroup]="form" autocomplete="off">
  <!-- VIN -->
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>VIN</mat-label>
    <input matInput formControlName="vin" maxlength="30" placeholder="Ingrese un VIN..."
      (keydown.enter)="nextDanio($event)" (keydown.tab)="nextDanio($event)" />
    <button mat-icon-button matSuffix (click)="findEcomBy()" [attr.aria-label]="'Buscar VIN'">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <div class="boxCalleColumnNivel">

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Rua</mat-label>
      <mat-select matInput formControlName="selectedCalle" (selectionChange)="findColumn()" #selectedCalle>
        <mat-option *ngFor="let selectedCalle of listCalle" [value]="selectedCalle">
          {{ selectedCalle }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Coluna</mat-label>
      <mat-select matInput formControlName="selectedColumn" (selectionChange)="findNivel()" #selectedumn>
        <mat-option *ngFor="let selectedColumn of listColumn" [value]="selectedColumn">
          {{ selectedColumn }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Nivel</mat-label>
      <mat-select matInput formControlName="selectedNivel" (selectionChange)="setSelectedNivel()" #selectedNivel>
        <mat-option *ngFor="let selectedNivel of listNivel" [value]="selectedNivel">
          {{ selectedNivel }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Orden de Fabricação</mat-label>
      <input matInput formControlName="ordenFabricacion" maxlength="256" placeholder="Ingrese una ordem de fabricação..." />
      <button mat-icon-button matSuffix [attr.aria-label]="'orden de Fabricação'">
      </button>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Mercado</mat-label>
      <input matInput formControlName="market" maxlength="256" placeholder="Ingrese una mercado..." />
      <button mat-icon-button matSuffix [attr.aria-label]="'Calle'">
      </button>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Origem</mat-label>
      <input matInput formControlName="origen" maxlength="256" placeholder="Ingrese una origem..." />
      <button mat-icon-button matSuffix [attr.aria-label]="'Buscar VIN'">
      </button>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Marca</mat-label>
      <input matInput formControlName="marca" maxlength="256" placeholder="Ingrese una marca..." />
      <button mat-icon-button matSuffix [attr.aria-label]="'Buscar VIN'">
      </button>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Modelo</mat-label>
      <input matInput formControlName="modelo" maxlength="256" placeholder="Ingrese un modelo..." />
      <button mat-icon-button matSuffix [attr.aria-label]="'Buscar VIN'">
      </button>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Cor</mat-label>
      <input matInput formControlName="color" maxlength="256" placeholder="Ingrese un VIN..." />
      <button mat-icon-button matSuffix [attr.aria-label]="'Buscar VIN'">
      </button>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Destino</mat-label>
      <input matInput formControlName="destination" maxlength="256" placeholder="Ingrese un VIN..." />
      <button mat-icon-button matSuffix [attr.aria-label]="'Buscar VIN'">
      </button>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Destino de Origem</mat-label>
      <input matInput formControlName="originDestination" maxlength="256" placeholder="Ingrese un VIN..." />
      <button mat-icon-button matSuffix [attr.aria-label]="'Buscar VIN'">
      </button>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Genero</mat-label>
      <input matInput formControlName="gender" maxlength="256" placeholder="Ingrese un VIN..." />
      <button mat-icon-button matSuffix [attr.aria-label]="'Buscar VIN'">
      </button>
    </mat-form-field>
  </div>


</form>