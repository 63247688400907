import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { BackendServiceRecepcion } from 'src/app/services/api-backend/validaRecepcion.service';
import { BackendService } from 'src/app/services/backend.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RegistrarProcesoUnificadoService } from 'src/app/services/registrar-proceso-unificado/registrar-proceso-unificado.service';
import { SoundService } from 'src/app/services/sound.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

export type RecepcionEventType = {
  valid: boolean;
  orden: string;
  vin: string;
  modelo: string;
  motor: string;
  destino: string;
  marca: any;
}

@Component({
  selector: 'app-ingreso-vin-unificado',
  templateUrl: './ingreso-vin-unificado.component.html',
  styleUrls: ['./ingreso-vin-unificado.component.scss']
})
export class IngresoVinUnificadoComponent implements OnInit {
  @Output() focusEvent = new EventEmitter();
  @Output() EnableButton = new EventEmitter();
  @Output() EnableButtons = new EventEmitter();
  @Output() valid = new EventEmitter<RecepcionEventType>();
  @Input() funcion: Funcion | null = null;
  @Input() cliente: any = null;
  @Input() cuenta: IdDescr | null = null;
  @Input('okey') btnAceptado!: MatButton;
  @Input() esProcesoUnificado !: boolean;
  @Input() subcuenta: IdDescr | null = null;
  @Input() destinoOk: boolean = false;
  public autoComplete: boolean = false;
  form: FormGroup = new FormGroup(
    {
      orden: new FormControl(null, Validators.compose([
        Validators.pattern('[0-9A-Z]{8}'),
        Validators.required
      ])),
      vin: new FormControl(null, Validators.compose([
        /* Validators.pattern('[0-9A-Z]{8}(?:[0-9a-zA-Z]{9})?') */
        Validators.pattern('[0-9a-zA-Z]{17}'),
        Validators.required
      ])),
      modelo: new FormControl(null, Validators.compose([
        Validators.pattern('[0-9A-Z]{16}'),
        Validators.required
      ])),
      motor: new FormControl(null, Validators.compose([
        Validators.pattern('[0-9A-Z]{13,15}'),
        Validators.required
      ])),
      destino: new FormControl(null, Validators.compose([
        Validators.pattern('[0-9A-Z]{4}'),
        Validators.required
      ]))
    }
  );
  valido: boolean = false;
  sistema: string = '59';
  marca: any = null;
  
  constructor(
    private back: RegistrarProcesoUnificadoService,
    private msg: NotificationService,
    private sound: SoundService,
    private el: ElementRef,
    private router: Router,
    public dialog: MatDialog
  ) {
    let nav = this.router.getCurrentNavigation();
    this.funcion = <Funcion>nav?.extras?.state?.funcion;
  }

  ngOnInit(): void {
    
    this.form.controls.modelo.disable();
    this.form.controls.motor.disable();
    this.form.controls.destino.disable();
    this.form.statusChanges.subscribe(
      valid => {
        let ret = ((<string>valid) == 'VALID');
        
        this.valid.emit({
          valid: ret && this.valido,
          orden: this.form.controls.orden.value,
          vin: this.form.controls.vin.value,
          modelo: this.form.controls.modelo.value,
          motor: <string>this.form.controls.motor.value,
          destino: this.form.controls.destino.value,
          marca: this.marca
        });
      }
    );
    this.setFocus('orden')
  }

  openConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { message: 'El vin tiene operaciones pendientes ¿Desea cerrarlas?' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let vinUpperCase = this.form.controls.vin.value.toUpperCase();

        let payload = {
          vin: vinUpperCase,
          cliente: this.cliente,
          cuenta: this.cuenta,
          subcuenta: this.subcuenta,
          sinEcom: true
        };

        this.back.registrarOperacionesPendientes(payload, this.funcion).subscribe((data) => {
          
          if(data.ok) {
            this.msg.showSuccess('Se registraron las operaciones pendientes exitosamente.');
          }
        }, (error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.EnableButton.emit(false);
            this.form.patchValue({
              modelo: '',
              motor: '',
              destino: ''
            })
            this.form.controls.modelo.enable();
            this.setFocus('modelo');
            return;
          } else {
            this.msg.catchError(error);
            this.sound.playBeepError();
          }
        });
        
        this.EnableButton.emit({
          acces1: true,
          acces2: true,
          acces3: true,
          acces4: false
        });
      } else {
        this.EnableButton.emit({
          acces1: false,
          acces2: false,
          acces3: true,
          acces4: false
        });
      }
    });
  }
  
  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + name + '"]');
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
  }

  validaOrden() {
    let payload = {
      OrdenDeFabricacion: this.form.controls.orden.value
    };
    this.back.validaOrdenDeFabricacion(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.msg.showSuccess('Orden Aceptado');
          this.sound.playBeepOk();
          this.setFocus('vin')
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  onClickSearch() {
    let vinUpperCase = this.form.controls.vin.value.toUpperCase();
    let payload = {
      vin: vinUpperCase,
      cliente: this.cliente,
      cuenta: this.cuenta,
      subcuenta: this.subcuenta,
      esProcesoUnificado: this.esProcesoUnificado,
      ordenDeFabricacion: this.form.controls.orden.value,
    };

    this.back.validaVin(payload, this.funcion).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.sound.playBeepOk();
         // console.log('ing vin');
          this.back.completarVin(payload, this.funcion).subscribe(
            (data: any) => {
              if(data) {

                if (data.marca && data.marca != null) {
                  this.marca = data.marca;
                }
                //Se realiza el autocomplete
                this.form.controls.modelo.enable();
                this.form.controls.motor.enable();
                this.form.controls.destino.enable();
                this.form.patchValue({
                  modelo: data.modelo.id,
                  motor: data.numeroMotor,
                  destino: data.destino.id
                })
                if (data.numeroMotor && data.modelo.id && data.destino.id &&
                  data.numeroMotor != null && data.modelo.id != null && data.destino.id != null) {
                  this.onClickModelo();
                  this.autoComplete = true;
                }
                //Aca va el servicio para validar si tiene o no op pend.
                //Si tiene se ejecuta openConfirmationDialog()
                //Si no tiene se ejecuta:
                //this.EnableButton.emit(true);
                let payload = {
                  vin: vinUpperCase,
                  cliente: this.cliente,
                  cuenta: this.cuenta,
                  subcuenta: this.subcuenta,
                  sinEcom: true // sino probar con 1
                };
                this.back.validaOperacionesPendientesExistentes(payload, this.funcion).subscribe((data) => {
                  if(data) {
                    this.openConfirmationDialog();
                  } else {
                    this.EnableButton.emit(true);
                  }
                }, (error: HttpErrorResponse) => {
                  if (error.status === 404) {
                    this.EnableButton.emit(false);
                    this.form.patchValue({
                      modelo: '',
                      motor: '',
                      destino: ''
                    })
                    this.form.controls.modelo.enable();
                    this.setFocus('modelo');
                    return;
                  } else {
                    this.msg.contolError(data);
                    this.sound.playBeepError();
                  }
                }); 

              } else {
                this.form.controls.modelo.enable();
                this.setFocus('modelo')
                return;
              }
            },
            (error: HttpErrorResponse) => {
              if (error.status === 404) {
              this.EnableButton.emit(false);
              this.form.patchValue({
                modelo: '',
                motor: '',
                destino: ''
              })
              this.form.controls.modelo.enable();
              this.setFocus('modelo');
              return;
              }else{
                this.msg.contolError(data);
                this.sound.playBeepError();
              }
             
            }
          );
        }
        this.msg.contolError(data);
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
        this.EnableButton.emit(false);
      }
    );

  }

  validaModelo() {
    let payload = {
      modelo: this.form.controls.modelo.value,
      vin: this.form.controls.vin.value,
      cliente: this.cliente,
      cuenta: this.cuenta
    };
    this.back.validaModelo(payload, this.funcion).subscribe(
      (data: any) => {
        if (data && data.ok) {
          
          if (!this.autoComplete) {
          //  console.log('ing model');
            this.sound.playBeepOk();
            this.form.controls.motor.enable();
            this.marca = data.marca
            this.setFocus('motor');
          } else {
            this.validaMotor();
          }
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  onClickModelo() {
    this.validaModelo();
  }
  
  validaMotor() {
    let payload = {
      motor: this.form.controls.motor.value,
    };
    this.back.validaMotor(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          if (this.autoComplete) { 
            this.validaDestino();
          } else {
           // console.log('ing motor');
            this.sound.playBeepOk();
            this.form.controls.destino.enable();
            this.setFocus("destino");
          }
          
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }
  
  onClickMotor() {
    this.validaMotor();
  }
  
  validaDestino() {
    let payload = {
      destino: this.form.controls.destino.value,
      cuenta: this.cuenta,
      cliente: this.cliente,
    }
    this.back.validaDestino(payload, this.funcion).subscribe(data => {
      if (data && data.ok) {
        this.setFocus('okey');
        this.onEmitFocus();
        this.destinoOk = true;
        if(!this.autoComplete)
        {
         // console.log('ing des', data);
          this.sound.playBeepOk();
        }
      } else {
        this.form.controls.tipo.disable();
        this.msg.contolError(data);
        this.destinoOk = false;
      }
      this.autoComplete = false;
    }, (error: HttpErrorResponse) => {
      this.msg.catchError(error);
      this.destinoOk = false;
      this.autoComplete = false;
    });

  }
  
  onClickDestino() {
    this.validaDestino();
  }
  
  onEmitFocus() {
    this.btnAceptado.focus();
  }
  nextFocus(event: any, id: any) {
    if (id == 1) {
      event.preventDefault();
      this.validaOrden();
    }
    if (id == 2) {
      event.preventDefault();
      if (this.form.controls.vin.valid) {
        this.onClickSearch();
      } else {
        this.msg.showError('El campo debe tener 17 caracteres', 'Error');
      }
    }
    if (id == 3) {
      event.preventDefault();
      this.validaModelo();
    }
    if (id == 4) {
      event.preventDefault();
      this.validaMotor();
    }
    if (id == 5) {
      event.preventDefault();
      this.validaDestino();
    }
  }
  
  public ClearForm() {
    this.form.reset();
  }

}