import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Funcion } from 'src/app/model/funcion';
import { NotificationService } from 'src/app/services/notification.service';
import { SoundService } from 'src/app/services/sound.service';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { BackendIngresoEcom } from 'src/app/services/ingresoEcom/ingreso-ecom.service';

export interface EventOnValidIngressoEcom {
  valid: boolean;
  vin: any;
  listCalle: any;
  listColumn: any;
  listNivel: any;
  selectedCalle: any;
  selectedColumn: any;
  selectedNivel: any;
  ordenFabricacion: any;
  market: any;
  origen: any;
  marca: any;
  modelo: any;
  color: any;
  destination: any;
  originDestination: any;
  gender: any;
}

@Component({
  selector: 'app-entrada-ecom',
  templateUrl: './entrada-ecom.component.html',
  styleUrls: ['./entrada-ecom.component.scss']
})

export class EntradaEcomComponent implements OnInit {
  @Input() funcion: Funcion | null = null;
  @Input() cliente: any;
  @Input() cuenta: any;
  @Input() subcuenta: any;
  @Input() codOpPendiente: any;
  @Input('aceptar') btnAceptar!: MatButton;

  @Output() valid = new EventEmitter<EventOnValidIngressoEcom>();

  form = new FormGroup({
    vin: new FormControl(null, Validators.required),
    selectedCalle: new FormControl(null, Validators.required),
    selectedColumn: new FormControl(null, Validators.required),
    selectedNivel: new FormControl(null, Validators.required),
    ordenFabricacion: new FormControl(null),
    market: new FormControl(null, Validators.required),
    origen: new FormControl(null, Validators.required),
    marca: new FormControl(null, Validators.required),
    modelo: new FormControl(null, Validators.required),
    color: new FormControl(null, Validators.required),
    destination: new FormControl(null, Validators.required),
    originDestination: new FormControl(null, Validators.required),
    gender: new FormControl(null, Validators.required),
  });

  listCalle: any;
  listColumn: any;
  listNivel: any;
  ecomDetail: any;
  selectedCalle: any;
  selectedColumn: any;
  selectedNivel: any;

  constructor(
    private back: BackendIngresoEcom,
    private msg: NotificationService,
    private el: ElementRef,
    private sound: SoundService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.form.statusChanges.subscribe((val) => {
      let ret = <string>val == 'VALID';
      this.valid.emit({
        valid: ret,
        vin: this.form.controls.vin.value,
        listCalle: this.listCalle,
        listColumn: this.listColumn,
        listNivel: this.listNivel,
        selectedCalle: this.form.controls.selectedCalle.value,
        selectedColumn: this.form.controls.selectedColumn.value,
        selectedNivel: this.form.controls.selectedNivel.value,
        ordenFabricacion: this.form.controls.ordenFabricacion.value,
        market: this.form.controls.market.value,
        origen: this.form.controls.origen.value,
        marca: this.form.controls.marca.value,
        modelo: this.form.controls.modelo.value,
        color: this.form.controls.color.value,
        destination: this.form.controls.destination.value,
        originDestination: this.form.controls.originDestination.value,
        gender: this.form.controls.gender.value,
      });
    });

    this.findCalle();
    this.setFocus('vin');
  }


  setFocus(name: string) {
    const invalidControl = this.el.nativeElement.querySelector(
      '[formcontrolname="' + name + '"]'
    );
    if (invalidControl) {
      setTimeout(() => {
        invalidControl.focus();
      }, 0);
    }
    //this.form.controls.operacion.disable();
  }

  ClearForm() {
    this.form.reset();
    this.selectedCalle = undefined;
    this.selectedColumn = undefined;
    this.selectedNivel = undefined;
    this.ecomDetail = undefined;
  }

  findCalle() {
    this.selectedCalle = this.form.controls.selectedCalle.value;
    this.form.controls.selectedColumn.setValue(undefined);
    this.selectedColumn = undefined;
    this.form.controls.selectedNivel.setValue(undefined);
    this.selectedNivel = undefined;
    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      subCuenta: this.subcuenta
    };

    this.back.findCalle(payload).subscribe(
      (data: any) => {

        if (data && data.ok) {
          this.listCalle = data.results;
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  findColumn() {
    this.form.controls.selectedNivel.setValue(undefined);
    this.selectedCalle = this.form.controls.selectedCalle.value;
    this.selectedColumn = this.form.controls.selectedColumn.value;
    this.selectedColumn = undefined;
    this.selectedNivel = undefined;
    this.form.controls.selectedNivel.setValue(undefined);

    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      subCuenta: this.subcuenta,
      calle: this.form.controls.selectedCalle.value
    };

    this.back.findColumn(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.listColumn = data.results;
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  findNivel() {
    this.selectedNivel = undefined;
    this.form.controls.selectedNivel.setValue(undefined);
    this.selectedColumn = this.form.controls.selectedColumn.value;

    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      subCuenta: this.subcuenta,
      calle: this.form.controls.selectedCalle.value,
      column: this.form.controls.selectedColumn.value
    };

    this.back.findNivel(payload).subscribe(

      (data: any) => {
        if (data && data.ok) {
          this.listNivel = data.results;
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  setSelectedNivel() {
    this.selectedNivel = this.form.controls.selectedNivel.value;
  }

  findEcomBy() {
    const vvin = this.form.controls.vin.value;
    this.ClearForm();
    this.form.controls.vin.setValue(vvin);

    if (!(this.form.controls.vin.value)) return

    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      vin: this.form.controls.vin.value,
      transacion: "2264"
    };

    this.back.findEcomBy(payload).subscribe(

      (data: any) => {
        if(data.results.length ===0){
          this.msg.showError('Não foi encontrado detalhes para esse vin');
        }
        if (data && data.ok) {
          this.form.controls.vin.setValue(data.results[0].vin.id);
          this.ecomDetail = data.results[0].vin;
          this.form.controls.ordenFabricacion.setValue(this.ecomDetail.ordenFabricacion);
          this.findMarket(data.results[0].vin.mercado.id);
          this.findDestination(this.ecomDetail.destino.id);
          this.findColor(this.ecomDetail.marca.id, this.ecomDetail.color.id);
          this.findMarca(this.ecomDetail.marca.id, this.ecomDetail.modelo.id);
          this.findOrigen(this.ecomDetail.origen.id)
          return;
        }

        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        console.log(error)
        this.msg.catchError(error);
      }
    );
  }

  nextDanio(event: any) {
    event?.preventDefault();
    this.findEcomBy();
  }
  

  findMarket(idMercado) {
    
    this.back.findMarket(idMercado).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.form.controls.market.setValue(data.results[0].descripcion);
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  findOrigen(idOrigen) {
    this.back.findOrigen(idOrigen).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.form.controls.origen.setValue(data.results[0].descripcion);
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  findMarca(idMarca: string, codModelo: string) {

    let payload = {
      cliente: this.cliente,
      activo: true,
      codMarca: idMarca
    };
    this.back.findMarca(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.findModelo(data.results[0].id, codModelo);
          if(data.results[0].id) {
            this.form.controls.marca.setValue(data.results[0].descripcion); 
            return;
          }

          this.form.controls.marca.setValue(data.results[0].descripcion);
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  findModelo(idMarca: string, codModelo: string) {
    let payload = {
      cliente: this.cliente,
      marca: idMarca,
      codModelo,
    };
    this.back.findModelo(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.form.controls.modelo.setValue(`${data.results[0].descripcion} - (${data.results[0].id})`);
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  findColor(idMarca: string, codColor: string) {
    let payload = {
      cliente: this.cliente,
      marca: idMarca,
      color: codColor
    };
    this.back.findColor(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          if(data.results.length == 0){
            this.form.controls.destination.setValue("Não informado");
          }
          this.form.controls.color.setValue(data.results[0].descripcion);
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  findDestination(codDestination: string) {
    this.back.findDestination(codDestination).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.findGenero();
          if(data.results.length === 0) {
            this.form.controls.destination.setValue("Não informado");
          this.form.controls.originDestination.setValue("Não informado");
          return;
          }
          this.form.controls.destination.setValue(data.results[0].descripcion);
          this.form.controls.originDestination.setValue(data.results[0].descripcion);
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        this.msg.catchError(error);
      }
    );
  }

  findGenero() {
    let payload = {
      cliente: this.cliente,
      marca: this.ecomDetail.marca.id,
    };
    this.back.findGenero(payload).subscribe(
      (data: any) => {
        if (data && data.ok) {
          this.form.controls.gender.setValue(data.results[0].descripcion);
          return;
        }
        this.msg.contolError(data);
        this.sound.playBeepError();
      },
      (error: HttpErrorResponse) => {
        console.log("ERROR: ", error)
        this.msg.catchError(error);
      }
    );
  }

}

