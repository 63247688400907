import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { error } from 'protractor';
import Quagga from 'quagga';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-quagga-js',
  templateUrl: './quagga-js.component.html',
  styleUrls: ['./quagga-js.component.scss']
})
export class QuaggaJSComponent implements OnInit,AfterViewInit  {

  @Output() cancel = new EventEmitter();
  @Output() scan = new EventEmitter<string>();
  
  @ViewChild('myCanvas', {static: false}) myCanvas!: ElementRef;
  
  errorMessage = '';
  private lastScannedCode: string = '';
  private lastScannedCodeDate: number = 0;
  public camaras: MediaDeviceInfo[] = [];
  public idCamara = 0;
  mostrar = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private _platform: Object,
    private msg:NotificationService
  ) { 
    if(isPlatformBrowser(this._platform) && 'mediaDevices' in navigator) {
      navigator.mediaDevices.enumerateDevices().then((mdi:MediaDeviceInfo[]) => {
        this.camaras = mdi.filter((val:MediaDeviceInfo) => val.kind == "videoinput" );        
        var indice = 0;
        this.camaras.forEach( (media:MediaDeviceInfo) => {
          var prop = (<any>media).getCapabilities();
          if(prop){
            if(prop.facingMode && prop.facingMode.length > 0 ){
              if(prop.facingMode[0] == 'environment'){
                this.idCamara = indice;
                return;
              }
            }
          }
          indice++;          
        } );        
      });
    }else{
      // no soportado
    }
  }

  ngAfterViewInit(): void { 
    
  }

  public Iniciar(){
    this.mostrar = true;
    this.msg.showInfo('Cantidad de camaras ' + this.camaras.length);
        
    setTimeout(() => {
      this.InitQuagga(this);
    }, 1000);
  }

  onCancelarClick(){
    Quagga.stop();
    this.cancel.emit();
    this.mostrar = false;
  }

  onNextClick(){
    Quagga.stop();
    if(this.camaras && this.camaras.length > 0){
      this.idCamara++;
      if(this.idCamara > (this.camaras.length-1)){
        this.idCamara=0;
      }
      this.msg.showInfo('Cambio a camara: '+this.idCamara);
      this.InitQuagga(this);
    }    
  }

  InitQuagga(instacia:QuaggaJSComponent) {

    instacia.setCamaras();

    
    
    Quagga.init({
      locate: true,
      locator : {
        halfSample: true,
        patchSize: "medium", // x-small, small, medium, large, x-large
        debug: {
          showCanvas: true,
          showPatches: true,
          showFoundPatches: true,
          showSkeleton: true,
          showLabels: true,
          showPatchLabels: true,
          showRemainingPatchLabels: true,
          boxFromPatches: {
            showTransformed: true,
            showTransformedBox: true,
            showBB: true
          }
        }
      },
      inputStream: {
        target: document.querySelector('#interactive'),
        constraints: {
          facingMode: 'environment',
          width: 640,
          height: 480,
          deviceId: instacia.camaras[instacia.idCamara].deviceId
        },
        area: { // defines rectangle of the detection/localization area
          top: "40%",    // top offset
          right: "0%",  // right offset
          left: "0%",   // left offset
          bottom: "40%"  // bottom offset
        },
        singleChannel: true
      },
      decoder: {
        //readers: ['code_39_vin_reader'],
        //readers: ['code_128_reader'],
        readers: ['code_39_reader'],
        debug: {
          drawBoundingBox: true,
          showFrequency: true,
          drawScanline: true,
          showPattern: true
        },
        multiple: false
      },
    },
    (err:any) => {
      if (err) {
        this.errorMessage = `QuaggaJS could not be initialized, err: ${err}`;        
      } else {
        Quagga.start();
        Quagga.onDetected((res:any) => {          
          if(!res.line) return;
        var line1 = res.line[0];
        var line2 = res.line[1];
        //if(line1.x > 50 || line2.x < 590) return;
        //if(line1.y < 230 || line1.y > 250) return;
        //if(line2.y < 230 || line2.y > 250) return;

          this.onBarcodeScanned(res.codeResult.code);
          Quagga.stop();
          //this.msg.showInfo(res.codeResult.code);
        });
      }
    });

    /*
    Quagga.onProcessed(function (result) {
      var drawingCtx = Quagga.canvas.ctx.overlay,
      drawingCanvas = Quagga.canvas.dom.overlay;
      
      var context:any = Quagga.canvas.ctx.overlay;

      
      if(!result || !result.codeResult || !result.codeResult.code){
        return;
      }
      if (result) {
        if(!result.line) return;

        var line1 = result.line[0];
        var line2 = result.line[1];

        /* context.beginPath();
        context.moveTo(line1.x,line1.y);
        context.lineTo(line2.x,line2.y);
        context.lineWidth = 1;
        context.strokeStyle = "green";
        context.stroke();
        console.log('lone', result.line, result.codeResult.code); 

        

        Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'green', lineWidth: 1 });

        //if(line1.x > 50 || line2.x < 590) return;
        //if(line1.y < 230 || line1.y > 250) return;
        //if(line2.y < 230 || line2.y > 250) return;

        var multiplicadorX = 640/ context.canvas.clientWidth;
        var multiplicadorY = 480 / context.canvas.clientHeight;

        console.log('frame: ',multiplicadorX,multiplicadorY,context.canvas.clientHeight,context.canvas.clientWidth);
        
        console.log('context', context, context.getContextAttributes());
        
        console.log('ctx', Quagga.canvas.ctx);
        
        /*
        context.beginPath();
        context.moveTo(line1.x ,line1.y );
        context.lineTo(line2.x ,line2.y );
        context.lineWidth = 1;
        context.strokeStyle = "red";
        context.stroke();
        console.log('lone', result.line);
        */
        /*
        console.log('code:',result.codeResult.code);
        

          if (result.boxes) {
              drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
              result.boxes.filter(function (box) {
                  return box !== result.box;
              }).forEach(function (box) {
                  Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
              });
          }

          if (result.box) {
              Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
              console.log('box', result.box);
              
          }

          if (result.codeResult && result.codeResult.code) {
              Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
          }

          

      } 
    }); 
    */
  }
  
  public setCamaras() {
    console.log('InitQuagga camaras', this.camaras);
  }

  ngOnInit(): void {
  }

  onBarcodeScanned(code: string) {
    // ignore duplicates for an interval of 1.5 seconds
    const now = new Date().getTime();
    if (code === this.lastScannedCode && (now < this.lastScannedCodeDate + 1500)) {
      return;
    }

    this.lastScannedCode = code;
    // ignore unknown articles
    //Quagga.stop();
    
    this.mostrar = false;

    this.changeDetectorRef.detectChanges();

    this.scan.emit(code);
  }


}
