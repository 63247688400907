import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { IRecomendacionVin } from 'src/app/model/irecomandacionvin';
import { IVin } from 'src/app/model/ivin';
import { Usuario } from 'src/app/model/usuario';
import { environment } from 'src/environments/environment';

type responseOperaciones = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
  grupoOperacion: IdDescr[];
};

type responseRecomVin = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
  vin: IVin;
  ubicacion: {
    calle: string;
    columna: string;
  };
  operacionesPendientes: any[];
};

type response = {
  ok: boolean;
  err: {
    error: string;
    message: string;
  };
};

@Injectable({
  providedIn: 'root',
})
export class BackendRecomandacionVinService {
  public usrServer: String = environment.urlBackend;
  public urlSeguridad: String = this.usrServer + 'seguridad/';
  public urlToken: String = this.usrServer + 'token/';
  public urlMobile: String = this.usrServer + 'mobile/';

  constructor(private httpClient: HttpClient) {}

  // TODO: Listo para prueba de integración
  //trae los grupos de operaciones en el componente recomendacion-vin
  public findOperaciones(): Observable<responseOperaciones> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'findOperaciones';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    return this.httpClient.post<responseOperaciones>(
      url,
      {},
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
  }

  // TODO: Listo para prueba de integración
  //trae los vines en el componente recomendacion-vin
  public consultaRecomendacionVin(
    cliente: string,
    cuenta: number,
    subcuenta: number,
    grupo: IdDescr
  ): Observable<responseRecomVin> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'consultaRecomendacionVin';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;
    const body = {
      pais: parsedUser.pais.id,
      cliente,
      cuenta: cuenta,
      subcuenta: subcuenta,
      grupo,
    };

    return this.httpClient.post<responseRecomVin>(url, body, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  // TODO: Listo para prueba de integración
  //realiza un alta del vin a liberar en mobile-recomendacion-vin
  public liberarVin(
    cliente: IdsDescr,
    cuenta: IdDescr,
    subcuenta: IdDescr,
    vin: any,
    calle: string,
    columna: string,
    nivel: string,
    grupo: IdDescr
  ): Observable<response> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'liberarVin';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;
    const body = {
      pais: parsedUser.pais,
      cliente,
      cuenta,
      subcuenta,
      vin,
      calle,
      columna,
      nivel,
      grupo,
    };

    return this.httpClient.post<response>(url, body, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  //realiza un alta de vin en lista negra en el componente mobile-recomendacion-vin
  public listaNegraVin(
    cliente: IdsDescr,
    cuenta: IdDescr,
    subcuenta: IdDescr,
    vin: any,
    calle: string,
    columna: string,
    nivel: string,
    grupo: IdDescr,
    observacion: string
  ): Observable<response> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'liberarVin';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;
    const body = {
      pais: parsedUser.pais,
      cliente,
      cuenta,
      subcuenta,
      vin,
      calle,
      columna,
      nivel,
      grupo,
      observacion,
    };

    return this.httpClient.post<response>(url, body, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  //ingresa un vin, en el componente mobile-recomendacion-vin
  public tomarVin(
    cliente: IdsDescr,
    cuenta: IdDescr,
    subcuenta: IdDescr,
    vin: IVin,
    calle: string,
    columna: string,
    nivel: string,
    grupo: IdDescr
  ): Observable<response> {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('usuario');
    const url = this.urlMobile + 'tomarVin';

    if (!token || !user)
      return throwError({ statusText: 'Usuario no logeado' });

    const parsedUser = JSON.parse(user) as Usuario;
    const body = {
      pais: parsedUser.pais,
      cliente,
      cuenta,
      subcuenta,
      vin,
      calle,
      columna,
      nivel,
      grupo,
    };

    return this.httpClient.post<response>(url, body, {
      headers: { authorization: `Bearer ${token}` },
    });
  }
}
