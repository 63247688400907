<form [formGroup]="form" autocomplete="off">
    <!-- Input orden de fabricación -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Orden de Fabricación</mat-label>
        <input matInput formControlName="orden" #orden
        (keydown.enter)="nextOrden($event)"
        (keydown.tab)="nextOrden($event)"
        type="text"
        maxlength="8"
        placeholder="Orden de Fabricación..."
        onfocus="this.select()">
    </mat-form-field>

    <!-- Input VIN -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>VIN</mat-label>
        <input matInput formControlName="vin" #vin
            maxlength="17"
            placeholder="Ingrese un VIN..."
            onfocus="this.select()"
            (keydown.enter)="nextVin($event)"
            (keydown.tab)="nextVin($event)"
        >
        <div mat-icon-button matSuffix (click)="onClickSearch()" [attr.aria-label]="'Buscar VIN'">
            <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
        </div>    
    </mat-form-field>

    <!-- Input modelo -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Modelo</mat-label>
        <input matInput formControlName="modelo" #modelo
        (keydown.enter)="nextModelo($event)"
        (keydown.tab)="nextModelo($event)"
        maxlength="16" placeholder="Modelo..."
        onfocus="this.select()">
    </mat-form-field>

    <!-- Input motor -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Motor</mat-label>
        <input matInput formControlName="motor" #motor
        (keydown.enter)="nextMotor($event)"
        (keydown.tab)="nextMotor($event)"
        maxlength="15" placeholder="Motor..."
        onfocus="this.select()">
        <div mat-icon-button matSuffix (click)="onClickMotor()" [attr.aria-label]="'Buscar VIN'">
            <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
        </div> 
    </mat-form-field>

    <!-- Input destino -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Destino</mat-label>
        <input matInput formControlName="destino" #destino
            maxlength="4"
            placeholder="Destino..."
            onfocus="this.select()"
            (keydown.enter)="nextDestino($event)"
            (keydown.tab)="nextDestino($event)"
        >
    </mat-form-field>
</form>