import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ɵangular_packages_platform_browser_dynamic_platform_browser_dynamic_a } from '@angular/platform-browser-dynamic';
import { Router } from '@angular/router';
import { EventOnValidRegOpAuto } from 'src/app/forms/reg-op-auto/reg-op-auto.component';
import { EventOnValidRegOpOt, RegOpOtComponent } from 'src/app/forms/reg-op-ot/reg-op-ot/reg-op-ot.component';
import { SeguridadEvent } from 'src/app/forms/seguridad/seguridad.component';
import { Funcion } from 'src/app/model/funcion';
import { IdDescr, IdsDescr } from 'src/app/model/iddescr';
import { NotificationService } from 'src/app/services/notification.service';
import { ResgistracionOpOtService } from 'src/app/services/registracion-operacion-ot/registracion-op-ot.service';
import { SoundService } from 'src/app/services/sound.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-mobile-registrar-op-ot',
  templateUrl: './mobile-registrar-op-ot.component.html',
  styleUrls: ['./mobile-registrar-op-ot.component.scss']
})
export class MobileRegistrarOpOtComponent implements OnInit {

  @ViewChild(RegOpOtComponent) formRegOPoT !: RegOpOtComponent

  funcion:Funcion|null = null;  
  sistema: string = '59';
  valid = false;
  paso:number = 1;
  subtitulo:string = 'Seguridad';
  // Seguridad
  cliente:IdsDescr|null = null;
  cuenta:IdDescr|null = null;
  subcuenta:IdDescr|null = null;
  //RegistroOpOt
  ot:any = null;
  vin:any = null;
  operacion:any = null;
  proveedor:any = null;
  resultado:any = null;
  lineasProveedor:any = null;
  
  constructor(
    private storage:StorageService,
    private router: Router,
    private back : ResgistracionOpOtService,
    private sound : SoundService,
    private msg : NotificationService

  ) { 
    let nav = this.router.getCurrentNavigation();
    this.funcion = <Funcion>nav?.extras?.state?.funcion;
    if(!this.funcion){
      this.router.navigate(['']);
  }
  this.sistema = this.funcion.sistema.id;
}
  ngOnInit() {
    
  }

  onClick(){
    if(this.paso < 2) {
    this.setPaso(this.paso+1);
    }
    else {
      this.postRegistrarOpOt();
    }        
  }
  postRegistrarOpOt() {

    this.lineasProveedor.valor = this.resultado;

    let payload = {
      cliente: this.cliente,
      cuenta: this.cuenta,
      subcuenta: this.subcuenta,
      proveedor: this.proveedor,
      lineasProveedor: this.lineasProveedor,
      operacion: this.operacion,
      vin: this.vin,
      ot: this.ot
    }
    this.back.registrarOperacionPorOt(payload, this.funcion).subscribe(
      data => {
        if(data && data.ok ){
          this.msg.showSuccess('Operacion Registrada');
          this.formRegOPoT.ClearForm();
          this.setPaso(2);
          this.sound.playBeepOk();
        }else{
          if(data && data.err){
            this.msg.showError(data.err.message,data.err.error);
            this.sound.playBeepError();
          }else{
            this.msg.showError("Error inesperado","Error");
            this.sound.playBeepError();
          }            
        }
      },
      (error:HttpErrorResponse) => {
        this.msg.showError(error.statusText,"Error inesperado");
        this.sound.playBeepError();
      }
    );
  }

  
  setPaso(nuevoPaso: number) {
    this.valid = false;
    this.paso = nuevoPaso;
    switch (this.paso) {
      case 1:
        this.subtitulo = 'Seguridad';
        break;
      case 2:
        this.subtitulo = `Registración de Operacion por OT - ${this.cliente?.descripcion}(${this.cliente?.id}) - ${this.cuenta?.descripcion}(${this.cuenta?.id}) - ${this.subcuenta?.descripcion}(${this.subcuenta?.id})`; 
        break;
    
      default:
        break;
    }
  }

  onCancel = () => {
    this.setPaso(this.paso-1);
    if(this.paso == 0) this.router.navigate(['/']);
  }

  onValidSeguridad($event: SeguridadEvent){    
      this.cliente = $event.cliente;
      this.cuenta = $event.cuenta;
      this.subcuenta = $event.subcuenta;
    
    //this.valid = $event.valid;    
    this.valid = true;      
  }
  onValidRegOpOt($event:EventOnValidRegOpOt){
    if($event.valid){
      this.ot = $event.ot;
      this.vin =$event.vin;
      this.operacion = $event.operacion;
      this.proveedor =$event.proveedor;
      this.resultado = $event.resultado;
      this.lineasProveedor = $event.lineasProveedor;
    }
    this.valid = $event.valid;
  }
}
