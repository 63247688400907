<mat-card>
    <mat-card-header>
        <mat-card-title>Control de Despacho</mat-card-title>
    </mat-card-header>
    <mat-card-content style="height: fit-content;">
        <form [formGroup]="form" autocomplete="off">
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>VIN que figura en el remito</mat-label>
                <input matInput 
                formControlName="vin" 
                maxlength="256" 
                placeholder="Ingrese VIN que figura en el remito..."
                (keydown.enter)="nextFocus($event)"
                (keydown.tab)="nextFocus($event)"
                >
                <div matSuffix style="display:flex">
                    <mat-icon (click)="onClickSearch()">search</mat-icon>
                    <mat-icon (click)="onScannStart()" matSuffix>photo_camera</mat-icon>
                </div>

                <!-- <button mat-icon-button matSuffix (click)="onClickSearch()" [attr.aria-label]="'Buscar VIN'">
                    <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
                </button> -->
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>VIN recibido</mat-label>
                <input matInput 
                formControlName="otherVin" 
                maxlength="256" 
                placeholder="Ingrese VIN recibido..."
                (keydown.enter)="focusOtherVin($event)"
                (keydown.tab)="focusOtherVin($event)"
                >
                <button mat-icon-button matSuffix (click)="onClickSearch2()" [attr.aria-label]="'Buscar VIN'">
                    <img src="assets/icons/search.svg" style="margin-bottom: 15px;">
                </button>
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Ubicación</mat-label>
                <input matInput formControlName="ubicacion" maxlength="256" placeholder="Ingrese Ubicación...">
            </mat-form-field>
        </form>
        <dl>
            <dt>ubicacionActual</dt>
            <dd>{{ubicacionActual}}</dd>
        </dl>
    </mat-card-content>
    <mat-card-actions>
        <div style="text-align: center;">
            <button mat-raised-button (click)="onCancel()">Cancelar</button>
            <button type="submit" mat-raised-button (click)="onClick()" 
                [disabled]="!valid"
                color="primary">Aceptar</button>    
        </div>
    </mat-card-actions>
</mat-card>
<app-quagga-js #controlQuagga
    (scan)='onScannDetect($event)'
></app-quagga-js>